import React from 'react';
import { Icon } from 'semantic-ui-react';
import Button from '../../components/ui/Button';

import Form from '../../components/ui/Form';

import { __ } from '../../i18n';

const colorOptions = [
  'f03e3e',
  'd6336c',
  'ae3ec9',
  '7048e8',
  '4263eb',
  '1c7ed6',
  '1098ad',
  '0ca678',
  '37b24d',
  '74b816',
  'f59f00',
  'f76707'
];

export default class LabelForm extends Form {
  defaultValues = {
    title: '',
    color: 'f03e3e'
  }

  rules = {
    title: ['required', values => this.validateContent('title', values)],
    color: 'required'
  }

  validateContent = (name, values) => {
    if (name === 'title') {
      return values.trim().length > 0;
    }

    return true;
  }

  renderColorOptions = activeColor => colorOptions.map(__color => (
    <div
      key={__color}
      onClick={() => this.setValue('color', __color)}
      style={{ margin: '0px 8px 0px 0px', backgroundColor: `#${__color}`, borderRadius: '50%', cursor: 'pointer', height: '33px', width: '33px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {activeColor === __color && <i className="icon check" style={{ color: 'white', fontSize: '11px', margin: '0px 2px 2px 0px' }} />}
    </div>
  ))

  render() {
    const { values, errors } = this.state;
    const { cancelButton, submitButton, deleteButton, loading } = this.props;

    const actionButtonsLeft = [<Button
      data-action="cancel"
      round
      transparent
      floated="left"
      text={cancelButton.text}
      disabled={loading}
      onClick={this.props.onClose}
    />];
    const actionButtonsRight = [
      this.props.edit ?
        <Button
          data-action="delete"
          round
          red
          transparent
          style={{ marginRight: '12px' }}
          icon={deleteButton.icon && { name: deleteButton.icon }}
          text={deleteButton.text}
          loading={loading}
          disabled={loading}
          onClick={(e, event) => deleteButton.onClick(e, event)}
        /> : null,
      <Button
        data-action="submit"
        round
        icon={submitButton.icon && { name: submitButton.icon }}
        text={submitButton.text}
        loading={loading}
        disabled={loading}
        onClick={(e, event) => this.handleSubmit(e, event)}
      />
    ];

    const hasTitleError = errors && errors.title;

    return (
      <Form
        id="LabelForm"
        {...this.props}
        onSubmit={(e, event) => this.handleSubmit(e, event)}
        paddingHeader={this.isMobile()}
        actionButtonsLeft={!this.isMobile() ? actionButtonsLeft : null}
        actionButtonsRight={!this.isMobile() ? actionButtonsRight : null}
        header={this.isMobile() ? {
          title: '',
          onClose: () => this.props.onClose(),
          invertCloseButton: true,
          headerItem: <Button
            data-action="submit"
            round
            loading={loading}
            text={submitButton.text}
            onClick={(e, event) => this.handleSubmit(e, event)}
            style={{ height: '40px', width: '100px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}
          />
        } : null}
      >
        <div>
          <Form.Input
            label={__('Label name')}
            name="title"
            placeholder={__('Ex: Important')}
            containerInputStyle={{ border: hasTitleError ? '1px solid #bf2600' : '1px solid rgba(0, 0, 0, 0.16)', borderRadius: '0.5rem', height: '50px', display: 'flex', justifyContent: 'space-between' }}
            inputStyle={{ border: 'none', width: '60%', height: '47px' }}
            value={values.title}
            onChange={e => this.onTextInputChange(e, { name: 'title', value: e.target.value, maxDigits: 15 })}
            actionRight={<span style={{ color: '#999999', paddingRight: '20px' }}>{__('%s characters left', 15 - (values.title.length || 0))}</span>}
            error={hasTitleError ? __('Label name cannot be empty') : null}
          />
        </div>
        <div style={{ marginBottom: '1.7rem', marginTop: '1.2rem' }}>
          <span style={{ fontSize: '1.143rem' }}>{__('Label color')}</span>
          <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '12px' }}>
            {this.renderColorOptions(values.color)}
          </div>
        </div>
      </Form>
    );
  }
}
