import React from 'react';

import TermsAndPrivacy from '../../components/TermsAndPrivacy';

const styles = {
  form: {
    maxWidth: '800px',
    padding: '0 14px 70px',
    overflow: 'hidden',
    margin: 'calc(60px + 2em) auto 0'
  }
};
export default class ClassAppTerms extends React.Component {
  render() {
    const { location } = this.props;
    const params = location.query.type ? location.query.type.split(' ') : ['noentity'];
    return (<div style={styles.form}>
      <TermsAndPrivacy
        entityTypes={params}
      />
    </div>);
  }
}
