import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';

import { __ } from '../../i18n';
import Modal from '../../components/Modal';

/**
 * @typedef {{
 *  boletoLink: string;
 *  boletoReference: string;
 * }} Props
 * @extends {Component<Props>}
 */
@inject('store')
@observer
export default class BoletoPaymentModal extends Component {
  render() {
    const { boletoLink, boletoReference } = this.props;

    return (
      <Modal
        key={'modal-leave'}
        closeIcon="times close"
        onClose={() => this.props.store.appends.pop()}
        closeOnRootNodeClick={false}
        header={__('Bank Boleto')}
        content={(
          <div style={{ display: 'grid' }}>
            <div
              style={{
                width: '100%',
                margin: 'auto',
                textAlign: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                borderColor: '#A5A5A5'
              }}
            >
              <p style={{ fontSize: 20, width: 150, margin: 'auto', paddingTop: 25, paddingBottom: 25 }}>
                {boletoReference}
              </p>
            </div>
            <span
              style={{
                paddingTop: '10px',
                marginBottom: '10px',
                textAlign: 'center',
                width: '100%',
                margin: 'auto',
                color: '#A5A5A5'
              }}
            >
              {__('This is the scannable line from your boleto. You can copy and paste it directly into your banking application')}
            </span>
          </div>
        )}
        actions={[
          <a
            className="wordWrapped"
            target="_blank"
            rel="noreferrer noopener"
            href={boletoLink}
          >
            <Button
              data-action="cancel"
              key={0}
              basic
              floated="right"
              content={__('Open PDF')}
              style={{ marginBottom: '10px' }}
            />
          </a>,
          <Button
            data-action="cancel"
            key={0}
            basic
            floated="left"
            content={__('Close')}
            style={{ marginBottom: '10px' }}
            onClick={() => this.props.store.appends.pop()}
          />
        ]}
      />
    );
  }
}
