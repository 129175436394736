import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import SurveyForm from './Form';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`query SurveyEditQuery ($id: ID!) {
    node(id: $id) @connection(key: "Survey", filter: ["id"]) {
      ... on Survey {
        id: dbId
        name
        optionNumber
        options (orderBy: { column: ID, direction: ASC }) {
          nodes {
            id: dbId
            value
          }
        }
      dateLimit
    }
  }
}
`, {
  options: ownProps => ({
    variables: {
      id: (ownProps.surveyId || ownProps.survey.dbId)
    }
  })
})
@graphql(gql`mutation updateSurvey($updateSurveyMutation: UpdateSurveyInput!) {
  updateSurvey(input: $updateSurveyMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['MessageSurveyResultsEntityQuery', 'MessageSurveyResultsOrganizationQuery', 'MessageNodeEntityQuery', 'MessageNodeOrganizationQuery', 'SurveyEditQuery', 'MessageEntityHistoryQuery', 'MessageOrganizationHistoryQuery']
  }
})
@observer
export default class SurveyEdit extends Controller {
  treatValues = (values) => {
    const ids = this.props.data.node.options.nodes.map(option => option.id);

    values.options = values.options.map(value => value.trim());
    values.name = values.name.trim();
    if (!values.dateLimit) {
      return {
        name: values.name,
        optionNumber: values.optionNumber,
        options: values.options.map((value, i) => ({ id: ids[i], value }))
      };
    }

    const time = values.time ? values.time.split(':').map(t => parseInt(t, 10)) : values.time;
    let timeLimit = '23:59';

    if (time) {
      timeLimit = values.time;
    }

    values.dateLimit = new Date(values.dateLimit.getTime() - (values.dateLimit.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + ' ' + timeLimit;

    return {
      name: values.name,
      optionNumber: values.optionNumber,
      options: values.options.map((value, i) => ({ id: ids[i], value })),
      dateLimit: values.dateLimit
    };
  }

  request = (values) => {
    const { store, mutate, survey } = this.props;

    return mutate({
      variables: {
        updateSurveyMutation: {
          id: survey.dbId,
          ...this.treatValues(values)
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: __('%s was saved', values.name), success: true };
      store.appends.pop();
    });
  }

  render() {
    const { data, store } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { name, optionNumber, options, dateLimit } = data.node;

    return (
      <Controller
        id="SurveyEdit"
        modal={{
          fullScreen: this.isMobile(),
          header: this.isMobile() ? null : __('Edit survey'),
          portalHeader: this.isMobile()
        }}
        modalType={'beta'}
        edit
        title={__('Edit survey')}
        submitButton={{ text: __('Save'), isActionButtom: true }}
        cancelButton={{ text: __('Cancel'), isActionButtom: true }}
        form={SurveyForm}
        values={{
          name,
          optionNumber,
          options: options.nodes.map(option => option.value),
          dateLimit: dateLimit && new Date(dateLimit),
        }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        onCancel={() => store.appends.pop()}
        onClose={() => store.appends.pop()}
        {...this.props}
      />
    );
  }
}
