import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';

import LabelForm from './Form';

import { __ } from '../../i18n';

@inject('store', 'client')
@graphql(gql`mutation createLabel($CreateLabelInput: CreateLabelInput!) {
  createLabel(input: $CreateLabelInput) {
    label {
      id: dbId
    }
    clientMutationId
  }
}`, {
  options: {
  refetchQueries: ['OrganizationEditQuery', 'OrganizationEditMasterQuery']
  }
  })
@observer
export default class LabelAdd extends Controller {
  request = async (values) => {
    const { store, mutate, organization_id } = this.props;
    const { title, color } = values;
    this.setState({ loading: true });
    return mutate({
      variables: {
        CreateLabelInput: {
          organizationId: organization_id,
          title,
          color,
          type: 'DEFAULT'
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: __('New label created successfully'), success: true };
      this.props.store.appends.pop();
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <Controller
        id="LabelAdd"
        modal={{
          closeOnRootNodeClick: false,
          fullScreen: this.isMobile(),
          cssTags: this.isMobile() && 'ismobileadd',
          portalHeader: this.isMobile(),
          actions: false
        }}
        modalType={'beta'}
        add
        title={this.isMobile() ? null : __('Add label')}
        form={LabelForm}
        loading={this.state.loading}
        submitButton={{
          icon: 'check',
          text: __('Add'),
          isActionButtom: true
        }}
        cancelButton={{
          text: __('Cancel'),
          isActionButtom: true
        }}
        submitButtonIcon="check"
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        onClose={() => this.props.store.appends.pop()}
        {...this.props}
      />
    );
  }
}
