import React from 'react';
import { render } from '../../../newRender';
import ArrivedTermsAndPrivacy from '../ArrivedTermsAndPrivacy';

describe('ArrivedTermsAndPrivacy', () => {
  it('should render', () => {
    const query = {
      type: 'admin',
    };
    const location = {
      query,
    };

    const wrapper = render(<ArrivedTermsAndPrivacy location={location} />);
    expect(wrapper).toBeDefined();
  });
});
