import React from 'react';
import { List, Button, Icon, Header, Label, Popup } from 'semantic-ui-react';
import gql from 'graphql-tag';

import { __ } from '../../i18n';

export default class AddressItem extends React.Component {
  static fragments = {
    address: gql`
      fragment AddressItem on Address {
        id: dbId
        address
        type
        status
        userId
        tags {
          nodes {
            name
          }
        }
        user {
            fullname
          }
      }`,
  }

  render() {
    const { address, onDelete, onInvite } = this.props;
    const { type, user, status, userId } = address;
    const tags = (address && address.tags && address.tags.nodes) || (address && address.tags);

    return (
      <List.Item
        className="addressItem"
        title={address.address}
        data-id={address.id}
      >
        <Icon name={type === 'PHONE' ? 'phone' : 'envelope'} />

        <Popup
          trigger={<span style={{ width: '90%', overflow: 'hidden', textOverflow: 'ellipsis' }} className="user-email">{address.address}</span>}
          content={<span>{address.address}</span>}
          hideOnScroll
          basic
        />

        {tags && <div className="tags">
          {tags.map((tag, i) => <Label key={i} basic>
            <Icon name="tag" />{typeof tag === 'string' ? tag : tag.name}
          </Label>)}
        </div>}

        {user && user.fullname && <Label className="user-email">{user.fullname}</Label>}

        <Button.Group basic>
          {!userId && onInvite &&
          <Button
            basic
            data-action="invite"
            size="small"
            onClick={onInvite}
          >
            {status > 0 ? __('Invite again (%s sent)', status) : __('Invite')}
          </Button>}
          {onDelete && <Button
            basic
            data-action="delete"
            icon="trash"
            onClick={onDelete}
          />}
        </Button.Group>
      </List.Item>
    );
  }
}
