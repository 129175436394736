import React from 'react';
import { Button, Segment, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import gql from 'graphql-tag';

import { __ } from '../../i18n';
import Modal from '../../components/Modal';
import Countdown from '../../components/Countdown';
import PixPaymentModal from './PixPaymentModal';
import * as utils from '../../utils';

@inject('store')
@observer
export default class PaymentDetails extends React.Component {
  static fragments = {
    payment: gql`
      fragment PaymentDetails on Charge {
        payments{
          nodes {
            id: dbId
            method
            link
            installments
            expirationDate
            reference
            user {
              id: dbId
              fullname
            }
          }
        }
      }
      `,
  }
  constructor(props) {
    super(props);
    this.currency = {
      BRL: 'R$',
      USD: 'US$'
    };
  }

  renderItem = (iconName, title, description, countdown = false) => {
    const { payment } = this.props;

    return (
      <div style={{ display: 'table', marginBottom: '14px' }}>
        <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
          <Icon name={iconName} size="large" />
        </div>
        <div style={{ marginLeft: '10px' }}>
          <p style={{ marginBottom: '2px', fontWeight: 'bold', fontSize: '16px' }}>{title}</p>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <p style={{ fontSize: '14px', color: '#868e96' }}>{description}</p>
            {countdown &&
              <span>
                &nbsp;
                <p style={{ display: 'inline', fontSize: '14px', color: '#868e96' }}>{__('expires on')}</p>
                &nbsp;
                <Countdown finalDate={new Date(payment.expirationDate)} onFinish={() => this.props.store.appends.pop()} inline />
              </span>
            }
            {description === 'Boleto' &&
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <p style={{ fontSize: '14px', marginLeft: '5px', marginRight: '5px', color: '#868e96' }}>•</p>
                <a
                  className="wordWrapped"
                  target="_blank"
                  rel="noreferrer noopener"
                  href={this.props.payment.link}
                  style={{ fontSize: '14px' }}
                >
                  {__('View it again')}
                </a>
              </div>}
          </div>
        </div>
      </div>
    );
  }

  renderLastUpdate = (dateString) => {
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);

    return utils.simpleDate(dateString, true, 'LL, HH:mm', lang);
  }

  render() {
    const { payment, charge } = this.props;
    const pixExpirationDate = payment.method === 'PIX' && new Date(payment.expirationDate);
    const showCountdown = payment.status === 'PENDING' && pixExpirationDate && pixExpirationDate.getTime() - new Date().getTime() <= 24 * 60 * 60 * 1000; // less than a day left

    return (
      <Modal id="PaymentDetails" open size="mini" closeIcon="times close" onClose={() => this.props.store.appends.pop()}>
        <Modal.Header>
          {__('Payment Details')}
        </Modal.Header>
        <Modal.Content >
          <Segment style={{ padding: '24px' }}>
            <Segment>
              <div className="bold" style={{ marginBottom: '8px' }}>
                <Icon name="donate" style={{ color: '#0080ff', marginRight: '6px' }} />
                {charge.name}
              </div>
              <div style={{ backgroundColor: '#f1f3f5', paddingTop: '5px', paddingBottom: '5px', textAlign: 'center' }}>
                <p style={{ color: '#868e96', marginBottom: '0px', fontSize: '12px' }}>
                  {__('Amount paid')}
                </p>
                <p style={{ color: '#007fff', fontSize: '21px', fontWeight: 'bold' }}>
                  {charge && payment.paidAmount && `${payment.paidAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                </p>
              </div>
            </Segment>
            {this.renderItem('user', __('Paying User'), payment.user.fullname)}
            {this.renderItem('check', __('Status'), payment.status === 'PAID' ? __('Payment confirmed') : payment.status === 'PENDING' ? __('Payment processing') : (payment.status === 'CANCELED' ? __('Payment canceled') : __('Payment not made')))}
            {this.renderItem('barcode', __('Payment Method'), payment.method === 'CARD' ? __('Credit Card (%sx)', payment.installments) : (payment.method === 'EXTERNAL' ? __('External') : payment.method === 'BOLETO' ? __('Boleto') : __('Pix')), showCountdown)}
            {this.renderItem('clock outline', __('Last Update'), this.renderLastUpdate(payment.lastStatusUpdate))}

          </Segment>
        </Modal.Content>
        {payment.method === 'PIX' && payment.status === 'PENDING' &&
          <Modal.Actions>
            <Button
              basic
              floated="left"
              content={__('Open QR Code')}
              onClick={() => {
                this.props.store.appends.push(
                  <PixPaymentModal
                    expiration={new Date(payment.expirationDate)}
                    code={payment.reference}
                    paymentAmount={payment.paidAmount}
                  />
                );
              }}
            />
            <Button
              className="blue"
              content={__('Copy code')}
              onClick={() => {
                navigator.clipboard.writeText(payment.reference).then(() => {
                  this.props.store.snackbar = { active: true, message: __('Code copied successfully'), success: true };
                });
              }}
            />
          </Modal.Actions>
        }
      </Modal>
    );
  }
}
