import React from 'react';
import moment from 'moment';
import { Container, Image, Popup, Icon } from 'semantic-ui-react';
import InputMask from 'react-input-mask';
import { inject, observer } from 'mobx-react';
import Form from '../../components/Form';
import PhoneInput from '../../components/PhoneInput';
import { __ } from '../../i18n';
import { acceptedBrands, brandLogos, numberToBrand, brandToMask } from '../../lib/payment';
@inject('store') @observer
export default class CardForm extends Form {
  defaultValues = {
    ccNumber: '',
    name: '',
    expiration: '',
    cvv: '',
    documentNumber: (this.props && this.props.store && this.props.store.currentUser && this.props.store.currentUser.documentNumber) || '',
    email: (this.props && this.props.store && this.props.store.currentUser && this.props.store.currentUser.email) || '',
    phone: (this.props && this.props.store && this.props.store.currentUser && this.props.store.currentUser.phone) || ''
  }

  maybes = {
    documentNumber: [
      'required', values => (values.oldDocumentNumber.length > 0) && !values.documentNumber
    ]
  }

  rules = {
    name: ['required', values => this.validateContent('fullname', values)],
    ccNumber: ['required', values => this.validateContent('ccNumber', values)],
    expiration: ['required', values => this.validateContent('expiration', values)],
    cvv: ['required', values => this.validateContent('cvv', values)],
    documentNumber: ['required', 'CPF'],
    email: ['required', 'email'],
    phone: ['required', 'mobile']
  }

  beforeSubmit = (values) => {
    values.ccNumber = values.ccNumber.replace(/[-_]+/g, '');
    values.documentNumber = values.documentNumber.replace(/[.-]+/g, '');
    values.brand = numberToBrand(values.ccNumber || '');
    values.expiration = values.expiration.replace(/_+/g, '');
    values.cvv = values.cvv.replace(/_+/g, '');
    values.email = values.email;
    return values;
  }

  validateContent = (field, values) => {
    if (values.trim().length === 0) {
      throw new Error(__('Cannot be empty'));
    }
    if (field === 'ccNumber' && (!numberToBrand(values, false))) {
      throw new Error(__('Invalid card number'));
    }
    if (field === 'expiration') {
      if (values.length !== 7) {
        throw new Error(__('Invalid expiration date'));
      }
      const [month, year] = values.split('/');
      if (moment(`${year}-${month}`).endOf('month') < moment()) {
        throw new Error(__('Invalid expiration date'));
      }
    }
    if (field === 'documentNumber' && !(/^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2})|([0-9]{11}))$/.test(values))) {
      throw new Error(__('Invalid document number'));
    }
    if (field === 'cvv' && values.length < 3) {
      throw new Error(__('Invalid cvv number'));
    }
    if (field === 'fullname' && values.replace(/\s/g, '').match(/[0-9]/)) {
      throw new Error(__('Invalid name. Write the name without numbers'));
    }
    if (field === 'fullname' && !values.replace(/\s/g, '').match(/^[a-zA-Z]*$/)) {
      throw new Error(__('Invalid name. Write the name without accent marks'));
    }
  }

  renderAcceptedCards = () => {
    const { ccNumber } = this.state.values;

    const brand = numberToBrand(ccNumber || '');

    return (
      <Container textAlign="center" style={{ marginBottom: 24 }}>
        <span style={{ opacity: 0.5 }}>{__('Accepted Cards')}</span>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
          {brandLogos.map((logo, i) => <Image style={{ height: 24, marginLeft: 5, marginRight: 5 }} src={logo[!!brand && acceptedBrands[i] !== brand ? 'noColor' : 'colored']} />)}
        </div>
      </Container>
    );
  }

  render() {
    const { values, errors } = this.state;

    const userHasEmail = this.defaultValues.email.length > 0;

    const brand = numberToBrand(values.ccNumber);
    const cardMask = brandToMask(brand);

    return (
      <Form id="CardForm" {...this.props} onSubmit={this.handleSubmit}>
        {this.renderAcceptedCards()}
        <InputMask mask={cardMask} value={values.ccNumber} onChange={e => this.onInputChange(null, e.target)} >
          {
            inputProps =>
              <Form.Input
                name="ccNumber"
                {...inputProps}
                placeholder="••••-••••-••••-••••"
                label={__('Card Number')}
                error={errors && errors.ccNumber}
              />
          }
        </InputMask>

        <Form.Group>
          <InputMask mask="99/9999" value={values.expiration} onChange={e => this.onInputChange(null, e.target)}>
            {
              inputProps =>
                <Form.Input
                  name="expiration"
                  {...inputProps}
                  placeholder={__('MM/YYYY')}
                  label={__('Expiration')}
                  error={errors && errors.expiration}
                  size={'mini'}
                  style={{ fontSize: '1em', width: '108px', height: '38px' }}
                />
            }
          </InputMask>
          <div style={{ margin: '0 auto' }}>
            <InputMask mask="9999" value={values.cvv} onChange={e => this.onInputChange(null, e.target)}>
              {
                inputProps =>
                  <Form.Input
                    name="cvv"
                    {...inputProps}
                    placeholder="••••"
                    label={__('CVV')}
                    error={errors && errors.cvv}
                    size={'mini'}
                    style={{ fontSize: '1em', width: '108px', height: '38px' }}
                  />
              }
            </InputMask>
          </div>
        </Form.Group>
        <Form.Input
          label={__('Name')}
          placeholder={__('Insert your name')}
          name="name"
          value={values.name}
          onChange={(e, { value }) => this.onTextInputChange(null, { name: 'name', value: value && value.normalize('NFD').replace(/[\u0300-\u036f]/g, '') })}
          error={errors && errors.name}
        />
        <Form.Input
          label={
            <span>
              {__('Email')}
              {userHasEmail && <Popup
                trigger={<Icon style={{ marginLeft: '3px' }} name="info circle" color="grey" />}
                content={<span>{__('Email registered on your ClassApp user')}</span>}
              />}
            </span>
          }
          placeholder={__('Insert your email')}
          name="email"
          value={values.email}
          onChange={this.onTextInputChange}
          error={errors && errors.email}
          readOnly={userHasEmail}
        />
        <Form.Input
          label={__('Phone')}
          name="phone"
          type="text"
          defaultValue={values.phone}
          error={errors && errors.phone}
          placeholder={__('Area Code + Phone')}
          control={PhoneInput}
          onChange={value => this.onInputChange(null, { name: 'phone', value: value && value.replace(/[^0-9 ()+-]+$/, ''), checked: '' })}
          optional
        />
        <InputMask mask="999.999.999-99" value={values.documentNumber} onChange={e => this.onInputChange(null, e.target)}>
          {
            inputProps =>
              <Form.Input
                name="documentNumber"
                {...inputProps}
                placeholder="000.000.000-00"
                label={__('Document Number')}
                error={errors && errors.documentNumber}
              />
          }
        </InputMask>
      </Form>
    );
  }
}
