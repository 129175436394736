import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';

import { __ } from '../../i18n';

@inject('store', 'client')
@graphql(gql`query TagDeleteQuery ($id: ID!) {
  node(id: $id) @connection(key: "Organization", filter: ["id"]) {
    ... on Organization {
      id: dbId
      tags(limit: 400) {
        nodes {
          name
          id: dbId
        }
      }
    }
  }
}
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.organization_id
    }
  })
})
@graphql(gql`mutation deleteTag($deleteTagMutation: DeleteTagInput!) {
  deleteTag(input: $deleteTagMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['OrganizationEditQuery', 'OrganizationEditMasterQuery', 'TagDeleteQuery']
  }
})
@observer
export default class TagDelete extends Controller {
  request = () => {
    const { client, store, mutate, params, router } = this.props;

    return mutate({
      variables: {
        deleteTagMutation: {
          id: params.tag_id
        }
      }
    }).then(() => {
      const previousTags = store.currentOrganization.tags.nodes;
      const nextTags = previousTags.filter(tag => tag.id !== Number(params.tag_id));

      client.writeQuery({
        query: gql` query OrganizationNodeIdQuery($id: Int!) {
            node(id: $id) @connection(key: "Organization", filter: ["id"]) {
              ... on Organization {
              id: dbId
              tags {
                nodes {
                  id: dbId
                  name
                }
              }
            }
            }
          }`,
        data: {
          node: {
            __typename: 'Organization',
            id: params.organization_id,
            tags: {
              nodes: nextTags,
              __typename: 'OrganizationTagsConnection'
            },
          },
        },
        variables: {
          id: params.organization_id
        }
      });

      store.snackbar = { active: true, message: __('Tag was deleted'), success: true };
      router.push(`/organizations/${params.organization_id}/edit`);
    });
  }

  render() {
    return (
      <Controller
        id="TagDelete"
        delete
        object={__('tag')}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
