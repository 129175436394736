import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Controller from '../../components/Controller';
import Modal from '../../components/Modal';
import AccessForm from './Form';
import { __ } from '../../i18n';
import * as utils from '../../utils';
@inject('store')

@graphql(gql`query OrganizationAccessesEditQuery($id: ID!) {
  node(id: $id) @connection(key: "Link", filter: ["id"]) {
    ... on Link {
      id : dbId
      icon
      title
      url
      type
      organizationId
    }
  }
}`, {
  options: ownProps => ({
    variables: { id: ownProps.params.access_id }
  })
})

@graphql(gql`mutation updateLink($updateLinkMutation: updateLinkInput!) {
  updateLink(input: $updateLinkMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['OrganizationAccessesQuery', 'OrganizationAccessesEditQuery']
  }
})

@observer

export default class AccessEdit extends Controller {

  request = (values) => {
    values.url = (values.url.indexOf('http://') === -1) && (values.url.indexOf('https://') === -1) ? 'http://' + values.url : values.url;

    return this.props.mutate({
      variables: {
        updateLinkMutation: {
          id: this.props.params.access_id,
          ...values
        }
      }
    }).then(() => {
      this.props.store.snackbar = { active: true, message: __('%s was saved', values.title), success: true, dismissAfter: 3000 };
      this.props.router.push(`/organizations/${this.props.params.organization_id}/accesses`);
    });
  }

  render() {
    const { data, params } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;
    const { icon, title, url, type } = data.node;

    return (
      <Controller
        id="AccessEdit"
        modal
        edit
        title={__('Edit access')}
        form={AccessForm}
        values={{ icon, title, url, type }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
