import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { words } from 'lodash';
import { Button, Loader, Menu, Label, Header, Popup, Breadcrumb, Form, Divider, Grid, Segment, Icon, Statistic, Dimmer } from 'semantic-ui-react';

import TableView from '../../components/TableView';
import Page from '../../components/Page';


import { __ } from '../../i18n';

const PAGE_SIZE = 40;

@inject('store')
@graphql(gql`query IntegrationQuery($id: ID!){
  node(id: $id){
    ...on Organization{
      integration{
        nodes{
          id: dbId
          time
          isActive
          onlyReport
          configuration
          module{
            id: dbId
            name
            applicationId
            activationType
            # type
            # baseConfiguration
            # formConfiguration
          }
          application{
            id: dbId
            name
            # baseConfiguration
            # formConfiguration
          }
          organization{
            id: dbId
          }
        }
      }
    }
  }
}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      id: ownProps.params.organization_id,
      limit: PAGE_SIZE,
      offset: 0,
      search: ownProps.location.query.search || '',
      type: (ownProps.location.query.type && [ownProps.location.query.type]) || []
    }
  })
})
@observer
export default class IntegrationNode extends Page {
  /* renderRowCells = integration => [
    <span>{integration.module.name}</span>,
    <Icon style={{ color: (integration.isActive) ? '#099268' : '#F03E3E' }} name={((integration.isActive) ? 'check' : 'times') + ' circle'} />,
  ]; */

  renderHeader = () => <div>
    <Header as="h5" style={{ textAlign: 'left', fontSize: '.9rem' }}>
      <Header.Content>
        <Icon name="puzzle piece" color="blue" />
        {__('Installed Integrations').toUpperCase()}
      </Header.Content>
    </Header>
    <Divider hidden />
  </div>

  render() {
    const { data, params } = this.props;

    if (data.loading && !data.node) return <Loader active inline="centered" />;

    const integration = data && data.node && data.node.integration;
    const nodes = integration && integration.nodes;

    return (
      <Grid.Column id="IntegrationNode" key={'integrations'} className="noPadding">
        <Grid.Row>
          <Segment textAlign="center" style={{ border: 'none', padding: '30px' }}>
            {data.loading && <Dimmer active inverted>
              <Loader />
            </Dimmer>
            }
            {this.renderHeader()}
            {
              nodes && nodes.length > 0 ?
                <div >
                  {nodes.map((element) => {
                    const module_name = words(element.module.name);
                    return (<Segment basic padded>
                      <span style={{ float: 'left' }}>{module_name[0] + ' ' + module_name[1]}</span>
                      {
                        (element.isActive) ?
                          (<div style={{ float: 'right', color: '#099268', width: '100px', textAlign: 'left' }}><Icon medium name={'check filled circle'} />{__('Active (ativo)')}</div>) :
                          (<div style={{ float: 'right', color: '#F03E3E', width: '100px', textAlign: 'left' }}><Icon medium name={'times filled circle'} />{__('Inactive')}</div>)
                      }

                    </Segment>);
                  })}
                </div>
                :
                <Segment basic secondary padded textAlign="center">
                  {__('There are no Installed Integrations.')}
                </Segment>
            }
          </Segment>
        </Grid.Row>
      </Grid.Column>
    );
  }
}
