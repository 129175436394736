import React from 'react';
import { render, fireEvent } from '../../../newRender';
import ConfirmationModal from '../ConfirmationModal';
import { __ } from '../../../i18n';
import { browserHistory } from 'react-router';

jest.mock('react-router', () => ({
  browserHistory: {
    push: jest.fn(),
  }
}))

const mockProps = {
  onConfirm: jest.fn(),
  onClose: jest.fn(),
  userName: 'mock user',
  documentNumber: '123456789',
  store: {
    appends: {
      push: jest.fn(),
    },
  },
};


describe('ConfirmationModal', () => {
  it('should render modal', () => {
    const { getByText } = render(<ConfirmationModal {...mockProps} />);
    expect(getByText(__('Do you want to sign this contract?'))).toBeInTheDocument();
  });

  it('should call onConfirm when confirm button is clicked', async () => {
    const { getByText } = render(<ConfirmationModal {...mockProps} />);
    const confirmButton = getByText(__('Yes, I want to sign'));
    fireEvent.click(confirmButton);
    expect(mockProps.onConfirm).toHaveBeenCalledTimes(1);
  });

  it('should call onClose when back button is clicked', async () => {
    const { getByText } = render(<ConfirmationModal {...mockProps} />);
    const closeButton = getByText(__('Back'));
    fireEvent.click(closeButton);
    expect(mockProps.onClose).toHaveBeenCalledTimes(1);
  });

  it('should be able to redirect to user account screen when review button is clicked', async () => {
    const { getByText } = render(<ConfirmationModal {...mockProps} />);
    const reviewButton = getByText(__('Review signature details'));
    fireEvent.click(reviewButton);
    expect(browserHistory.push).toHaveBeenCalledWith("/user/account");
  });
});
