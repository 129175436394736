/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import cookie from 'react-cookie';
import { Menu, Sidebar, Container, Loader, Image, Label } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { words } from 'lodash';

import Snackbar from '../components/Snackbar';
// import WhatIsNew from '../components/WhatIsNew';
import NoJavascript from '../components/NoJavascript';
import Announcements from '../components/Announcements';
import Banner from '../components/Banner';
import Responsive from '../components/Responsive';

import i18n from '../i18n';
import * as utils from '../utils';

@inject('store')
@graphql(gql`query AppQuery {
  viewer {
    id: dbId
    fullname
    phone
    email
    confirmPhone
    confirmEmail
    hasPassword
    isMaster
    language
  }
}
`, {
  options: {
    fetchPolicy: 'network-only'
  }
})
@observer
export default class CleanHome extends Responsive {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;

    if ((data.loading && !data.viewer) || !data.viewer) return;

    const { store } = this.props;

    if (store.app.locale !== data.viewer.language) {
      cookie.save('lang', data.viewer.language, { path: '/', maxAge: 60 * 60 * 24 * 7 });
      store.app.locale = data.viewer.language;
      i18n.locale = store.app.locale;
    }

    this.props.store.currentUser = Object.assign({}, data.viewer);
  }

  renderPrepends = () => this.props.store.prepends.map((prepend, i) =>
    <div key={i}>{prepend}</div>
  )

  renderAppends = () => this.props.store.appends.map((append, i) =>
    <div key={i}>{append}</div>
  )

  render() {
    let { store, location } = this.props;
    let { currentUser, app } = store;

    if (!currentUser) return <Loader active />;

    let items = [
      app.env !== 'production' ?
        <Menu.Item key={1002}>
          <Label color={utils.envColor(app.env)} as="a" href={app.url}>{app.env}</Label>
        </Menu.Item> : null
    ];

    return (
      <Sidebar.Pushable id="Home">
        { this.renderPrepends()}
        <div className={'ui loaderbar ' + (store.pageLoading && 'active')} />
        <Menu fixed="top" borderless>
          <Container>
            {items.map(item => item)}
            <Image src={utils.asset('/logo/ClassApp-LOGO-SIMBOLO_azul.png')} size="mini" centered className="logo" as={Link} to={'/'} />
          </Container>
        </Menu>
        {/* {isAdminOrStaff(store) && store.banners.indexOf('hasSeenClassUPBanner') === -1 && <Banner />} */}
        <NoJavascript />
        <Sidebar.Pusher className={this.isMobile() ? 'md' : ''}>
          {/* <div style={{ height: isAdminOrStaff(store) && store.banners.indexOf('hasSeenClassUPBanner') === -1 ? 110 : 55 }} /> */}
          <div style={{ height: 64 }} />
          <Container style={{ marginTop: '40px' }} fluid={words(location.pathname)[0] === 'start'}>
            {this.props.children}
          </Container>
        </Sidebar.Pusher>
        { this.renderAppends()}
        <Snackbar />
        {/* <Announcements /> */}
      </Sidebar.Pushable>
    );
  }
}
