import React from 'react';
import TermsAndPrivacy from '../TermsAndPrivacy';
import { render } from '../../../newRender';

describe('TermsAndPrivacy', () => {
  it('should be defined', () => {
    expect(TermsAndPrivacy).toBeDefined();
  });

  it('should render', () => {
    const mockLocation = {
      query: {
        type: 'entity1 entity2',
      },
    };

    const wrapper = render(<TermsAndPrivacy location={mockLocation} />);
    expect(wrapper).toBeDefined();
  });
});
