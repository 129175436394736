import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';

import GroupForm from './Form';

import { __ } from '../../i18n';

@inject('store', 'client')
@graphql(
  gql`
    mutation createGroup($createGroupMutation: CreateGroupInput!) {
      createGroup(input: $createGroupMutation) {
        clientMutationId
      }
    }
  `,
  {
    options: {
      refetchQueries: ['OrganizationGroupsQuery', 'RoleFormGroupsQuery'],
    },
  },
)
@observer
export default class GroupAdd extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Add group');
  }

  request = ({ name, internal, seeAll, type, markerIds }) => {
    const { store, params, router, mutate } = this.props;

    let input = {
      organizationId: params.organization_id,
      name,
      internal,
      seeAll,
      type,
    };

    if (markerIds.length > 0) {
      input.markerIds = markerIds;
    }

    return mutate({
      variables: {
        createGroupMutation: {
          ...input,
        },
      },
    }).then(() => {
      store.snackbar = {
        active: true,
        message: __('%s was added', name),
        success: true,
      };
      router.push(`/organizations/${params.organization_id}/groups/`);
    });
  };

  render() {
    return (
      <Controller
        id="GroupAdd"
        modal
        add
        title={__('Add group')}
        form={GroupForm}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        formProps={{
          markers: this.state.markers,
        }}
        {...this.props}
      />
    );
  }
}
