import React from 'react';
import { Icon, Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { __ } from '../../i18n';
import Modal from '../../components/Modal';

const styles = {
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px'
  },
  confirmButton: {
    backgroundColor: '#0069FF',
    height: '43px',
    color: '#FFF',
    borderRadius: '20px'
  },
  cancelButton: {
    height: '43px',
    borderRadius: '20px'
  },
  content: {
    textAlign: 'left'
  }
};

@inject('store')
@observer
export default class SendInviteModal extends React.Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <Modal
        closeIcon={'times close inside'}
        header={__('Sending Invitations')}
        onClose={() => this.props.store.appends.pop()}
        content={
          <div>
            <span style={styles.content}>{__('Invitations to access will be sent to the people listed by you. Do you want to confirm this action?')}</span>
            <div style={styles.footer}>
              <Button
                basic
                style={styles.cancelButton}
                content={__('Close')}
                onClick={() => this.props.store.appends.pop()}
              />
              <Button
                primary
                onClick={handleSubmit}
                style={styles.confirmButton}
                content={__('Confirm')}
              />
            </div>
          </div>}
      />
    );
  }
}
