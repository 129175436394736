import React from 'react';
import { Icon, Label, Popup } from 'semantic-ui-react';
import gql from 'graphql-tag';

import Button from '../../../components/ui/Button';
import { __ } from '../../../i18n';
import Responsive from '../../../components/Responsive';

const styles = {
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    height: '44px',
    borderRadius: '200px',
    maxWidth: '100%',
    marginBottom: '10px',
    marginRight: '10px'
  },
  icon: {
    margin: '10px',
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: 1
  },
  address: {
    fontSize: '16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '70%'
  },
  rightItems: {
    display: 'flex',
  },
  leftItems: {
    display: 'flex',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignItems: 'center'
  },
  closeButton: {
    border: 'none',
    background: 'none',
    minWidth: '20px',
    minHeight: '20px',
    width: '20px',
    height: '20px',
    margin: '0 1px 0 7px'
  },
  closeIcon: {
    fontSize: '16px',
    fontWeight: 300,
    margin: 0
  },
  tag: {
    fontSize: '16px',
    fontWeight: 400,
    borderRadius: '6px',
    padding: '5px'
  }
};

export default class AddressItem extends Responsive {
  static fragments = {
    address: gql`
      fragment AddressItem on Address {
        id: dbId
        code
        address
        type
        status
        userId
        tags {
          nodes {
            name
          }
        }
        user {
            fullname
          }
      }`,
  }

  renderMobileTags = tags => (
    <div>
      {tags && tags.length === 1 &&
      <div className="tags" style={{ display: 'inline-block', marginLeft: '7px' }}>
        <Label style={{ ...styles.tag }} key={1} basic>
          {typeof tags[0] === 'string' ? (tags[0].length > 12 ? tags[0].substring(0, 12).trim() + '...' : tags[0]) : (tags[0].name.length > 12 ? tags[0].name.substring(0, 12).trim() + '...' : tags[0].name)}
        </Label>
      </div>
      }
      {tags && tags.length > 1 &&
      <Label style={{ ...styles.tag }} key={2} basic>
        <Popup
          trigger={<div>{`+${tags.length}`}</div>}
          content={
            <p>
              {tags.map((tag, i) => (i === tags.length - 1 ? (`${tag.name}`) : (`${tag.name},`)))}
            </p>
          }
        />
      </Label>
      }
    </div>
  );

  renderTags = tags  => (
    tags && tags.length > 0 &&
      <div className="tags" style={{ display: 'flex', marginLeft: '7px' }}>
        <Label style={{ ...styles.tag }} key={1} basic>
          {typeof tags[0] === 'string' ? (tags[0].length > 12 ? tags[0].substring(0, 12).trim() + '...' : tags[0]) : (tags[0].name.length > 12 ? tags[0].name.substring(0, 12).trim() + '...' : tags[0].name)}
        </Label>
        { tags.length > 1 &&
          <Label style={{ ...styles.tag }} key={2} basic>
            <Popup
              trigger={<div>{`+${tags.length - 1}`}</div>}
              content={
                <p>
                  {tags.filter((tag, i) => i != 0).map((tag, i) => (i !== tags.length - 2 ? `${tag.name}, ` : `${tag.name}`))}
                </p>
              }
            />
          </Label>
        }
      </div>
  );


  render() {
    const { address, onDelete, onInvite } = this.props;
    const { code, type, user, status, userId } = address;
    const tags = (address && address.tags && address.tags.nodes) || (address && address.tags);

    return (
      <div style={{ ...styles.item }}>
        <div style={{ ...styles.leftItems }}>
          <Icon style={{ ...styles.icon }} name={type === 'PHONE' ? 'mobile' : 'at'} />
          <Popup
            trigger={
              <span style={{ ...styles.address }}>
                {
                  // type !== 'PHONE' && address.address.length > 24 ?
                  //   address.address.split('@')[0].substring(0, (24 - address.address.split('@')[1].length)) + '...@' + address.address.split('@')[1]
                  //   :
                  address.address}
              </span>
            }
            content={
              <span>
                {address.address}
              </span>
            }
            hideOnScroll
            basic
          />
        </div>
        <div style={{ ...styles.rightItems }}>
          {this.isMobile() ? this.renderMobileTags(tags) : this.renderTags(tags)}
          {!userId && onInvite &&
            <Button
              round
              transparent
              style={{ marginLeft: '16px' }}
              data-action="invite"
              size="small"
              onClick={onInvite}
            >
              {status > 0 ? __('Invite again (%s sent)', status) : __('Invite')}
            </Button>}
          {onDelete &&
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '7px' }}>
            <Button
              round
              transparent
              style={{ ...styles.closeButton }}
              data-action="delete"
              icon={{ name: 'close', style: { ...styles.closeIcon } }}
              onClick={onDelete}
            />
          </div>}
        </div>
      </div>
    );
  }
}
