import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Card, Icon, Dropdown, Popup } from 'semantic-ui-react';

import { __ } from '../../i18n';
import * as utils from '../../utils';

import Modal from '../../components/ui/Modal';
import Responsive from '../../components/Responsive';
import OptionsToast from '../../components/OptionsToast';

const styles = {
  details: {
    borderRadius: '4px',
    backgroundColor: '#f1f3f5',
    marginBottom: '5px',
    padding: 8,
    width: '296px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

@inject('store') @observer
export default class FormItem extends Responsive {
  renderLimitDate = (form) => {
    const formDateLimit = String(form.dateLimit);
    const formattedDate = new Date(formDateLimit.substring(0, 15).split('-'));

    formattedDate.setHours(23);
    formattedDate.setMinutes(59);
    formattedDate.setSeconds(59);

    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);
    return utils.simpleDate(formattedDate, true, 'LL, HH:mm', lang);
  }


  renderMenu = () => {
    const { isMessageToApprove } = this.props

    const menu = [
      !isMessageToApprove && { dataAction: 'edit', text: __('Edit'), icon: 'edit', color: '#000000', closeOnClick: true, onClick: () => { this.props.onEdit(); } },
      { dataAction: 'delete', text: __('Delete Form'), icon: 'trash', color: '#BF2600', closeOnClick: true, onClick: () => { this.props.onDelete(); } },
      { dataAction: 'cancel', text: __('Cancel'), icon: 'times', color: '#000000', closeOnClick: true },
    ];

    return (
      this.props.store.appends.push(<Modal
        id="OptionsToast"
        toast
        invertCloseButton
        onClose={() => this.props.store.appends.pop()}
        closeOnRootNodeClick
        header={__('Form Options')}
        spaceFooter
        content={<OptionsToast menu={menu} />}
      />));
  }

  render() {
    const { form, id, isMessageToApprove } = this.props;
    const { name } = form;

    const items = [
      !isMessageToApprove ? <Dropdown.Item onClick={() => this.props.onEdit()}>{__('Edit')}</Dropdown.Item> : null,
      <Dropdown.Item onClick={() => this.props.onDelete()}>{__('Delete')}</Dropdown.Item>
    ];

    return (
      <Card
        data-type="FormItem"
        data-index={id}
        fluid
        style={{
          marginBottom: '1em',
          width: this.isMobile() ? '100%' : '355px',
          height: this.isMobile() ? '' : '159px',
          borderRadius: '12px',
          marginRight: '8px'
        }}
      >
        <Card.Content style={{ margin: 0 }}>
          <Container textAlign="center">
            <div className="horizontallySpacedItems" style={{ margin: this.isMobile() ? '22px 10px 14px' : '22px 24px 14px' }}>
              <div className="bold"><Icon name="poll h" style={{ color: '#000000', marginRight: '12px', fontSize: '16px', fontWeight: 300 }} />
                <Popup
                  trigger={<span>{(name.length > 25) ? (name.substring(0, 25) + '...') : name}</span>}
                  content={<span>{name}</span>}
                  hideOnScroll
                />
              </div>
              {
                (items.length > 0) ?
                  !this.isMobile() ?
                    <Dropdown data-testid="form-edit-menu" icon={null} trigger={<Icon name="ellipsis v" style={{ margin: 0 }} />}>
                      <Dropdown.Menu className="bold">
                        {items}
                      </Dropdown.Menu>
                    </Dropdown>
                    :
                    <Icon data-testid="form-edit-menu" name="ellipsis v" onClick={() => this.renderMenu()} style={{ margin: 0 }} />
                  :
                  null
              }
            </div>
            {
              ((form.description && form.description.length > 0) || !this.isMobile()) &&
              <div id="FormItemDetails" style={{ margin: this.isMobile() ? '0 18px' : '0 32px', marginBottom: form.dateLimit ? '14px' : '24px' }}>
                <div
                  style={{
                    borderRadius: '200px',
                    backgroundColor: '#ffffff',
                    display: 'table',
                    marginBottom: '5px',
                    width: '100%',
                    border: '1px solid #084FFF99'
                  }}
                >
                  <p style={{ fontSize: '13px', fontWeight: '700', padding: '12px', color: '#084FFF', display: 'table-cell', verticalAlign: 'middle' }}>
                    {
                      (form.description && form.description.length > 0) ?
                        (form.description.length > 35) ?
                          <Popup
                            trigger={<span>{form.description.substring(0, 35) + '...'}</span>}
                            content={<span>{form.description}</span>}
                            hideOnScroll
                          />
                          : form.description
                        : __('No description')
                    }
                  </p>
                </div>
              </div>
            }
            {
              form.dateLimit && form.hasDateLimit &&
              <p style={{ color: '#ffb43c', fontSize: '13px', fontWeight: 300, marginBottom: '24px' }}>
                <Icon name="exclamation circle" />
                {
                  __('Fillings until %s', this.renderLimitDate(form))
                }
              </p>
            }
          </Container>
        </Card.Content>
      </Card>
    );
  }
}
