import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import { __ } from '../../i18n';

@graphql(gql`mutation deleteForm($deleteFormMutation: DeleteFormInput!) {
  deleteForm(input: $deleteFormMutation) {
    form {
      dbId
    }
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['FormResultFormQuery']
  }
})
@inject('store') @observer
export default class FormDelete extends Controller {
  request = () => this.props.mutate({
    variables: {
      deleteFormMutation: {
        id: this.props.params.form_id
      }
    }
  }).then(() => {
    this.props.store.snackbar = { active: true, message: __('Form was deleted'), success: true };
    this.props.router.push(`/organizations/${this.props.params.organization_id}/forms`);
  })

  render() {
    return (
      <Controller
        id="FormDelete"
        delete
        object={__('form')}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
