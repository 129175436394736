import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Image } from 'semantic-ui-react';
import QRCode from 'react-qr-code';

import { __, currentLocale } from '../../i18n';
import Modal from '../../components/Modal';
import Countdown from '../../components/Countdown';

/** @type {{[key:string]: React.CSSProperties}} */
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  },
  qrCodeContainer: {
    marginTop: 28,
    marginBottom: 28,
    height: 'auto',
    maxWidth: 200,
    width: '100%'
  },
  title: {
    fontSize: 16,
    color: '#212529'
  },
  subText: {
    margin: '18px 56px 0px 56px',
    color: '#00000066',
    fontSize: 13
  },
  valueBox: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 6,
    marginRight: 10,
    padding: 16
  }
};

/**
 * @typedef {{
 *  expiration: Date;
 *  code: string;
 *  paymentAmount: number;
 * }} Props
 * @extends {Component<Props>}
 */
@inject('store')
@observer
export default class PixPaymentModal extends Component {
  render() {
    const { code, paymentAmount, expiration } = this.props;

    const showCountdown = expiration.getTime() - new Date().getTime() <= 24 * 60 * 60 * 1000; // less than a day left
    const expiresToday = showCountdown && expiration.getDate() === new Date().getDate();
    const formattedExpiration = expiration.toLocaleTimeString(currentLocale(), { hour: '2-digit', minute: '2-digit' });

    return (
      <Modal
        key={'modal-leave'}
        closeIcon="times close"
        onClose={() => this.props.store.appends.pop()}
        closeOnRootNodeClick={false}
        header={__('Pix')}
        content={(
          <div style={styles.container}>
            <div style={styles.qrCodeContainer} >
              <QRCode
                size={200}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={code}
                viewBox="0 0 200 200"
              />
            </div>
            <span style={styles.title}>{__('Copy or Scan the QR CODE')}</span>
            <span style={{ ...styles.subText, ...{ marginBottom: showCountdown ? 15 : 24 } }}>{__('Open the bank application and select "Pix" > "Read QR code". Point your phone camera at the code and make the payment quickly and securely.')}</span>
            {showCountdown &&
              <div>
                <span style={{ color: '#000', fontSize: 13 }}>{
                  expiresToday ? __('The code expires today, until %s.', formattedExpiration)
                    : __('The code expires tomorrow, until %s.', formattedExpiration)
                }</span>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, fontSize: 16 }}>
                  <div style={styles.valueBox}>{paymentAmount.toLocaleString(currentLocale(), { style: 'currency', currency: 'BRL' })}</div>
                  <Countdown finalDate={expiration} onFinish={() => this.props.store.appends.pop()} />
                </div>
              </div>
            }
          </div>
        )}
        actions={[
          <Button
            data-action="cancel"
            basic
            floated="left"
            content={__('Close')}
            style={{ marginBottom: '10px' }}
            onClick={() => this.props.store.appends.pop()}
          />,
          <Button
            className="blue"
            floated="right"
            content={__('Copy code')}
            style={{ marginBottom: '10px' }}
            onClick={() => {
              navigator.clipboard.writeText(code).then(() => {
                this.props.store.snackbar = { active: true, message: __('Code copied successfully'), success: true };
              });
            }}
          />
        ]}
      />
    );
  }
}
