import React from 'react';
import { render } from '../../../newRender';
import About from '../About';

describe('About', () => {
  it('should render', () => {
    const wrapper = render(<About />);
    expect(wrapper).toBeDefined();
  });
});
