import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';

import TagForm from './Form';

import { __ } from '../../i18n';
import { check } from 'checkit';

import { isNetwork } from '../../app/helpers/isNetwork';

@inject('store', 'client')
@graphql(
  gql`
    query TagAddQuery($id: ID!) {
      node(id: $id) @connection(key: "Organization", filter: ["id"]) {
        ... on Organization {
          id: dbId
          tags(limit: 400) {
            nodes {
              name
              id: dbId
            }
          }
        }
      }
    }
  `,
  {
    options: (ownProps) => ({
      variables: {
        id: ownProps.params.organization_id,
      },
    }),
  },
)
@graphql(
  gql`
    mutation createTag($createTagMutation: CreateTagInput!) {
      createTag(input: $createTagMutation) {
        tag {
          id: dbId
          name
        }
        clientMutationId
      }
    }
  `,
  {
    options: {
      refetchQueries: [
        'OrganizationEditQuery',
        'OrganizationEditMasterQuery',
        'TagAddQuery',
      ],
    },
  },
)
@observer
export default class TagAdd extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Add tag');
  }

  request = (values) => {
    const { client, store, mutate, params, router } = this.props;

    const variables = {
      organizationId: params.organization_id,
      name: values.name,
      checked: values.checked,
    };

    if (isNetwork(store.currentOrganization)) {
      variables.isNetworkTag = values.isNetworkTag;
    }

    return mutate({
      variables: {
        createTagMutation: {
          organizationId: params.organization_id,
          ...variables,
        },
      },
    }).then((response) => {
      const previousTags = store.currentOrganization.tags.nodes;
      const newTag = response.data.createTag.tag;

      client.writeQuery({
        query: gql`
          query OrganizationNodeIdQuery($id: Int!) {
            node(id: $id) @connection(key: "Organization", filter: ["id"]) {
              ... on Organization {
                id: dbId
                tags {
                  nodes {
                    id: dbId
                    name
                  }
                }
              }
            }
          }
        `,
        data: {
          node: {
            __typename: 'Organization',
            id: params.organization_id,
            tags: {
              nodes: [...previousTags, { ...newTag }],
              __typename: 'OrganizationTagsConnection',
            },
          },
        },
        variables: {
          id: params.organization_id,
        },
      });

      store.snackbar = {
        active: true,
        message: __('New tag created successfully'),
        success: true,
      };
      router.goBack();
    });
  };

  render() {
    const { store } = this.props;

    const organization = store?.currentOrganization;

    return (
      <Controller
        id="TagAdd"
        modal={{ closeOnRootNodeClick: false }}
        add
        title={__('Add tag')}
        form={TagForm}
        formProps={{
          organization,
        }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
