import { render } from '@testing-library/react';
import React from 'react';
import App from '../App';

const eventNavigation = jest.fn();
window.navigation = {
  addEventListener: eventNavigation,
};

describe('given App component', () => {
  it('should be able to render something using the App.js', () => {
    const { getByText } = render(
      <App>
        <h1>Test</h1>
      </App>
    );

    expect(getByText('Test')).toBeTruthy();
    expect(eventNavigation).toHaveBeenCalled();
  });
});
