import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';

import ApplicationForm from '../Application/Form';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`mutation createApplication($createApplicationMutation: CreateApplicationInput!) {
  createApplication(input: $createApplicationMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['UserApplicationsQuery']
  }
})
@observer
export default class ApplicationAdd extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Add application');
  }

  request = (values) => {
    const { store, router, mutate } = this.props;

    return mutate({
      variables: {
        createApplicationMutation: {
          ...values
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: __('%s was added', values.name), success: true };
      router.push('/applications');
    });
  }

  render() {
    return (
      <Controller
        id="ApplicationAdd"
        modal
        add
        title={__('Add application')}
        form={ApplicationForm}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
