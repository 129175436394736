import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import FormForm from './Form';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`mutation createForm($createFormMutation: CreateFormInput!) {
  createForm(input: $createFormMutation) {
    form {
      id: dbId
    }
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['FormResultFormQuery']
  }
})
@observer
export default class FormAdd extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Add form');
  }

  request = (values) => {
    const { store, router } = this.props;
    return this.props.mutate({
      variables: {
        createFormMutation: { organizationId: store.currentOrganization.id, ...values }
      }
    }).then((data) => {
      const { form } = data.data.createForm;
      router.push(`/organizations/${store.currentOrganization.id}/forms/${form.id}`);
    });
  }

  render() {
    return (
      <Controller
        id="FormAdd"
        modal={{ size: 'small' }}
        add
        title={__('Add form')}
        form={FormForm}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
