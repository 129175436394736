import React from 'react';
import { Divider, Accordion, Icon, Segment, Popup } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { uniq } from 'lodash';
import { toJS } from 'mobx';

import Form from '../../components/ui/Form';
import Calendar from '../../components/Calendar';
import BetaButton from '../../components/ui/Button';
import BetaModal from '../../components/ui/Modal';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const date = new Date();

const styles = {
  block: {
    boxShadow: '-1px 0px 0px rgba(0, 0, 0, 0.1)',
    height: '40px',
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center'
  }
};

export const checkSurveyNameModal = (surveyName, handleSubmit, onClose, { store, values, surveys }) => {
  if (values) {
    handleSubmit()
    return
  }

  const surveyNameAlreadyExists = surveys?.find(survey => survey.name === surveyName);

  if (!surveyNameAlreadyExists) {
    handleSubmit()
    return
  }

  return store.appends.push(
    <BetaModal
      id="CheckSurveyNameModal"
      onClose={onClose}
      actions={[
        <BetaButton
          data-action="no"
          transparent round
          text={__('No')}
          style={{
            marginRight: '6px',
            padding: '12px 33px'
          }}
          onClick={() => {
            onClose()
          }}
        />,
        <BetaButton
          data-action="yes"
          round
          text={__('Yes')}
          style={{
            padding: '12px 33px'
          }}
          onClick={() => {
            onClose()
            handleSubmit()
          }}
        />
      ]}
      header={__('There is already a poll with the same title')}
      content={(
        <div>
          <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>
            {__('This message already contains a poll with this name. Do you still want to create it?')}
          </span>
        </div>
      )}
    />
  );
}

@inject('store') @observer
export default class SurveyForm extends Form {
  defaultValues = {
    name: '',
    optionNumber: 1,
    options: [''],
    dateLimit: new Date(date.getTime() + (30 * 86400000)),
    time: null,
    previousId: null
  }

  rules = {
    name: ['required', values => this.validateContent('name', values)],
    options: [values => this.validateContent('options', values)]
  }

  validateContent = (name, values) => {
    if (name === 'name' && values.trim().length <= 0) throw new Error(__('Title cannot be empty'));

    if (name === 'name' && values.trim().length > 255) throw new Error(__('Title cannot be longer than 255 characters'));

    if (name === 'options' && (uniq(values.map(item => item && item.trim())).length !== values.length)) throw new Error(__('All fields must be different'));

    if (name === 'options' && !values.length > 0) throw new Error(__('Surveys must have at least one valid option'));

    if (name === 'options' && ((this.state.values.options.length !== this.state.values.options.filter(options => options && options.trim().length > 0).length) || !values.length > 0)) throw new Error(__('Options cannot be empty'));

    return values.length > 0;
  }

  beforeSubmit = (values) => {
    if (values.optionNumber > values.options.length) {
      values.optionNumber = values.options.length;
    }

    return values;
  }

  renderOptionNumber = () => {
    const options = Array(this.state.values.options.filter(option => (option && option.trim())).length).fill().map((option, i) => (
      { value: i + 1, text: i + 1 }
    ));

    if (options.length === 0) return Array({ value: 1, text: 1 });

    return options;
  }

  renderNoOptions = () => (
    <div style={styles.block}>
      <span style={{ marginLeft: '24px', color: 'rgba(0, 0, 0, 0.6)' }}>
        {__('No options selected')}
      </span>
    </div>
  );

  renderOptions = isEnabled =>
    this.state.values.options.map((option, i) =>
      <Form.Input
        key={i}
        placeholder={__('Option')}
        icon={this.props.edit ? null : {
          after: {
            name: 'times',
            link: true,
            'data-action': 'delete-option',
            'data-params': i,
            style: { fontSize: '16px' },
            onClick: () => {
              this.popValue('options', i);
              const { options, optionNumber } = this.state.values;
              if (option && option.trim() && options.filter(o => (o && o.trim())).length < optionNumber) {
                this.setValue('optionNumber', 1);
              }
            }
          }
        }}
        name="options"
        value={option}
        onChange={e => this.onItemChange(i, { name: 'options', value: e.target.value })}
        onKeyDown={(e) => {
          if ((isEnabled && !this.props.edit) && e.keyCode === 13) this.addOption(e);
        }}
        autoFocus={this.state.autofocus && this.state.values.options.length === i + 1}
        style={{ marginBottom: '12px' }}
      />
    )

  getTime = (where) => {
    const time = this.state.values.time;
    return (time) ? time.split(':')[where === 'hour' ? 0 : 1] : '';
  }

  setTime = (where = 'hour', value) => {
    const time = this.state.values.time || '00:00';
    const split = time.split(':');
    const hour = split[0];
    const minute = split[1];

    if (where === 'hour') {
      this.setValue('time', `${value}:${minute}`);
    } else if (where === 'minute') {
      this.setValue('time', `${hour}:${value}`);
    }
  }

  addOption = (e) => {
    const { values } = this.state;

    e.preventDefault();
    this.setState({ autofocus: true }, () => {
      if (values.options.length >= 39) {
        this.props.store.snackbar = {
          active: true,
          message: __('You reached the limit of 40 options per survey'),
          success: false
        };
      }
      if (this.state.values.options.find(option => option === '') === undefined) {
        this.pushValue('options', '');
      }
    });
  }

  isSubmitEnabled() {
    const { values } = this.state;
    return values.options.length <= 39
  }

  onError = (errors) => {
    if (errors.name && this.nameView) {
      return this.nameView.scrollIntoView({ behavior: 'smooth' });
    }
    if (errors.options && this.optionsView) {
      return this.optionsView.scrollIntoView({ behavior: 'smooth' });
    }
  }

  render() {
    const { values, errors, advanced } = this.state;
    const { i } = this.props;
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);
    const isEnabled = this.isSubmitEnabled();

    const actionButtonsLeft = [<BetaButton
      data-action="cancel"
      round
      transparent
      floated="left"
      text={__('Cancel')}
      onClick={() => this.props.store.appends.pop()}
    />];
    const actionButtonsRight = [<BetaButton
      data-action="submit"
      round
      icon={{ name: 'check' }}
      text={i === null ? __('Add') : __('Save')}
      onClick={(e) => checkSurveyNameModal(this.state.values.name, () => this.handleSubmit(e), () => this.props.store.appends.pop(), this.props)}
    />];

    return (
      <Form
        id="SurveyForm"
        onSubmit={this.handleSubmit}
        actionButtonsLeft={!this.isMobile() ? actionButtonsLeft : null}
        actionButtonsRight={!this.isMobile() ? actionButtonsRight : null}
        paddingHeader={this.isMobile()}
        header={this.isMobile() ? {
          title: '',
          onClose: () => this.props.store.appends.pop(),
          invertCloseButton: true,
          headerItem: <BetaButton
            data-action="submit"
            round
            text={i === null ? __('Add') : __('Save')}
            onClick={this.handleSubmit}
            style={{ height: '40px', width: '100px', justifyContent: 'center', display: 'flex' }}
          />
        } : null}
        style={this.isMobile() ? { paddingLeft: '24px', paddingRight: '24px' } : {}}
        {...this.props}
      >
        <div ref={(el) => { this.nameView = el; }}>
          <Form.Input
            label={__('Survey title')}
            labelStyle={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', marginBottom: '12px' }}
            placeholder={__('Type your survey\'s title...')}
            name="name"
            red={errors && errors.name && errors.name !== 'Error'}
            value={values.name}
            onChange={e => this.onTextInputChange(e, { name: 'name', value: e.target.value, maxDigits: 255 })}
            style={{ width: '100%' }}
            error={errors && errors.name && errors.name !== 'Error' ? __('Required field') : null}
            autoFocus
          />
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', marginLeft: '0px' }}
          ref={(el) => { this.optionsView = el; }}
        >
          <span style={{ fontWeight: 'bold', fontSize: '16px', margin: '16px 0 12px 0' }}>{__('Options')}</span>
          {
            values.options.length < 1 ?
              this.renderNoOptions() :
              this.renderOptions(isEnabled)
          }
        </div>
        <BetaButton
          type="submit"
          data-action="add-option"
          round transparent
          style={{ marginBottom: '16px' }}
          icon={{ name: 'plus' }}
          text={__('Add option')}
          onClick={e => this.addOption(e)}
          disabled={!isEnabled || this.props.edit}
        />
        {
          errors && errors.options &&
            <div className="error-warning-red" style={{ margin: '0 0 18px 0' }}>
              <Icon data-value="error" name="exclamation circle" />
              {errors.options}
            </div>
        }
        {
          this.isMobile() ?
            <Form.Group style={{ alignItems: 'flex-end', margin: '0 0 16px 0' }}>
              <div
                onClick={() => this.props.store.appends.push(<BetaModal
                  toast
                  id={'FormDateLimitToast'}
                  header={__('Deadline')}
                  closeOnRootNodeClick
                  invertCloseButton
                  scrolling
                  onClose={() => this.props.store.appends.pop()}
                  content={
                    <Calendar
                      withPadding
                      calendarStyles
                      selectedDt={values.dateLimit}
                      onSelect={(d) => { this.setValue('dateLimit', d.selectedDt); this.props.store.appends.pop(); }}
                      maxDate={values.date}
                    />
                  }
                />)}
                style={this.isMobile() ? { width: '100%' } : {}}
              >
                <Form.Input
                  label={__('Answer deadline')}
                  labelStyle={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', marginBottom: '12px' }}
                  readOnly
                  icon={{ after: { name: 'chevron down', style: { fontSize: '16px' } } }}
                  value={utils.simpleDate(values.dateLimit, true, 'll', lang)}
                />
              </div>
            </Form.Group>
            :
            <Form.Group style={{ alignItems: 'flex-start', flexDirection: 'column', margin: '0 0 16px 0' }}>
              <div style={{ marginBottom: '12px', display: 'flex' }}>
                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{__('Answer deadline')}</span>
              </div>
              <Popup
                trigger={(
                  <div>
                    <Form.Input
                      className="calendar-input"
                      value={utils.simpleDate(new Date(values.dateLimit), true, 'll', lang)}
                      icon={{ after: { name: 'chevron down', style: { fontSize: '16px' } } }}
                      readOnly
                    />
                  </div>
                )}
                content={<Calendar
                  calendarStyles
                  selectedDt={values.dateLimit}
                  onSelect={d => this.setValue('dateLimit', d.selectedDt)}
                  maxDate={values.date}
                />}
                position="bottom left"
                hideOnScroll
                on="click"
              />
            </Form.Group>
        }
        {
          values.options.length > 0 &&
            <Form.Dropdown
              label={__('How many options can be selected?')}
              name="optionNumber"
              className={'form-dropdown'}
              upward
              compact
              selection
              icon="angle down"
              value={values.optionNumber}
              options={this.renderOptionNumber()}
              onChange={this.onInputChange}
              style={{ width: '75px', marginBottom: '28px' }}
            />
        }
      </Form>
    );
  }
}
