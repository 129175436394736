import React from 'react';
import { render, fireEvent } from '../../../newRender';
import SignatureItem from '../Item';
import { __ } from '../../../i18n';

describe('<SignatureItem />', () => {
  const props = {
    store: {
      currentUser: {
        id: '1',
        documentNumber: '93616190045',
      },
      app: {
        locale: 'pt-BR'
      },
      appends: {
        push: jest.fn()
      }
    },
    signature: {
      id: '1',
      documentNumber: '93616190045',
      name: 'Test',
      status: 'PENDING',
      dateLimit: '2020-01-01T00:00:00.000Z',
      canSign: true,
    },
    userSignature: {
      id: '1',
      documentNumber: '123456789',
      signerFullname: 'John Doe',
      created: '2020-01-01T00:00:00.000Z',
    },
    state: {
      taxId: '93616190045'
    }
  };

  it('should item be defined', () => {
    const { container } = render(<SignatureItem {...props} />);
    expect(container).toBeDefined();
  });

  it('should be able to click at accept terms and sign button', () => {
    const { getByText } = render(<SignatureItem {...props} />);
    
    const acceptTerms = getByText(__('Accept terms and sign contract'));
    fireEvent.click(acceptTerms);
    expect(props.store.appends.push).toHaveBeenCalled();
  });
});
