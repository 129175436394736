import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Controller from '../../components/Controller';
import AccessForm from './Form';
import { __ } from '../../i18n';

@inject('store')
@graphql(gql`mutation createLink($createLinkMutation: createLinkInput!) {
    createLink(input: $createLinkMutation) {
    clientMutationId
  }
}`, {
  options: {
  refetchQueries: ['OrganizationAccessesQuery']
  }
  })
@observer
export default class AccessAdd extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Add access');
  }

  request = (values) => {
    const { store, params, router, mutate } = this.props;

    if (values.url) {
      values.url = (values.url.indexOf('http://') === -1) && (values.url.indexOf('https://') === -1) ? 'http://' + values.url : values.url;
    }

    return mutate({
      variables: {
        createLinkMutation: {
          organizationId: params.organization_id,
          type: 'URL',
          icon: 'globe',
          ...values
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: __('%s was added', values.title), success: true, dismissAfter: 3000 };
      router.push(`/organizations/${this.props.params.organization_id}/accesses`);
    });
  }

  render() {
    return (
      <Controller
        id="AccessAdd"
        modal
        add
        title={__('Add access')}
        form={AccessForm}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
