import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import RoleForm from './Form';
import { __ } from '../../i18n';

@inject('store', 'client')
@graphql(gql`mutation createRole($createRoleMutation: CreateRoleInput!) {
  createRole(input: $createRoleMutation) {
    clientMutationId
  }
}`)
@observer
export default class RoleAdd extends Controller {

  static fetchData({ store }) {
    store.app.title = __('Add people to group');
  }

  request = (values) => {
    const { store, router, params, location } = this.props;
    let data = [];

    values.entities.forEach((entityId) => {
      data = data.concat(values.groups.map(group => ({ groupId: group.id, entityId })));
    });

    const promises = data.map(d => this.props.mutate({
      variables: {
        createRoleMutation: {
          ...d
        }
      }
    }));

    return Promise.all(promises).then(async () => {
      await this.props.client.resetStore();

      store.snackbar = { active: true, message: __('Success'), success: true };
      router.push(`/organizations/${params.organization_id}/groups/${params.group_id}${location.search}`);
    });
  }

  render() {
    return (
      <Controller
        id="RoleAdd"
        modal
        add
        title={__('Add people')}
        form={RoleForm}
        values={{ groups: [parseInt(this.props.params.group_id, 10)] }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
