import React from 'react';
import { Loader, Button, Image } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router';
import { inject, observer } from 'mobx-react';

import * as utils from '../../utils';
import Modal from '../../components/Modal';
import { __ } from '../../i18n';
@inject('store')
@graphql(gql`query TermsInfo {
  viewer {
    id: dbId
    needTerms
    entities(limit: 500) {
      nodes {
        id: dbId
        type
      }
    }
    termNotification {
      id: dbId
      title
      description
      link
    }
  }
}
`, {
  options: ownProps => {
    return ({
      fetchPolicy: 'network-only'
    });
  }
}
)
@graphql(gql`mutation CreateAcceptedTerm($createAcceptedTermInput: CreateAcceptedTermInput!) {
  createAcceptedTerm(input: $createAcceptedTermInput) {
    clientMutationId
  }
}`, {
  options: ownProps => {
    return ({
      refetchQueries: ['TermsInfo'],
      fetchPolicy: 'no-cache'
    });
  }
})
@observer
export default class NewTerms extends React.Component {
  onSubmitTermAcceptance = (evt) => {
    evt.preventDefault();
    this.request();
  }

  request = () => {
    const { store } = this.props;

    this.props.mutate({
      variables: {
        createAcceptedTermInput: {}
      }
    }).then(async () => {
      store.snackbar = { success: true };
    }).catch((err) => {
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    });
  }

  renderTermsModal = () => {
    const { entities, termNotification } = this.props.data.viewer;
    const { title, description} = termNotification;
    const link = termNotification && utils.hasJsonStructure(termNotification.link) ? JSON.parse(termNotification.link) : null;
    const { nodes } = entities;
    const userTypes = [...new Set(nodes.map(obj => obj.type))];
    const params = (userTypes.length > 1) ? userTypes.join('+') : userTypes[0];

    const textStyle = {
      fontFamily: 'SF Pro Text',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '140%',
      textAlign: 'center',
      letterSpacing: '-0.02em',
      color: 'rgba(0, 0, 0, 0.6)',
      flex: 'none',
      order: '1',
      flexGrow: '0',
      margin: '16px 0px'
    };
    return (
      <Modal
        style={{
          maxWidth: '30em',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '16px 22px',
          background: '#FFFFFF',
          boxShadow: '0px 0px 26px rgba(0, 0, 0, 0.08)',
          borderRadius: '12px'
        }}
        id="newTermsModal"
        content={
          <div>
            <div>
              <Image
                id="termsImage"
                src={utils.asset('/terms/privacy_1.png')} centered
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                flexGrow: '0',
                margin: '16px 0px'
              }}
            >
              <h3 style={{ textStyle }}> {(title && title !== '') ? title : __('We updated the Terms of Use and Privacy Policy')} </h3>
              <p>
                {(description && description !== '') ? description
                  : __('Your data remains safe! By clicking “Continue“, you declare that you have read and agree with our')}
                <Link 
                  id="termsLink" 
                  target="_blank" 
                  to={`${ link ?link.link:'/terms'}?type=${params ? params.toLowerCase() : 'noentity'}`}>
                    { link ? link.text : __('Terms of Service and Privacy Policy')} 
                </Link>
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Button
                id="acceptTermsButton"
                style={{
                  backgroundColor: '#0E4FF5',
                  color: 'white',
                  borderRadius: '200px',
                  fontWeight: 'normal'
                }}
                data-action="submit"
                key={1}
                content={__('Continue')}
                onClick={e => this.onSubmitTermAcceptance(e)}
                disabled={this.props.loading}
                loading={this.props.loading}
              />
            </div>
          </div>
        }
      />
    );
  }
  render() {
    const { data } = this.props;
    if ((data.loading && !data.viewer) || !data.viewer) return <Loader active centered />;
    const showModal = data.viewer.needTerms;
    return (
      showModal ?
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.renderTermsModal()}
        </div>
        : null
    );
  }
}
