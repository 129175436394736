import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import ApplicationForm from '../Application/Form';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`query ApplicationEditQuery ($id: ID!) {
    node(id: $id) @connection(key: "Application", filter: ["id"]) {
      ... on Application {
        id: dbId
        type
        name
        description
        appUrl
        published
        author
        urlAuthor
        redirectUri
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.application_id
    }
  })
})
@graphql(gql`mutation updateApplication($updateApplicationMutation: UpdateApplicationInput!) {
  updateApplication(input: $updateApplicationMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['UserApplicationsQuery', 'ApplicationEditQuery']
  }
})
@observer
export default class ApplicationEdit extends Controller {
  request = values => this.props.mutate({
    variables: {
      updateApplicationMutation: {
        id: this.props.params.application_id,
        ...values
      }
    }
  }).then(() => {
    this.props.store.snackbar = { active: true, message: __('%s was saved', values.name), success: true };
    this.props.router.push('/applications');
  })

  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { type, name, description, appUrl, published, author, urlAuthor, redirectUri } = data.node;

    return (
      <Controller
        id="ApplicationEdit"
        modal
        edit
        title={__('Edit application')}
        form={ApplicationForm}
        values={{
          type,
          name,
          description,
          appUrl,
          published,
          author,
          urlAuthor,
          redirectUri
        }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
