import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Controller from '../../components/Controller';
import CardForm from './Form';
import { __ } from '../../i18n';
import * as utils from '../../utils';
import { tokenizeCreditCard } from '../../lib/payment';

const SERVICE = 'mundipagg';
const paymentService = {
  mundipagg: 'MUNDIPAGG',
};

@inject('store')
@graphql(gql`mutation createUserCard($createUserCardMutation: CreateUserCardInput!) {
  createUserCard(input: $createUserCardMutation) {
    userCard {
      dbId
      user {
        fullname
      }
    }
    clientMutationId
  }
}`, {
  options: {
  refetchQueries: ['PaymentFormQuery', 'MessageNodeEntityQuery', 'UserPaymentsQuery']
  }
  })
@observer
export default class CardAdd extends Controller {
  request = values => tokenizeCreditCard(values, SERVICE, this.props.store.app.env)
    .then(({ cardToken, cardHash }) => {
      const params = {};
      if (cardToken) params.cardToken = cardToken;
      if (cardHash) params.cardHash = cardHash;
      params.phone = values.phone;
      params.email = values.email;
      params.documentNumber = values.documentNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, (re, g1, g2, g3, g4) => `${g1}.${g2}.${g3}-${g4}`);
      params.cardholderName = values.name;
      params.last4Digits = parseInt(values.ccNumber.substr(values.ccNumber.length - 4), 10);
      params.expiration = values.expiration;
      params.brand = values.brand;
      params.service = paymentService[SERVICE];
      return this.props.mutate({
        variables: {
          createUserCardMutation: {
            ...params
          }
        }
      }).then(() => {
        this.props.store.snackbar = { active: true, message: __('Credit card added'), success: true };
        this.props.store.appends.pop();
      });
    })
    .catch((errors) => {
      console.log('catch error', errors);
      if (errors.graphQLErrors) {
        this.setState({ loading: false });
        this.props.store.snackbar = { active: true, message: utils.handleError(errors.graphQLErrors[0]), success: false };
      } else {
        this.props.store.snackbar = { active: true, message: __('An error occurred. Verify the data and try again'), success: false };
        this.setState({ loading: false });
      }
    })

  render() {
    return (
      <Controller
        {...this.props}
        id="CardAdd"
        modal={{ size: 'mini', closeIcon: 'times close inside', closeOnRootNodeClick: false }}
        add
        title={__('New Card')}
        form={CardForm}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        onCancel={() => this.props.store.appends.pop()}
        values={{}}
        onClose={() => this.props.store.appends.pop()}
      />
    );
  }
}
