import React from 'react';
import { Icon, Loader } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import cookie from 'react-cookie';

import { __ } from '../../i18n';
import ConciergeForm from './Form';
import BetaModal from '../../components/ui/Modal';
import Input from '../../components/ui/Input';
import ColoredCheckbox from '../../components/ColoredCheckbox';
import Button from '../../components/ui/Button';
import Responsive from '../../components/Responsive';

@inject('store')
export default class ConciergeAddModal extends Responsive {
  render() {
    const { concierge, store, edit } = this.props;
    const organization = store && store.currentOrganization;

    return (
      <BetaModal
        id="ConciergeAdd"
        style={{ margin: '0 0 0 -275px', padding: this.isMobile() ? '0 1.5rem' : null }}
        className={this.isMobile() ? null : 'fix-modal-position'}
        header={edit ? __('Edit gate') : __('Add gate')}
        fullScreen={this.isMobile()}
        fixHeader={this.isMobile()}
        fixActions={this.isMobile()}
        onClose={() => this.props.store.appends.pop()}
        content={
          <ConciergeForm
            edit={edit}
            concierge={concierge}
            onSubmit={this.props.onSubmit}
            tags={organization && organization.tags}
          />
        }
        size="tiny"
      />
    );
  }
}
