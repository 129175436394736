import React from 'react';
import { Icon } from 'semantic-ui-react';
import { __ } from '../../i18n';
import ConciergeModal from './Modal';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import BetaModal from '../../components/ui/Modal';
import ConciergeItem from './Item';
import Responsive from '../../components/Responsive';

import { inject, observer } from 'mobx-react';

const styles = {
  block: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
    height: '2.5rem',
    margin: '2rem 0',
    display: 'flex',
    alignItems: 'center'
  }
};

@inject('store')
@observer
export default class ConciergeManager extends Responsive {
  onConciergeSubmit = (newConciergeData, i) => {
    const concierges = [...this.props.concierges];
    if (i === undefined) {
      concierges.unshift(newConciergeData);
    } else {
      concierges[i] = newConciergeData;
    }

    this.props.onChangeValues(concierges);
  }

  onDeleteConcierge = (id) => {
    const concierges = [...this.props.concierges];
    const conciergeToDelete = concierges.findIndex(item => item.id === id);

    concierges.splice(conciergeToDelete, 1);

    this.props.onDelete(concierges);
  }

  openDeleteModal = ({ name, id }) => {
    this.props.store.appends.push(<BetaModal
      id="DeleteConciergeModal"
      fullScreen={this.isMobile()}
      fixHeader={this.isMobile()}
      fixActions={this.isMobile()}
      onClickTimesClose={() => { this.props.store.appends.pop(); }}
      onClose={() => this.props.store.appends.pop()}
      closeOnRootNodeClick
      header={__('Delete gate?')}
      content={<span style={this.isMobile() ? { display: 'flex', margin: '0 1rem' } : null}>{__('Are you sure you want to delete "%s"? This action cannot be undone', name)}</span>}
      actions={[
        <Button
          data-action="cancel"
          transparent round
          text={__('No')}
          style={{ width: '94px', height: '40px' }}
          onClick={() => {
            this.props.store.appends.pop();
          }}
        />,
        <Button
          data-action="delete"
          round
          text={__('Yes')}
          style={{ width: '94px', height: '40px' }}
          onClick={() => {
            this.onDeleteConcierge(id);
            this.props.store.appends.pop();
          }}
          primary
        />
      ]}
    />);
  }

  renderConcierges = items =>
    <div style={{ display: 'flex', flexDirection: 'column', margin: '1rem 0' }}>
      {items.map((item, i) => (
        <ConciergeItem
          concierge={item}
          disabled={this.props.disabled}
          onDelete={() => { this.openDeleteModal({ name: item.name, id: item.id }); }}
          onEdit={() => {
            this.props.store.appends.push(
              <ConciergeModal
                concierge={item}
                onSubmit={concierge => this.onConciergeSubmit(concierge, i)}
                edit
              />
            );
          }}
          key={i}
        />
      ))}
    </div>;

  renderNoConcierges = () => (
    <div style={styles.block}>
      <span style={{ marginLeft: '24px', color: 'rgba(0, 0, 0, 0.6)' }}>
        {__('No gate added')}
      </span>
    </div>
  );

  render() {
    const { concierges, error, disabled } = this.props;

    return (
      <div id="ConciergeManager">
        <div>
          {concierges.length > 0 ? this.renderConcierges(concierges) : this.renderNoConcierges()}
          <Button
            transparent
            round
            text={__('Add gate')}
            icon={{ name: 'map marker alt', style: error ? { color: '#BF2600' } : null }}
            style={error ? { border: '1px solid #BF2600', color: '#BF2600', marginBottom: '24px' } : { marginBottom: '24px' }}
            disabled={disabled}
            // eslint-disable-next-line react/jsx-curly-spacing
            onClick={() => {
              this.props.store.appends.push(
                <ConciergeModal onSubmit={this.onConciergeSubmit} />
              );
            }}
          />
        </div>
        {error && (
          <div className={error.className ? 'error-warning ' + error.className : 'error-warning'} style={error && error.style}>
            <Icon className="circle exclamation" />
            <span>{typeof error !== 'string' && error.text ? error.text : error}</span>
          </div>
        )}
      </div>
    );
  }
}
