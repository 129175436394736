import React from 'react';
import {
  Accordion, Container, Segment, Icon
} from 'semantic-ui-react';
import Form from '../../components/Form';
import { __ } from '../../i18n';
import { validateContent } from './helpers/validateContent';

export default class AccessForm extends Form {
  defaultValues = {
    title: '',
    url: ''
  }

  rules = {
    title: ['required', (values) => validateContent('title', values)],
    url: ['required', (values) => validateContent('url', values)]
  };


  render() {
    const { values, errors } = this.state;

    return (
      <Form id="AccessForm" {...this.props} onSubmit={this.handleSubmit}>
        <Form.Input
          label={__('Title')}
          placeholder={__('Link name')}
          name="title"
          value={values.title}
          onChange={this.onInputChange}
          autoFocus
        />
        {
          errors && errors.title
            ? (
              <div style={{ marginBottom: 14 }}>
                <Icon data-value="error" name="exclamation circle" size="large" color="red" />
                {errors.title}
              </div>
            )
            : null
        }
        <Form.Input
          label={__('URL')}
          placeholder={__('ex: https://classapp.com.br/')}
          name="url"
          value={values.url}
          onChange={this.onInputChange}
        />
        {
          errors && errors.url
            ? (
              <div style={{ marginBottom: 14 }}>
                <Icon data-value="error" name="exclamation circle" size="large" color="red" />
                {errors.url}
              </div>
            )
            : null
        }
      </Form>
    );
  }
}
