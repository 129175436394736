import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import PaymentAdd from '../Payment/Add';
import { __ } from '../../i18n';

/** @type {{[key: string]: React.CSSProperties}} */
const styles = {
  button: {
    backgroundColor: 'white',
    color: '#212529',
    padding: '15px 0px',
    fontWeight: 'bold',
    borderRadius: 24,
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 24,
    marginTop: 12,
  },
  highlightText: {
    fontSize: 12,
    color: '#0069FF',
    paddingRight: 5,
    paddingLeft: 5,
  }
};

/**
 * @typedef {{
 *  method: 'CARD' | 'BOLETO' | 'PIX';
 *  charge: {
 *    id: number;
 *    payableExpired: boolean;
 *    expired: boolean
 *    paymentCardAmount: number;
 *    paymentBoletoAmount: number;
 *  };
 *  highlight?: 'recommended' | 'cheapest';
 *  hideValues: boolean;
 *  disabled: boolean;
 * }} Props
 * @extends {Component<Props>}
 */
@inject('store')
@observer
export default class PaymentMethodItem extends Component {
  render() {
    const { method, highlight, hideValues, disabled, messageToApprove } = this.props;
    const { id, payableExpired, expired, paymentCardAmount, paymentBoletoAmount, paymentPixAmount } = this.props.charge;

    const paymentAmount = (method === 'CARD' && paymentCardAmount) || (method === 'BOLETO' && paymentBoletoAmount) || (method === 'PIX' && paymentPixAmount);

    return (
      <div style={{ ...styles.container, borderColor: highlight ? '#0082FF' : '#e9ecef' }}>
        {!!highlight &&
          <div style={{ position: 'absolute', top: '-25%', left: 50, backgroundColor: '#FFF' }}>
            <span style={styles.highlightText}>{highlight === 'recommended' ? __('Recommended') : __('Lower cost')}</span>
          </div>
        }
        <Button
          fluid
          style={{...styles.button, cursor: messageToApprove ? 'unset' : 'pointer'}}
          data-action={`pay-charge-${method === 'CARD' ? 'credit-card' : method.toLowerCase()}`}
          data-value={id}
          disabled={(payableExpired ? false : expired) || disabled}
          onClick={() => {
            if(!messageToApprove) {
              this.props.store.appends.push(
                <PaymentAdd
                charge={this.props.charge}
                creditCard={method === 'CARD'}
                boleto={method === 'BOLETO'}
                pix={method === 'PIX'}
                />
                )}
            }
          }
        >
          <span>
            {method === 'CARD' ? __('Credit Card') : method === 'BOLETO' ? __('Bank Boleto') : __('Pix')}
          </span>
          {!hideValues &&
            <span style={{ color: '#0080ff', marginLeft: '15px' }}>
              {paymentAmount && `${paymentAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
            </span>
          }
        </Button>
      </div>
    );
  }
}
