import React from 'react';

import Responsive from '../../components/Responsive';

import { __ } from '../../i18n';

const styles = {
  mobile: {
    padding: '1.5em',
    marginTop: '60px'
  },
  desktop: {
    padding: '2em 15em',
    marginTop: '60px'
  }
};
export default class ClassAppAbout extends Responsive {
  render() {
    return (
      <div style={this.isMobile() ? styles.mobile : styles.desktop}>
        <h1 style={{ marginBottom: '30px' }}>{__('About Us')}</h1>
        <p>Fundada em 2014, a ClassApp é uma empresa brasileira que atende mais de 600 das maiores instituições de ensino em todo o Brasil, engajando mais de 420 mil pais e alunos na rotina escolar.</p>
        <div>
          <h2 style={{ marginTop: '30px', marginBottom: '30px' }}>Conheça um pouco da nossa história</h2>
          <p>Desde que os aplicativos foram inclusos na nossa rotina, eles têm facilitado o dia a dia das pessoas, principalmente porque permitem uma facilidade na comunicação. Percebendo isso, dois pais tiveram a ideia criar um aplicativo para que a comunicação com a escola fosse mais próxima e simplificada, pois tinham dificuldades no relacionamento com a escola dos seus filhos.</p>
          <p>Eles perceberam que o método de comunicação mais tradicional usado pela escola, a agenda, não era uma estratégia muito eficiente para intermediar a comunicação entre eles e o colégio. Uma agenda escolar típica não combinava com uma família do século 21, que trabalha e vive na correria.</p>
          <p>Ambos engenheiros da USP e da Unicamp, decidiram unir seus conhecimentos técnicos com a vontade de empreender e lançaram o ClassApp, um aplicativo que conecta as Instituições com pais e alunos de forma inteligente, simples e eficiente. Com ele, as escolas passam a ter o seu próprio sistema de comunicação privado, seguro e completamente gerenciável.</p>
          <h2 style={{ marginTop: '30px', marginBottom: '30px' }}>Missão</h2>
          <p>Oferecer solução de comunicação simples e inteligente que aprimore a integração e o relacionamento em organizações.</p>
          <h2 style={{ marginTop: '30px', marginBottom: '30px' }}>Visão</h2>
          <p>Ser referência mundial em comunicação confiável fomentando relacionamentos inteligentes.</p>
          <h2 style={{ marginTop: '30px', marginBottom: '30px' }}>Valores</h2>
          <ul style={{ marginLeft: '21px' }}>
            <li>Excelência</li>
            <li>Otimismo</li>
            <li>Comprometimento</li>
            <li>Confiança</li>
            <li>Criatividade</li>
            <li>Unidade</li>
            <li>Liderança</li>
            <li>Veracidade</li>
            <li>Integridade</li>
          </ul>
        </div>
      </div>
    );
  }
}
