import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import BetaModal from '../../components/ui/Modal';

import { __ } from '../../i18n';
import Button from '../../components/ui/Button';
import Responsive from '../../components/Responsive';
import { parseFormattedTranslation } from '../../utils';
import * as Analytics from '../../lib/analytics';

export default class ConfirmationModal extends Responsive {
  render() {
    const { onConfirm, onClose, userName, documentNumber, store } = this.props;

    return (
      <BetaModal
        id="ConfirmSignatureModal"
        style={{ minWidth: this.isMobile() ? '' : '680px', fontSize: this.isMobile() ? '16px' : undefined }}
        header={__('Do you want to sign this contract?')}
        content={parseFormattedTranslation({
          text: __('You are signing this contract with the name **%s** and the document number **%s**. This action cannot be undone.', userName, documentNumber),
          props: {
            style: {
              fontWeight: 'bold'
            }
          }
        })}
        onClose={this.isMobile() ? onClose : null}
        spaceFooter
        actions={
          [
            (
              !this.isMobile()
              &&
              <Button
                round
                transparent
                text={__('Back')}
                onClick={onClose}
              />
            )
            ,
            <div style={this.isMobile() ? { display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' } : {}}>
              <Button
                round
                transparent
                text={__('Review signature details')}
                onClick={() => {
                  browserHistory.push('/user/account')
                  onClose()
                  Analytics.sendGoogleAnalyticsEvent({
                    name: 'review_signature_details',
                    category: 'review signature data clicked by user',
                  }, {store})
                }}
                style={
                  Object.assign(
                    { borderColor: '#084FFF', color: '#084FFF', marginRight: '5px' },
                    this.isMobile() ? { flex: 1 } : {}
                  )
                }
              />
              <Button
                round
                style={this.isMobile() ? { flex: 1 } : {}}
                text={__('Yes, I want to sign')}
                onClick={onConfirm}
              />
            </div>
          ]}
      />
    );
  }
}
