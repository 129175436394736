import * as utils from '../../../utils';
import { __ } from '../../../i18n';

export function validateContent(name, values) {
  if (name === 'title' && values.trim().length <= 0)
    throw new Error(__('Title cannot be empty'));
  if (name === 'title' && values.trim().length > 30)
    throw new Error(__('Maximum length of the title is 30 characters'));
  if (name === 'url' && values.trim().length <= 0)
    throw new Error(__('URL is not defined'));
  if (name === 'url' && !utils.isUrl(values))
    throw new Error(__('URL is invalid'));
}
