import { inject, observer } from 'mobx-react';
import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import FormForm from './Form';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`mutation updateForm($updateFormMutation: UpdateFormInput!) {
  updateForm(input: $updateFormMutation) {
    form {
      dbId
    }
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['FormEditQuery', 'FormResultFormQuery', 'FormNodeQuery']
  }
})
@graphql(gql`query FormEditQuery($formId: ID!) {
  node (id: $formId) {
    ... on Form {
      id: dbId
      name
      description
      template
    }
  }
}`, {
  options: ownProps => ({
    variables: {
      formId: ownProps.params.form_id
    }
  })
})
@observer
export default class FormEdit extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Edit form');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.values && nextProps.data.node) {
      const values = { ...nextProps.data.node };

      delete values.__typename;

      this.setState({ values });
    }
  }

  request = (values) => {
    const { store, router } = this.props;

    return this.props.mutate({
      variables: {
        updateFormMutation: {
          ...values
        }
      }
    })
      .then(() => {
        store.snackbar = { active: true, message: __('%s was saved', values.name), success: true };
        router.goBack();
      });
  }

  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    return (
      <Controller
        id="FormEdit"
        modal
        edit
        title={__('Edit form')}
        form={FormForm}
        values={this.state.values}
        loading={this.props.data.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
