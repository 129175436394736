/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Card, Button, Icon, Container, Message, Dropdown } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';

import Modal from '../../components/Modal';
import Responsive from '../../components/Responsive';

import CommitmentReminds from '../Commitment/Reminds';
import CommitmentEdit from '../Commitment/Edit';
import CommitmentDelete from '../Commitment/Delete';

import * as utils from '../../utils';
import { __ } from '../../i18n';

const styles = {
  bar: {
    height: '48px',
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    margin: '8px 0px'
  }
};

const now = new Date();

@inject('store')
@graphql(gql`mutation createCommitmentRemind($createCommitmentRemindMutation: CreateCommitmentRemindInput!) {
  createCommitmentRemind(input: $createCommitmentRemindMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['MessageNodeEntityQuery']
  }
})
@observer
export default class CommitmentRemindForm extends Responsive {
  static fragments = {
    // if you need the id add to the component using the fragment
    // querying here can cause conflicts cause we need dbId in some components and key in others
    commitment: gql`
      fragment CommitmentRemindForm on Commitment {
        dbId
        date
        time
        name
        noConfirm
        expired
        dateLimit
        deleted
        videocallType        
        videocallLink
        summary {
          count
        }
        reminds {
          nodes {
            date
            entityId
          }
        }
        ...CommitmentReminds
      }
      ${CommitmentReminds.fragments.commitment}
      `,
  }

  commitmentRemindModal = () => {
    this.props.store.appends.push(
      <Modal
        id="CommitmentRemindForm"
        onClose={() => this.props.store.appends.pop()}
        header={__('Commitment confirm')}
        content={<Message warning>{__('Once confirmed, it can’t be undone.')}</Message>}
        actions={[
          <Button
            data-action="cancel"
            key={0}
            basic
            floated="left"
            content={__('Cancel')}
            onClick={() => this.props.store.appends.pop()}
          />,
          <Button
            data-action="confirm"
            key={1}
            primary
            content={__('Confirm')}
            onClick={() => this.submitCommitmentRemind()}
          />
        ]}
      />
    );
  }

  async submitCommitmentRemind() {
    this.props.store.appends.pop();
    await this.props.mutate({
      variables: {
        createCommitmentRemindMutation: {
          entityId: this.props.params.entity_id,
          commitmentId: this.props.commitment?.dbId
        }
      }
    });
    if (this.props.onSubmit) this.props.onSubmit();
  }

  renderLimitDate = (commitment) => {
    const dateString = new Date(commitment?.dateLimit?.replace(/-/g, '/'));
    const lang = this.props.store.app.locale && this.props.store.app.locale?.substring(0, 2);

    return utils.simpleDate(dateString, true, 'LL, HH:mm', lang);
  }

  render() {
    const { commitment, params, messageType, entity, fromHistory } = this.props;
    const { reminds, time, name, noConfirm, summary, dbId, expired, deleted, videocallType, videocallLink } = commitment;
    const isConfirmed = reminds?.nodes?.length > 0;
    let { store } = this.props;
    let date = null;
    let formattedDate = null;

    if (commitment?.date) {
      const then = moment(commitment?.date?.substring(0, 10));
      const d = then.format('dddd, LL'); //splitting for translation purposes
      date = (<div style={{ ...styles.bar, backgroundColor: '#FFFFFF', color: '#0080ff' }}>
        {d}{time && ', ' + time?.substring(0, 5)}
      </div>);

      const splitDate = commitment?.date.substring(0, 10).split('-').map(Number);
      const momentDate = moment([splitDate[0], splitDate[1] - 1, splitDate[2]]);

      formattedDate = new Date(momentDate.year(), momentDate.month(), momentDate.date());


      if (commitment?.time) {
        formattedDate.setHours(parseInt(commitment?.time?.split(':')[0], 10));
        formattedDate.setMinutes(parseInt(commitment?.time?.split(':')[1], 10));
      } else {
        formattedDate.setHours(23);
        formattedDate.setMinutes(59);
        formattedDate.setSeconds(59);
      }
    }

    const exportLink = `${store.app.url}/csv/commitment/reminds?` +
      `limit=1000&commitment_id=${dbId}&access_token=${encodeURIComponent(store.access_token)}&tz_offset=${-(new Date().getTimezoneOffset())}`;

    let items = [];

    if (!noConfirm && (messageType === 'SENT' || params.organization_id)) {
      items = items.concat([<Dropdown.Item
        onClick={() => this.props.store.appends.push(<CommitmentReminds
          commitment={filter(CommitmentReminds.fragments.commitment, commitment)}
        />)}
      >
        {__('See details')}
      </Dropdown.Item>,
      (
        <Dropdown.Item as="a" href={exportLink}>
          {__('Export')}
        </Dropdown.Item>
      )
      ]);
    }

    if (messageType === 'SENT' && (!commitment?.date || (formattedDate - now) > 0) && !deleted && (store.currentEntity.type === 'ADMIN' || !params.organization_id)) {
      items = items.concat([<Dropdown.Item
        onClick={() => this.props.store.appends.push(<CommitmentEdit commitment={commitment} />)}
      >
        {__('Edit')}
      </Dropdown.Item>,
      (
        <Dropdown.Item
          onClick={() => this.props.store.appends.push(<CommitmentDelete commitment={commitment} />)}
        >
          {__('Delete')}
        </Dropdown.Item>
      )
      ]);
    }

    return (
      <Card fluid style={{ marginBottom: '1em' }}>
        <Card.Content>
          <Container textAlign="center">
            <div className="horizontallySpacedItems">
              <div className="bold"><Icon name="calendar" style={{ color: '#0080ff' }} />{name}</div>
              {
                (items.length && !fromHistory) ?
                  <Dropdown icon={null} trigger={<Icon name="ellipsis v" />}>
                    <Dropdown.Menu className="bold" style={this.isMobile() ? { left: 'unset', right: 0 } : {}}>
                      {items}
                    </Dropdown.Menu>
                  </Dropdown>
                  :
                  null
              }
            </div>
            <div id="CommitmentDate" style={{ padding: '8px 0px' }}>
              {date}
              {
                noConfirm ?
                  null
                  :
                  (
                    (messageType === 'SENT' || params.organization_id) ?
                      <div
                        style={{ ...styles.bar, border: '1px solid #eee', cursor: !fromHistory && 'pointer' }}
                        data-action="open-commitment-reminds"
                        onClick={fromHistory ? null : () => this.props.store.appends.push(<CommitmentReminds
                          commitment={filter(CommitmentReminds.fragments.commitment, commitment)}
                        />)}
                      >
                        <Icon name="check" style={{ color: '#a0a0a0', fontWeight: 'normal' }} />
                        {summary && summary.count === 1 ? __('%s confirmation', (summary && summary.count) ? summary.count : 0) : __('%s confirmations', (summary && summary.count) ? summary.count : 0)}
                      </div>
                      :
                      (
                        !entity.disabled && !deleted &&
                        (
                          isConfirmed ?
                            <Button icon="check" fluid style={{ ...styles.bar, backgroundColor: '#0080ff', color: '#fff' }} />
                            :
                            <Button
                              fluid
                              className="buttonHover pointer"
                              style={{ ...styles.bar, backgroundColor: '#fff', color: '#0080ff', border: '1px solid #eee' }}
                              data-action="confirm-commitment"
                              data-value={dbId}
                              disabled={expired || fromHistory}
                              content={__('Confirm')}
                              onClick={() => this.commitmentRemindModal()}
                            />
                        )
                      )
                  )
              }
            </div>
            {/* meet */}
            {
              videocallLink && !deleted ? (
                <div>
                  <a
                    href={videocallLink}
                    target='_blank'
                    style={{ ...styles.bar, border: '1px solid #eee', cursor: !fromHistory && 'pointer', color: '#0069FF' }}
                  >{__('Join video conference')}</a>
                  <div style={{ opacity: '0.7', marginTop: '5px' }}>
                    {videocallLink}
                  </div>
                </div>
              )
                : null
            }
            {/* meet - end */}
            {
              deleted ?
                <p style={{ color: '#f04040' }}>
                  <Icon name="times" />
                  {__('Commitment Deleted')}
                </p>
                :
                commitment?.dateLimit &&
                <p style={{ color: expired ? '#a0a0a0' : '#ffb43c' }}>
                  <Icon name="exclamation circle" />
                  {
                    expired ?
                      __('Expired %s', this.renderLimitDate(commitment))
                      :
                      __('Expires %s', this.renderLimitDate(commitment))
                  }
                </p>
            }
          </Container>
        </Card.Content>
      </Card>
    );
  }
}
