import { typeOptions } from '../getTypeOptions';

describe('getTypeOptions', () => {
  it('should return correct options', () => {
    expect(typeOptions()).toEqual([
      {
        text: 'Web',
        value: 'WEB',
      },
      {
        text: 'iOS',
        value: 'iOS',
      },
      {
        text: 'Android',
        value: 'ANDROID',
      },
      {
        text: 'Windows Phone',
        value: 'WINDOWS_PHONE',
      },
    ]);
  });
});
