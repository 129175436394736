import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import { __ } from '../../i18n';

@graphql(gql`mutation deleteReportField($deleteReportFieldMutation: DeleteReportFieldInput!) {
  deleteReportField(input: $deleteReportFieldMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['ReportResultFormQuery', 'ReportNodeQuery']
  }
})
@inject('store') @observer
export default class ReportFieldDelete extends Controller {

  request = () => this.props.mutate({
    variables: {
      deleteReportFieldMutation: {
        id: this.props.params.field_id
      }
    }
  }).then(() => {
    this.props.store.snackbar = { active: true, message: __('Report field was deleted'), success: true };
    this.props.router.push(`/organizations/${this.props.params.organization_id}/reports/${this.props.params.report_id}`);
  })

  render() {
    return (
      <Controller
        id="ReportFieldDelete"
        delete
        object={__('report field')}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
