import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Loader, Menu, Breadcrumb, Segment, Icon, Dropdown, Button } from 'semantic-ui-react';

import Modal from '../../components/Modal';
import SortableList from '../../components/SortableList';
import FieldForm from '../../components/ui/FieldForm';
import BetaModal from '../../components/ui/Modal';
import Responsive from '../../components/Responsive';



import { __ } from '../../i18n';

@inject('store')
@graphql(gql`query FormNodeQuery ($id: ID!) {
  node (id: $id) {
    ... on Form {
      id: dbId
      name
      description
      template
      fields (limit: 400) {
        nodes {
          id: dbId
          name
          type
          initial
          options
          position
        }
      }
    }
  }
  }
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: ownProps.params.form_id
    }
  })
})
@graphql(gql`mutation updateForm($updateFormMutation: UpdateFormInput!) {
  updateForm(input: $updateFormMutation) {
    clientMutationId
  }
}`, {
  name: 'updateForm',
  options: {
    refetchQueries: ['FormResultFormQuery', 'FormNodeQuery']
  }
})
@graphql(gql`mutation createFormField($createFormFieldMutation: CreateFormFieldInput!) {
  createFormField(input: $createFormFieldMutation) {
    clientMutationId
  }
}`, {
  name: 'createFormField',
  options: {
    refetchQueries: ['FormResultFormQuery', 'FormNodeQuery', 'FormResultFillQuery']
  }
})
@graphql(gql`mutation updateFormField($updateFormFieldMutation: UpdateFormFieldInput!) {
  updateFormField(input: $updateFormFieldMutation) {
    clientMutationId
  }
}`, {
  name: 'updateFormField',
  options: {
    refetchQueries: ['FormResultFormQuery', 'FormNodeQuery', 'FormResultFillQuery']
  }
})
@observer
export default class FormNode extends Responsive {
  state = {
    sorted: null
  }

  onSort = data => this.setState({ sorted: data })

  onUpdateForm = () => {
    const { sorted: data } = this.state;
    const values = { ...this.props.data.node };

    values.fields = data.items.map(item => item.field);

    values.fields = values.fields.map((f, i) => {
      const field = { ...f };

      if (field.type === 'SELECT' && field.initial && field.initial.length && field.initial[0] !== null) {
        field.initial = field.initial[0].toString();
      } else if (field.type === 'CHECK' && field.initial && field.initial.length) {
        field.initial = field.initial.map(option => option.toString());
      } else if (field.type === 'CHECK') {
        field.initial = [];
      }

      field.position = i + 1;

      delete field.__typename;

      return { ...field };
    });

    delete values.__typename;

    this.props.updateForm({
      variables: {
        updateFormMutation: {
          ...values
        }
      }
    }).then(() => this.setState({ sorted: null }));
  }

  onFormFieldDelete = (id) => {
    const { router, params } = this.props;
    const { organization_id, form_id } = params;

    router.push(`/organizations/${organization_id}/forms/${form_id}/fields/${id}/delete`);
  }

  onFormFieldDuplicate = (field) => {
    const values = {};

    values.name = __('Copy of ') + field.name;
    values.type = field.type;
    values.initial = field.initial;
    values.options = field.options;

    if (field.type === 'SELECT' && field.initial && field.initial.length && field.initial[0] !== null) {
      values.initial = field.initial[0].toString();
    } else if (field.type === 'CHECK' && field.initial && field.initial.length) {
      values.initial = field.initial.map(option => option.toString());
    }

    this.props.createFormField({
      variables: {
        createFormFieldMutation: {
          formId: this.props.params.form_id,
          ...values
        }
      }
    });
  }

  onFormFieldSubmit = i => (value) => {
    this.props.store.appends.pop();

    if (value.type === 'SELECT' && value.initial && value.initial.length &&  value.initial[0] !== null) {
      value.initial = value.initial[0].toString();
    } else if (value.type === 'CHECK' && value.initial && value.initial.length) {
      value.initial = value.initial.map(option => option.toString());
    }

    if (i === null) {
      this.props.createFormField({
        variables: {
          createFormFieldMutation: {
            formId: this.props.params.form_id,
            ...value,
            initial: (typeof value.initial === 'number') ? value.initial.toString() : value.initial,
          }
        }
      });
    } else {
      this.props.updateFormField({
        variables: {
          updateFormFieldMutation: {
            ...value,
            initial: (typeof value.initial === 'number') ? value.initial.toString() : value.initial,
          }
        }
      });
    }
  }

  openFormFieldForm = (i = null) => {
    const field = i !== null ? { ...this.props.data.node.fields.nodes[i] } : null;
    const title = (i !== null) ? __('Edit field') : __('Add field');

    if (field) {
      if (field.type === 'SELECT' && field.initial && field.initial.length && field.initial[0] !== null) {
        field.initial = field.initial[0].toString();
      } else if (field.type === 'CHECK' && field.initial && field.initial.length) {
        field.initial = field.initial.map(option => option.toString());
      } else if (field.type === 'CHECK') {
        field.initial = [];
      } else if (field.type === 'TEXT') {
        field.options = [''];
      }

      delete field.__typename;
    }

    this.props.store.appends.push(
      <BetaModal
        id="FormFieldAdd"
        size="small"
        closeOnRootNodeClick={false}
        fullScreen={this.isMobile()}
        portalHeader={this.isMobile()}
        // fixHeader={this.isMobile()}
        actions={false}
        header={this.isMobile() ? null : title}
        content={<FieldForm
          modal
          values={field}
          onSubmit={this.onFormFieldSubmit(i)}
          submitButton={{
            text: i !== null ? __('Save') : __('Add'),
            isActionButtom: true
          }}
          cancelButton={{
            text: __('Cancel'),
            isActionButtom: true
          }}
          title={title}
        />}
      />
    );

  }

  renderField = (field, sortable, i) => <Segment
    key={i}
    className={'horizontallySpacedItems' + (sortable ? ' buttonHover pointer wordWrapped' : '')}
    style={{ alignItems: 'center', margin: '8px 0px', borderColor: '#eeeeee', minHeight: '62px', flexWrap: 'nowrap' }}
    onClick={() => (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_FORM')))) && this.openFormFieldForm(i)}
  >
    <div>
      {sortable && <Icon name="sort" style={{ color: '#a0a0a0', fontSize: '12px', cursor: 'move' }} />}
      <span className="bold">
        {field.name}
      </span>
    </div>
    {
      (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_FORM') || this.props.store.currentEntity.organization.permissions.entityScopes.includes('DELETE_FORM')))) &&
      (
        <Dropdown icon={null} trigger={<Icon name="ellipsis v" style={{ color: '#a0a0a0', fontSize: '16px' }} />}>
          <Dropdown.Menu style={this.isMobile() ? { left: 'unset', right: 0 } : {}}>
            {
              (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_FORM')))) &&
              (
                <Dropdown.Item onClick={() => this.onFormFieldDuplicate(field)}>
                  <Icon name="clone" />{__('Duplicate')}
                </Dropdown.Item>
              )
            }
            {
              (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('DELETE_FORM')))) &&
              (
                <Dropdown.Item onClick={() => this.onFormFieldDelete(field.id)}>
                  <Icon name="trash" />{__('Delete field')}
                </Dropdown.Item>
              )
            }
          </Dropdown.Menu>
        </Dropdown>
      )
    }
  </Segment>

  renderSortableFields = fields => <SortableList
    data={{
      items: fields.map((field, i) => ({ content: this.renderField(field, true, i), field }))
    }}
    onSort={this.onSort}
    style={{ marginTop: '20px' }}
  />

  renderFields = (fields, sortable) => (sortable ? this.renderSortableFields(fields) :
    (
      <div style={{ marginTop: '20px' }}>
        {fields.map((field, i) => <div key={i} outline="list">{this.renderField(field, false, i)}</div>)}
      </div>
    ))

  render() {
    const { data, params, store } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;

    const { name, fields: { nodes } } = data.node;

    const permissions = store.currentEntity && store.currentEntity.organization && store.currentEntity.organization.permissions;
    const type = store.currentEntity && store.currentEntity.type;
    const canEditOrDeleteForm = (type === 'ADMIN' || (permissions && (permissions.hasAdmin || permissions.entityScopes.includes('CREATE_UPDATE_FORM') || permissions.entityScopes.includes('DELETE_FORM'))));
    const canEditForm = (type === 'ADMIN' || (permissions && (permissions.hasAdmin || permissions.entityScopes.includes('CREATE_UPDATE_FORM'))));
    const canDeleteForm = (type === 'ADMIN' || (permissions && (permissions.hasAdmin || permissions.entityScopes.includes('DELETE_FORM'))));

    return (
      <div id="FormNode">
        {
          this.state.sorted ?
            <Menu compact floated="right" style={{ marginTop: '-1em' }}>
              <Menu.Item
                className="bold"
                data-action="update"
                content={__('Save Changes')}
                onClick={() => this.onUpdateForm()}
              />
            </Menu>
            :
            [
              canEditOrDeleteForm &&
              (
                <Menu compact floated="right" style={{ marginTop: '-1em' }}>
                  <Menu.Menu position="right" className="bold">
                    <Dropdown
                      item
                      icon={null}
                      trigger={<Icon name="ellipsis h" style={{ margin: 0 }} />}
                    >
                      <Dropdown.Menu style={{ marginTop: 10 }}>
                        {
                          canEditForm &&
                          (
                            <Dropdown.Item as={Link} to={`/organizations/${params.organization_id}/forms/${params.form_id}/edit`}>
                              <Icon name="pencil" />{__('Edit form')}
                            </Dropdown.Item>
                          )
                        }
                        {
                          canDeleteForm &&
                          (
                            <Dropdown.Item as={Link} to={`/organizations/${params.organization_id}/forms/${params.form_id}/delete`}>
                              <Icon name="trash" />{__('Delete form')}
                            </Dropdown.Item>
                          )
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu.Menu>
                </Menu>
              ),
              (type === 'ADMIN' || (permissions && (permissions.hasAdmin || permissions.entityScopes.includes('CREATE_UPDATE_FORM')))) &&
              <Menu compact floated="right" style={{ marginTop: '-1em' }}>
                <Menu.Item
                  className="bold"
                  data-action="add-field"
                  content={__('New Field')}
                  icon="plus"
                  onClick={() => this.openFormFieldForm()}
                />
              </Menu>
            ]
        }
        <Breadcrumb>
          <Breadcrumb.Section
            link
            className="bold defaultClspColor"
            as={Link}
            to={`/organizations/${params.organization_id}/forms`}
          >
            {__('Forms')}
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>{(name.length > 55) ? (name.substring(0, 55) + '...') : name}</Breadcrumb.Section>
        </Breadcrumb>
        {
          data.loading ?
            <Loader active />
            :
            nodes.length ?
              this.renderFields(nodes, type === 'ADMIN' || (permissions && (permissions.hasAdmin || permissions.entityScopes.includes('CREATE_UPDATE_FORM'))))
              :
              <Segment textAlign="center" style={{ margin: '28px 0px 8px 0px' }}>
                {__('This form does not have fields yet. Click "New Field" to create the first field.')}
              </Segment>
        }
        {this.props.children}
      </div>
    );
  }
}
