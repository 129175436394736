import React from 'react';
import { inject, observer } from 'mobx-react';
import { Divider, Icon } from 'semantic-ui-react';

import Form from '../../components/Form';
import PhoneInput from '../../components/PhoneInput';
import ColoredCheckbox from '../../components/ColoredCheckbox';

import { __ } from '../../i18n';

@inject('store') @observer
export default class AddressForm extends Form {
  defaultValues = {
    address: '',
    type: 'EMAIL',
    tags: [],
    invite: false
  }

  rules = {
    address: 'required',
    tags: [values => this.validateContent(values)]
  }

  maybes = {
    address: [
      [
        'email',
        values => values.type === 'EMAIL'
      ], [
        'mobile',
        values => values.type === 'PHONE'
      ]
    ]
  }

  componentDidMount() {
    this.setValue('tags', this.props.tags ? this.props.tags.filter(tag => tag.checked).map(tag => tag.id) : []);
  }

  validateContent = (value) => {
    const { entity_type, tags } = this.props;

    if (entity_type === 'STUDENT' && tags && tags.length && !value.length) {
      throw new Error(__("You can't add an address for a student without selecting at least one tag"));
    }
  }

  renderTags = tags =>
    !!tags &&
    !!tags.length &&
      <Form.Field>
        <label>{__('Tags')}</label>
        {
          tags.map((tag, i) =>
            <ColoredCheckbox
              key={i}
              name="tags"
              data-name="tags"
              className="label"
              label={tag.name}
              value={tag.name}
              data-value={tag.name}
              checked={!!this.state.values.tags.find(id => id == tag.id)}
              onClick={() => this.toggleValue('tags', tag.id)}
              style={{ marginRight: 15 }}
            />
          )
        }
      </Form.Field>

  beforeSubmit = (values) => {
    if (values.tags.length > 0) {
      values.tags = values.tags.map(id => ({ id, name: this.props.tags.find(tag => tag.id === id).name }));
    }

    return values;
  }

  render() {
    const { values, errors } = this.state;
    const { store, tags } = this.props;
    const { currentOrganization, entity, currentUser } = store;
    const organization = currentOrganization || (entity && entity.node && entity.node.organization);

    return (
      <Form id="AddressForm" {...this.props} onSubmit={this.handleSubmit}>
        {
          values.type === 'PHONE' ?
            <Form.Field
              label={__('Phone')}
              name="address"
              type="text"
              control={PhoneInput}
              onChange={value => this.onInputChange(null, { name: 'address', value: value && value.replace(/[^0-9]/g, '') })}
              error={errors.address}
              autoFocus
            />
            :
            <Form.Input
              label={__('Email')}
              name="address"
              value={values.address}
              onChange={this.onInputChange}
              error={errors.address}
              autoFocus
            />
        }
        {this.renderTags(tags)}
        {
          errors.tags ?
            <div>
              <Icon data-value="error" name="exclamation circle" size="large" color="red" />
              {errors.tags}
            </div>
            :
            null
        }
        {
          (organization && organization.live !== null) || (entity && entity.type !== 'STUDENT') || currentUser.isMaster ?
            [
              <Divider />,
              <Form.Checkbox
                label={__('Send invitation after adding')}
                name="invite"
                checked={values.invite}
                onClick={() => this.onInputChange(null, { name: 'invite', checked: !values.invite })}
              />
            ]
            :
            null
        }
      </Form>
    );
  }
}
