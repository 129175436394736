import React from 'react';
import { fireEvent, render, waitFor } from '../../../newRender';

import Form from '../Form';

describe('GroupForm', () => {
  let props = {
    store: {
      currentOrganization: {
        id: '1',
      },
      unleash: {
        isReady: true,
        getStatus: () => true,
      },
    },
    data: {
      loading: false,
      organizationMarkers: {
        nodes: [],
        totalCount: 0,
      },
    },
    values: {
      name: 'name',
    },
    advanced: false,
  };

  it('should render', () => {
    const container = render(<Form {...props} />);
    expect(container).toBeDefined();
  });

  it('should render with error in group name', () => {
    props.errors = {
      name: 'Testing component error',
    };
    const { queryByText } = render(<Form {...props} />);

    waitFor(() =>
      expect(queryByText('Testing component error')).toBeInTheDocument(),
    );
  });

  it('should render dropdown marker with data', () => {
    props.data = {
      loading: false,
      organizationMarkers: {
        nodes: [
          {
            id: '1',
            name: 'Marker 1',
          },
        ],
      },
    };

    const { getByTestId, queryByText } = render(<Form {...props} />);

    waitFor(() => {
      const dropdown = getByTestId('dropdown-marker');

      fireEvent.click(dropdown);
      expect(queryByText('Marker 1')).toBeInTheDocument();
    });
  });
});
