import React from 'react';
import { listenHostHistory } from '@classapp/sebastiao-routes';

if (typeof window === 'undefined') {
  global.window = {};
}

export default class App extends React.Component {
  componentDidMount() {
    listenHostHistory();
  }

  render() {
    return this.props.children;
  }
}
