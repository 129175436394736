import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import { handleError } from '../../utils';

import LabelForm from './Form';
import Modal from '../../components/ui/Modal';
import Button from '../../components/ui/Button';

import { __ } from '../../i18n';

@inject('store', 'client')
@graphql(gql`mutation updateLabel($updateLabelMutation: UpdateLabelInput!) {
  updateLabel(input: $updateLabelMutation) {
    clientMutationId
  }
}`, {
  name: 'updateLabel',
  options: {
  refetchQueries: ['OrganizationEditQuery', 'OrganizationEditMasterQuery', 'LabelEditQuery']
  }
  })
@graphql(gql`mutation deleteLabel($DeleteLabelInput: DeleteLabelInput!) {
  deleteLabel(input: $DeleteLabelInput) {
    clientMutationId
  }
}`, {
  name: 'deleteLabel',
  options: {
  refetchQueries: ['OrganizationEditQuery', 'OrganizationEditMasterQuery', 'LabelEditQuery']
  }
  })
@observer
export default class LabelEdit extends Controller {
  request = (values) => {
    this.setState({ loading: true });

    return this.props.updateLabel({
      variables: {
        updateLabelMutation: {
          id: this.props.label.id,
          ...values
        }
      }
    }).then(() => {
      this.setState({ loading: false });
      this.props.store.snackbar = { active: true, message: __('Label %s edited successfully', values.title), success: true };
      this.props.store.appends.pop();
    });
  };

  __handleDeleteLabel = async (label) => {
    const { store } = this.props;
    this.setState({ loading: true });
    try {
      await this.props.deleteLabel({
        variables: {
          DeleteLabelInput: {
            id: label.id,
            forceDelete: label.usedInMessages || false
          }
        }
      });
      store.snackbar = { active: true, message: __('Label was deleted'), success: true };
      store.appends.pop(); // Remove delete label modal
      store.appends.pop(); // Remove edit label modal
      this.setState({ loading: false });
    } catch (err) {
      console.error(err);
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 4000 };
      this.setState({ loading: false });
    }
  }

  openDeleteLabel = () => {
    const label = this.props.label;

    this.props.store.appends.push(
      <Modal
        id="InfoModal"
        onClose={() => this.props.store.appends.pop()}
        header={__('Do you want to delete this label?')}
        content={
          <div>
            <span>{label.usedInMessages ? __('This label is linked to messages. When you delete it, all linked messages will lose the label. Do you want to proceed?') : __('This action cannot be undone.')}</span>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '25px' }}>
              <Button
                data-action="delete-modal-cancel"
                round
                transparent
                loading={this.state.loading}
                text={__('Cancel')}
                onClick={() => this.props.store.appends.pop()}
              />
              <Button
                data-action="submit"
                round
                transparent
                loading={this.state.loading}
                red
                icon={{ name: 'trash' }}
                text={__('Yes, I want to delete')}
                onClick={() => this.__handleDeleteLabel(label)}
              />
            </div>
          </div>
        }
      />
    );
  }

  render() {
    const { title, color } = this.props.label;
    return (
      <Controller
        id="LabelEdit"
        modal={{
          closeOnRootNodeClick: false,
          fullScreen: this.isMobile(),
          cssTags: this.isMobile() && 'ismobileadd',
          portalHeader: this.isMobile(),
          actions: false
        }}
        modalType={'beta'}
        edit
        title={this.isMobile() ? null : __('Edit label')}
        form={LabelForm}
        values={{
          title,
          color
        }}
        formProps={{
          label: this.props.label,
          edit: true
        }}
        loading={this.state.loading}
        submitButton={{
          text: __('Save'),
          isActionButtom: true,
          icon: 'check'
        }}
        deleteButton={{
          text: __('Delete'),
          isActionButtom: true,
          icon: 'trash',
          onClick: this.openDeleteLabel
        }}
        cancelButton={{
          text: __('Cancel'),
          isActionButtom: true
        }}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        onClose={() => this.props.store.appends.pop()}
        {...this.props}
      />
    );
  }
}
