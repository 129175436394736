import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { __ } from '../../i18n';

// Components
import Tag from '../../components/ui/Tag';

// Styles
import '../../assets/css/ui/Tag.module.scss';

// Others
import * as utils from '../../utils';

export default class ConciergeItem extends React.Component {
  renderGroupsTags = (items) => {
    const [firstGroup, ...otherGroups] = items;
    if (!firstGroup) return null;
    return (
      <div
        className="groups-container"
        style={{
          display: 'flex', flexDirection: 'row', margin: '1rem 0', flexWrap: 'wrap'
        }}
      >
        {firstGroup && (
        <Tag
          style={{
            margin: '0',
            background: 'none',
            border: '1px solid rgba(0, 0, 0, 0.16)',
            borderRadius: 200,
          }}
          tooltipOnOverflow
          name={firstGroup.name}
          className="gray big"
        />
        )}
        {otherGroups.length > 0
          && (
          <Popup
            key={2}
            hideOnScroll
            hoverable
            trigger={(
              <div
                style={{
                  marginLeft: 8,
                  height: 40,
                  width: 40,
                  border: '1px solid rgba(0, 0, 0, 0.16)',
                  borderRadius: 200,
                  padding: '4px 8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                +
                {otherGroups.length}
              </div>
            )}

            content={(
              <div className="scroll-popup">
                {otherGroups.map((item, i) => (
                  <Tag
                    key={i}
                    style={{
                      height: 40,
                      border: '1px solid rgba(0, 0, 0, 0.16)',
                      borderRadius: 200,
                      padding: '14px 16px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    tooltipOnOverflow
                    name={item.name}
                    className="gray big popupTag"
                  />
                ))}
              </div>
            )}
          />
          )}
      </div>
    );
  };

  render() {
    const {
      concierge: { name, address, groups }, onDelete, onEdit, disabled
    } = this.props;

    return (
      <div className="concierge-item">
        <div className="info-container">
          <div>
            <span className="concierge-name">{utils.renderLongText(name)}</span>
            <span className="concierge-address">{address ? utils.renderLongText(address) : __('Address not specified')}</span>
          </div>

          {onEdit && (
          <Icon
            className="pen"
            style={{
              cursor: disabled ? 'unset' : null
            }}
            onClick={disabled ? null : onEdit}
          />
          )}
          {
            onDelete && (
            <Icon
              className="trash alt"
              style={{
                cursor: disabled ? 'unset' : null
              }}
              onClick={disabled ? null : onDelete}
            />
            )
          }
        </div>
        {this.renderGroupsTags(groups)}
      </div>
    );
  }
}
