import { __ } from '../../../i18n';

export function typeOptions() {
  return [
    {
      text: __('Web'),
      value: 'WEB',
    },
    {
      text: __('iOS'),
      value: 'iOS',
    },
    {
      text: __('Android'),
      value: 'ANDROID',
    },
    {
      text: __('Windows Phone'),
      value: 'WINDOWS_PHONE',
      // }, {
      //   text: __('Cross-platform'), value: 4
      // }];
    },
  ];
}
