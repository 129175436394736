import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import Form from '../../../components/ui/Form';

import { __ } from '../../../i18n';

export default class GroupForm extends Form {
  defaultValues = {
    name: '',
    internal: false,
    seeAll: false,
    type: null
  }

  rules = {
    name: 'required'
  }

  render() {
    const { values, errors } = this.state;

    return (
      <Form id="GroupForm" {...this.props} onSubmit={this.handleSubmit}>
        <div style={{ marginBottom: '16px' }}>
          <Form.Input
            label={__('Group name')}
            placeholder={__('Group name')}
            name="name"
            value={values.name}
            onChange={e => this.onInputChange(null, { name: 'name', value: e.target.value })}
            autoFocus
            maxLength={255}
          />
          {
            errors && errors.name && errors.name !== 'Error' ?
              <div style={{ marginBottom: 14 }}>
                <Icon data-value="error" name="exclamation circle" size="large" color="red" />
                {errors.name}
              </div>
              :
              null
          }
        </div>
        <div>
          <div className="input-component" style={{ marginBottom: '16px' }}>
            <span>{__('Permissions')}</span>
          </div>
          <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'baseline' }}>
            <Form.Checkbox
              style={{ fontSize: '1rem' }}
              label={__('For internal communication')}
              name="internal"
              radio
              radioChecked
              bold={false}
              checked={values.internal}
              onClick={() => this.onInputChange(null, { name: 'internal', checked: !values.internal })}
            />
            <Popup
              trigger={<Icon style={{ fontSize: '20px', fontWeight: '300', opacity: '.6', marginLeft: 'auto', order: 2 }} name="info circle" color="grey" />}
              content={<span>{__('Staff can send message to other staff in the group')}</span>}
              hideOnScroll
              position="top right"
              basic
            />
          </div>
          <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'baseline' }}>
            <Form.Checkbox
              style={{ fontSize: '1rem' }}
              label={__('Visible to all the staff')}
              name="seeAll"
              radio
              radioChecked
              bold={false}
              checked={values.seeAll}
              onClick={() => this.onInputChange(null, { name: 'seeAll', checked: !values.seeAll })}
            />
            <Popup
              trigger={<Icon style={{ fontSize: '20px', fontWeight: '300', opacity: '.6', marginLeft: 'auto', order: 2 }} name="info circle" color="grey" />}
              content={<span>{__('All staff members can send messages to this group, even if they are not in the group')}</span>}
              hideOnScroll
              position="top right"
              basic
            />
          </div>
        </div>
      </Form>
    );
  }
}
