import React from 'react';
import { inject, observer } from 'mobx-react';
import { Sidebar, Menu, Container, Image, Label } from 'semantic-ui-react';

import Snackbar from '../components/Snackbar';
import NoJavascript from '../components/NoJavascript';
import Responsive from '../components/Responsive';

import * as utils from '../utils';

@inject('store') @observer
export default class NotLoggedIn extends Responsive {
  componentWillUnmount() {
    this.props.store.snackbar = {
      active: false,
      message: '',
      success: false
    };
  }

  renderPrepends = () => this.props.store.prepends.map((prepend, i) =>
    <div key={i}>{prepend}</div>
  )

  renderAppends = () => this.props.store.appends.map((append, i) =>
    <div key={i}>{append}</div>
  )

  render() {
    const { app } = this.props.store;

    return (
      <Sidebar.Pushable id="NotLoggedIn">

        {this.renderPrepends()}
        {
          !/\/terms/.test(this.props.location.pathname) &&
          <Menu fixed="top" borderless>
            <Container>
              <Image src={utils.asset('/logo/ClassApp-LOGO-SIMBOLO_azul.png')} size="mini" centered className="logo" />
            </Container>
            {
              app.env !== 'production' ?
                <Menu.Item>
                  <Label color={utils.envColor(app.env)} as="a" href={app.url}>{app.env}</Label>
                </Menu.Item> : null
            }
          </Menu>
        }

        <NoJavascript />
        <Sidebar animation="overlay" width="wide" visible={false} />
        <Sidebar.Pusher className={this.isMobile() ? 'md' : ''}>
          {this.props.children}
        </Sidebar.Pusher>
        <Snackbar />
        {this.renderAppends()}
      </Sidebar.Pushable >
    );
  }
}
