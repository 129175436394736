import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Controller from '../../components/Controller';
import Modal from '../../components/Modal';
import IntegrationForm from '../Integration/Form';
import { __ } from '../../i18n';

@inject('store')
@graphql(gql`query IntegrationEditQuery ($id: ID!) {
    node(id: $id) @connection(key: "Integration", filter: ["id"]) {
      ... on Integration {
        id: dbId
        time
        splitedConfiguration
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.integration_id
    }
  })
})
@graphql(gql`mutation updateIntegration($updateIntegrationMutation: UpdateIntegrationInput!) {
  updateIntegration(input: $updateIntegrationMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['UserIntegrationsQuery', 'IntegrationEditQuery']
  }
})
@observer
export default class IntegrationEdit extends Controller {
  request = (values) => {
    values.configuration = JSON.parse(values.configuration);
    return this.props.mutate({
      variables: {
        updateIntegrationMutation: {
          id: this.props.params.integration_id,
          ...values
        }
      }
    }).then(() => {
      this.props.store.snackbar = { active: true, message: __('Integration was saved'), success: true, dismissAfter: 3000 };
      this.props.router.push('/admin/integrations/organizations');
    });
  }

  render() {
    const { data } = this.props;
    if ((data.loading && !data.node) || !data.node) return <Modal loading />;
    const { time, splitedConfiguration } = data.node;

    return (
      <Controller
        id="IntegrationEdit"
        modal
        edit
        title={__('Edit integration')}
        form={IntegrationForm}
        values={{
          time,
          configuration: JSON.stringify(splitedConfiguration.integration, null, 2)
        }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
