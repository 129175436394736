import React from 'react';

import PaywallModal from '../PaywallModal';

import { fireEvent, render, waitFor } from '../../../newRender';

describe('<PaywallModal /> Component', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      store: {
        currentOrganization: {
          fullname: 'Organization 1',
          plan: 'light'
        },
        entity: {
          type: 'ADMIN'
        },
        appends: [{ id: 1 }],
      },
      feature: {},
      onClose: jest.fn(),
      header: 'Paywall Modal',
      portalHeader: undefined,
    };
  });

  it('should render paywall modal', () => {
    const { container } = render(<PaywallModal {...props} />);
    expect(container).toBeDefined();
  });

  it('should click paywall modal close icon', () => {
    const { findByTestId } = render(<PaywallModal {...props} />);

    const whatsappButton = findByTestId('paywall-modal-whatsapp-button');

    waitFor(() => expect(whatsappButton).toBeInTheDocument());
  });
});
