import React from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Divider, Loader, Dropdown, Icon, Button } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { cloneDeep, omit } from 'lodash';
import { execute, makePromise } from 'apollo-link';

import Form from '../../components/ui/Form';
import Avatar from '../../components/Avatar';
import Tag from '../../components/ui/Tag';
import MultipleDropdown from '../../components/ui/MultipleDropdown';
import BetaModal from '../../components/ui/Modal';
import BetaButton from '../../components/ui/Button';

import EntityItem from '../Entity/Item';
import EntityForm from '../Entity/Form';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const styles = {
  label: {
    margin: '0px 0px 8px 0px',
  },
};

const entityTypes = (organizationType) => ({
  STUDENT: {
    name:
      utils.checkIfItIsClientOrSchool(organizationType) === 1
        ? __('Student')
        : __('Client'),
    color: 'grey',
  },
  STAFF: { name: __('Staff'), color: 'brown' },
  ADMIN: { name: __('Admin'), color: 'black' },
});

const roleFormEntitiesQuery = gql`
  query RoleFormEntitiesQuery($id: ID!, $search: String, $isChannel: Boolean) {
    node(id: $id) @connection(key: "Organization", filter: ["id"]) {
      ... on Organization {
        id: dbId
        entities(search: $search, isChannel: $isChannel) {
          nodes {
            id: dbId
            fullname
            picture {
              uri
              id: dbId
              key
            }
            roles {
              nodes {
                id: dbId
              }
            }
            ...EntityItemEntity
          }
        }
      }
    }
  }
  ${EntityItem.fragments.entity}
`;

const createPicture = (entityId) => `mutation createPicture {
  createPicture(input: { entityId: ${entityId} }) {
    clientMutationId
  }
}`;

const DropdownEntityItem = ({ id, entity }) => {
  const organizationType = entity.organization && entity.organization.type;

  return (
    <div
      key={id}
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Avatar
        style={{ width: 30, height: 30 }}
        circular
        spaced="right"
        src={entity.picture && entity.picture.uri}
        alt={entity.fullname}
      />
      <span style={{ fontSize: '1rem' }}>{entity.fullname}</span>
      <Tag
        name={entityTypes(organizationType)[entity.type].name}
        className="transparent"
      />
    </div>
  );
};

@inject('store', 'client', 'api')
@graphql(
  gql`
    query RoleFormGroupsQuery($id: ID!) {
      node(id: $id) @connection(key: "Organization", filter: ["id"]) {
        ... on Organization {
          id: dbId
          type
          groups(limit: 400) {
            totalCount
            nodes {
              id: dbId
              name
            }
            pageInfo {
              hasPreviousPage
              hasNextPage
            }
          }
          tags(limit: 400) {
            nodes {
              name
              checked
              id: dbId
            }
          }
        }
      }
    }
  `,
  {
    options: (ownProps) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        id: ownProps.store.currentOrganization.id,
      },
    }),
  },
)
@graphql(
  gql`
    mutation createEntity($createEntityMutation: CreateEntityInput!) {
      createEntity(input: $createEntityMutation) {
        clientMutationId
        entity {
          id: dbId
        }
      }
    }
  `,
  {
    options: {
      refetchQueries: ['OrganizationEntitiesQuery'],
    },
  },
)
@observer
export default class RoleForm extends Form {
  constructor(props) {
    super(props);

    this.state.entities = props.entities || [];
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, client, store } = nextProps;

    if ((data.loading && !data.node) || !data.node) return;

    const { totalCount, nodes } = data.node.groups;

    if (totalCount === nodes.length) return;

    try {
      const response = await client.query({
        query: gql`
          query RoleFormGroupsGroupsQuery($id: ID!, $limit: Int) {
            node(id: $id) {
              ... on Organization {
                id: dbId
                groups(limit: $limit) {
                  nodes {
                    type
                    name
                    id: dbId
                  }
                }
              }
            }
          }
        `,
        variables: {
          id: store.currentOrganization.id,
          limit: totalCount,
        },
      });

      data.updateQuery((previousResult) => {
        const nextResult = cloneDeep(previousResult);
        nextResult.node.groups.nodes = response.data.node.groups.nodes;

        return nextResult;
      });
    } catch (e) {
      console.log(e);
    }
  }

  defaultValues = {
    entities: [],
    groups: [],
    attach: true,
  };

  rules = {
    entities: [
      (val) => {
        if (val.length > 0) return true;
        const { type } = this.props;
        const error =
          type === 'entity'
            ? __('Add an existing entity and try again.')
            : __('Add an existing channel and try again.');
        throw new Error(error);
      },
    ],
    groups: [
      (val) => {
        if (val.length > 0) return true;
        throw new Error(__('The groups field cannot be empty'));
      },
    ],
  };

  beforeSubmit = (values) => {
    const groups = this.props.data.node.groups.nodes;

    values.groups = values.groups.map((groupId) => ({
      id: groupId,
      name: groups.find((group) => group.id === groupId).name,
    }));

    return values;
  };

  // Fetch for entities on search
  onSearchChange = (e, value) => {
    this.setState({ loading: true, searchQuery: value.searchQuery });

    const variables = {
      id: this.props.store.currentOrganization.id,
      search: value.searchQuery,
    };

    if (this.props.type === 'channel') {
      variables.isChannel = true;
    } else if (this.props.type === 'entity') {
      variables.isChannel = false;
    }

    makePromise(
      execute(this.props.client.link, {
        query: roleFormEntitiesQuery,
        variables,
      }),
    )
      .then((data) => {
        this.setState({ loading: false });

        const entities = this.state.entities
          .concat(data.data.node.entities.nodes)
          .filter(
            (entity, i, self) =>
              self.findIndex((s) => entity.id === s.id) === i,
          );

        this.setState({ entities });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  renderLabel = (label) => ({
    image: (
      <Avatar
        avatar
        spaced="right"
        src={label.entity.picture && label.entity.picture.uri}
        alt={label.entity.fullname}
      />
    ),
    content: label.text,
  });

  getEntities = (filterEntities = true) => {
    const { entities, values } = this.state;

    return entities
      .filter(
        (entity) =>
          !filterEntities ||
          this.props.multipleGroups ||
          (entity.roles &&
            !entity.roles.nodes.find((role) => role.id === values.groups[0])),
      )
      .map((entity) => ({
        text: entity.fullname,
        value: entity.id,
        content: (
          <DropdownEntityItem
            id={`optionEntity${entity.id}`}
            entity={filter(EntityItem.fragments.entity, entity)}
          />
        ),
        entity,
      }));
  };

  getGroups = (groups) =>
    groups.map((group) => ({
      text: group.name,
      value: group.id,
      'data-id': group.id,
    }));

  beforeOnSubmit = () => this.setState({ loading: true });

  afterOnSubmit = () => this.setState({ loading: false });

  onSubmitEntity = (values) => {
    const { store, api, data } = this.props;

    const entityValues = omit(values, 'picture');
    const picture = values.picture;

    return this.props
      .mutate({
        variables: {
          createEntityMutation: {
            organizationId: data.node.id,
            updateIfExists: false,
            ...entityValues,
          },
        },
      })
      .then((res) => {
        if (
          this.props.multipleGroups ||
          !values.groups.find(
            (group) => group.id === this.state.values.groups[0],
          )
        ) {
          this.setValue(
            'entities',
            this.state.values.entities.concat([
              res.data.createEntity.entity.id,
            ]),
          );
        }
        this.onSearchChange({}, { searchQuery: values.fullname });

        this.setState({ loading: true });
        if (picture) {
          api
            .upload('picture', {
              file: picture,
              query: createPicture(res.data.createEntity.entity.id),
            })
            .then(async () => {
              await this.props.client.resetStore();
              this.setState({ loading: false });
            })
            .catch((err) => {
              store.snackbar = {
                active: true,
                message: utils.handleError(err.graphQLErrors[0]),
                success: false,
              };
              this.setState({ loading: false });
            });
        }
      })
      .then(() => {
        this.setState({ loading: false });
        this.props.store.snackbar = {
          active: true,
          message: __('%s was added', values.fullname),
          success: true,
        };
        store.appends.pop();
      })
      .catch((err) => {
        this.setState({
          errors: { fullname: utils.handleError(err.graphQLErrors[0]) },
        });
      });
  };

  onEntityAdd = (e, { value }) => {
    const { store, data } = this.props;
    const { entities } = this.state.values;
    const index = entities.indexOf((id) => id === value);

    entities.splice(index, 1);

    this.setValue('entities', entities);

    store.appends.push(
      <BetaModal
        size="small"
        id="EntityAdd"
        onClose={() => store.appends.pop()}
        closeOnRootNodeClick={false}
        fullScreen={this.isMobile()}
        cssTags={this.isMobile() && 'ismobileadd'}
        portalHeader={this.isMobile()}
        actions={false}
        fixHeader={this.isMobile()}
        header={__('Add person')}
        content={
          <EntityForm
            modal
            values={{ fullname: value }}
            onSubmit={(values) => this.onSubmitEntity(values)}
            onClose={() => store.appends.pop()}
            submitButtonIcon="check"
            organization={{
              id: data.node.id,
              type: data.node.type,
              groups: data.node.groups.nodes,
              tags: data.node.tags.nodes,
            }}
            submitButton={{
              text: __('Add'),
              isActionButtom: true,
            }}
            cancelButton={{
              text: __('Cancel'),
              isActionButtom: true,
            }}
          />
        }
      />,
    );
  };

  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node)
      return <Loader active inline="centered" />;

    const { values, loading, errors, searchQuery } = this.state;
    return (
      <Form
        id="RoleForm"
        {...this.props}
        loading={loading || this.props.loading}
        onSubmit={this.handleSubmit}
        beforeOnSubmit={this.beforeOnSubmit}
        afterOnSubmit={this.afterOnSubmit}
      >
        <p className="bold" style={styles.label}>
          {!this.props.type
            ? __('People')
            : this.props.type === 'entity'
              ? __('People')
              : __('Channels')}
        </p>

        <MultipleDropdown
          reference={(c) => {
            this.entitiesDropdown = c;
          }}
          name="entities"
          renderLabel={this.renderLabel}
          onSearchChange={this.onSearchChange}
          noResultsMessage={
            values.entities.length > 0
              ? __('No results were found')
              : __('Search...')
          }
          error={errors && errors.entities}
          value={toJS(values.entities)}
          loading={loading}
          options={this.getEntities()}
          allowAdditions={
            !loading &&
            !!searchQuery &&
            !this.getEntities(false).find((e) => e.text === searchQuery)
          }
          onChange={(e, prop) =>
            this.onSelectionChange(
              e,
              prop,
              () =>
                this.entitiesDropdown &&
                this.entitiesDropdown.clearSearchQuery(),
            )
          }
          onAddItem={this.onEntityAdd}
          additionLabel={__('Add ')}
        />
        <Divider hidden />
        {this.props.multipleGroups && (
          <div>
            <p className="bold" style={styles.label}>
              {__('Groups')}
            </p>
            <MultipleDropdown
              reference={(c) => {
                this.groupsDropdown = c;
              }}
              name="groups"
              noResultsMessage={__('No results were found')}
              error={errors && errors.groups}
              value={toJS(values.groups)}
              options={this.getGroups(data.node.groups.nodes)}
              onChange={(e, prop) =>
                this.onSelectionChange(
                  e,
                  prop,
                  () =>
                    this.groupsDropdown &&
                    this.groupsDropdown.clearSearchQuery(),
                )
              }
            />
            <Divider hidden />

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ marginBottom: '1rem' }}>
                <Form.Checkbox
                  radio
                  radioChecked
                  data-action="keep-old-groups"
                  name="attach"
                  value
                  checked={values.attach}
                  color="#084FFF"
                  onClick={(e) =>
                    this.onInputChange(e, {
                      name: 'attach',
                      value: true,
                      checked: values.attach,
                    })
                  }
                  style={{
                    width: '24px',
                    height: '24px',
                    fontWeight: 'bold',
                    marginRight: '10px',
                  }}
                />
                <span>{__('Keep old groups')}</span>
              </div>
              <div>
                <Form.Checkbox
                  radio
                  radioChecked
                  data-action="substitute-old-groups"
                  name="attach"
                  value={false}
                  checked={!values.attach}
                  color="#084FFF"
                  onClick={(e) =>
                    this.onInputChange(e, {
                      name: 'attach',
                      value: false,
                      checked: !values.attach,
                    })
                  }
                  style={{
                    width: '24px',
                    height: '24px',
                    fontWeight: 'bold',
                    marginRight: '10px',
                  }}
                />
                <span>{__('Substitute old groups with new ones')}</span>
              </div>
            </div>
          </div>
        )}
      </Form>
    );
  }
}
