import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import { __ } from '../../i18n';
@graphql(gql`mutation deleteOrganizationAccess($deleteOrganizationAccessMutation: deleteLinkInput!) {
  deleteLink(input: $deleteOrganizationAccessMutation) {
    link {
      dbId
    }
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['OrganizationAccessesQuery']
  }
})
@inject('store')
export default class AccessDelete extends Controller {
  request = () => {
    this.setState({ loading: true });

    this.props.mutate({
      variables: {
        deleteOrganizationAccessMutation: {
          id: this.props.params.access_id
        }
      }
    }).then(() => {
      this.props.store.snackbar = { active: true, message: __('Access was deleted'), success: true, dismissAfter: 3000 };
      this.props.router.push(`/organizations/${this.props.params.organization_id}/accesses`);
      this.setState({ loading: false });
    }).catch((err) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <Controller
        id="AccessDelete"
        delete
        object={__('access')}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}