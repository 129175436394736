import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import { __ } from '../../i18n';

@graphql(gql`mutation deleteFormField($deleteFormFieldMutation: DeleteFormFieldInput!) {
  deleteFormField(input: $deleteFormFieldMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['FormResultFormQuery', 'FormNodeQuery', 'FormResultFillQuery']
  }
})
@inject('store') @observer
export default class FormFieldDelete extends Controller {
  request = () => this.props.mutate({
    variables: {
      deleteFormFieldMutation: {
        id: this.props.params.field_id
      }
    }
  }).then(() => {
    this.props.store.snackbar = { active: true, message: __('Form field was deleted'), success: true };
    this.props.router.push(`/organizations/${this.props.params.organization_id}/forms/${this.props.params.form_id}`);
  })

  render() {
    return (
      <Controller
        id="FormFieldDelete"
        delete
        object={__('form field')}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
