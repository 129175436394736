import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';

import { __ } from '../../i18n';

import Form from '../../components/Form';

const styles = {
  label: {
    margin: '0px 0px 8px 0px'
  }
};

@inject('store') @observer
export default class FormForm extends Form {
  defaultValues = {
    name: '',
    description: '',
    template: true
  }

  rules = {
    name: ['required', values => this.validateContent('name', values)],
    description: [values => this.validateContent('description', values)]
  }

  validateContent = (name, values) => {
    if (name === 'name' && values.trim().length <= 0) throw new Error(__('Name cannot be empty'));

    if (name === 'name' && values.trim().length > 255) throw new Error(__('Name cannot be longer than 255 characters'));

    if (name === 'description' && ((this.state.values.description.length > 64))) throw new Error(__('Description cannot be longer than 64 characters'));
  }

  render() {
    const { values, errors } = this.state;

    return (
      <Form id="FormForm" {...this.props} onSubmit={this.handleSubmit}>
        <p className="bold" style={styles.label}>{__('Form name')}</p>
        <Form.Input
          placeholder={__('Form name')}
          name="name"
          value={values.name}
          onChange={this.onInputChange}
          autoFocus
        />
        {
          errors && errors.name ?
            <div style={{ marginBottom: 14 }}>
              <Icon data-value="error" name="exclamation circle" size="large" color="red" />
              {errors.name}
            </div>
            :
            null
        }
        <p className="bold" style={styles.label}>{__('Description (optional)')}</p>
        <Form.Input
          placeholder={__('Ex: Daily Routine')}
          name="description"
          value={values.description}
          onChange={this.onInputChange}
        />
        {
          errors && errors.description ?
            <div style={{ marginBottom: 14 }}>
              <Icon data-value="error" name="exclamation circle" size="large" color="red" />
              {errors.description}
            </div>
            :
            null
        }
      </Form>
    );
  }
}
