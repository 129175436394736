import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Card,
  Container,
  Icon,
  Divider,
  Dropdown,
  Popup,
  Button
} from 'semantic-ui-react';
import gql from 'graphql-tag';

import { __ } from '../../i18n';
import * as utils from '../../utils';

import Modal from '../../components/ui/Modal';
import Responsive from '../../components/Responsive';
import OptionsToast from '../../components/OptionsToast';

@inject('store') @observer
export default class SurveyItem extends Responsive {
  static fragments = {
    survey: gql`
      fragment SurveyItem on Survey {
        id: dbId
        name
        expired
        dateLimit
        optionNumber
        deleted
        summary {
          nodes {
            id: dbId
            value
            count
          }
        }
      }
    `,
  }

  renderLimitDate = (survey) => {
    const dateString = new Date(utils.parseUniversalDateFormat(survey.dateLimit));
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);

    return utils.simpleDate(dateString, true, 'LL', lang);
  }

  renderMenu = () => {
    const { isMessageToApprove } = this.props;

    const menu = [
      !isMessageToApprove && { dataAction: 'edit', text: __('Edit'), icon: 'edit', color: '#000000', closeOnClick: true, onClick: () => { this.props.onEdit(); } },
      { dataAction: 'delete', text: __('Delete Survey'), icon: 'trash', color: '#BF2600', closeOnClick: true, onClick: () => { this.props.onDelete(); } },
      { dataAction: 'cancel', text: __('Cancel'), icon: 'times', color: '#000000', closeOnClick: true },
    ];
    return (
      this.props.store.appends.push(<Modal
        id="OptionsToast"
        toast
        invertCloseButton
        onClose={() => this.props.store.appends.pop()}
        closeOnRootNodeClick
        header={__('Survey Options')}
        spaceFooter
        content={<OptionsToast menu={menu} />}
      />));
  }

  render() {
    const { survey, id, isMessageToApprove } = this.props;
    const { optionNumber, name } = survey;
    const optionsRemaining = optionNumber;


    let items = [];
    items = items.concat([
      !isMessageToApprove && <Dropdown.Item
        onClick={() => this.props.onEdit()}
      >
        {__('Edit')}
      </Dropdown.Item>,
      <Dropdown.Item onClick={() => this.props.onDelete()}>
        {__('Delete')}
      </Dropdown.Item>
    ]);

    return (
      <Card
        key={id}
        data-type="SurveyItem"
        data-index={id}
        fluid
        style={{
          marginBottom: '1em',
          width: this.isMobile() ? '100%' : '355px',
          height: this.isMobile() ? '' : '159px',
          borderRadius: '12px',
          marginRight: '8px'
        }}
      >
        <Card.Content style={{ margin: 0 }}>
          <Container textAlign="center">
            <div className="horizontallySpacedItems" style={{ margin: this.isMobile() ? '22px 10px 14px' : '22px 24px 14px' }}>
              <div className="bold"><Icon name="bar chart" style={{ color: '#000000', marginRight: '12px', fontSize: '16px', fontWeight: 300 }} />
                <Popup
                  trigger={<span>{(name.length > 25) ? (name.substring(0, 25) + '...') : name}</span>}
                  content={<span>{name}</span>}
                  hideOnScroll
                />
              </div>
              {
                items.length ?
                  !this.isMobile() ?
                    <Dropdown data-testid="surveys-edit-menu" icon={null} trigger={<Icon data-action="open-survey-options" name="ellipsis v" style={{ margin: 0 }} />}>
                      <Dropdown.Menu className="bold">
                        {items}
                      </Dropdown.Menu>
                    </Dropdown>
                    :
                    <Icon data-testid="surveys-edit-menu" data-action="open-survey-options" name="ellipsis v" onClick={() => this.renderMenu()} style={{ margin: 0 }} />
                  :
                  null
              }
            </div>
            <div style={{ margin: this.isMobile() ? '0 18px' : '0 32px', marginBottom: survey.dateLimit ? '14px' : '24px' }}>
              <div
                style={{
                  borderRadius: '200px',
                  backgroundColor: '#ffffff',
                  display: 'table',
                  width: '100%',
                  border: '1px solid #084FFF99'
                }}
              >
                <p style={{ fontSize: '13px', fontWeight: '700', padding: '12px', color: '#084FFF', display: 'table-cell', verticalAlign: 'middle' }}>
                  {
                    (optionNumber > 1 && optionsRemaining > 0) ?
                      optionsRemaining + __(' available options') :
                      optionsRemaining + __(' available option')
                  }
                </p>
              </div>
            </div>
            {
              survey.dateLimit &&
              <p style={{ color: '#ffb43c', fontSize: '13px', fontWeight: 300, marginBottom: '24px' }}>
                <Icon name="exclamation circle" />
                {__('Answers until %s', this.renderLimitDate(survey))}
              </p>
            }
          </Container>
        </Card.Content>
        {this.props.children}
      </Card>
    );
  }
}
