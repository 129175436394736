import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Card, Icon, Dropdown, Button, Message } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import PaymentDetails from '../Payment/Details';
import ChargeStatus from '../Charge/Status';
import PaymentMethodItem from './PaymentMethodItem';

import { __ } from '../../i18n';
import * as utils from '../../utils';
import {
  isCardMethodAllowed,
  isBoletoMethodAllowed,
  isPixMethodIncluded,
  isPercentageType,
  paymentSituations,
  filterPayment,
  paymentMethods
} from '../../lib/payment';

import Modal from '../../components/Modal';
import Responsive from '../../components/Responsive';

const styles = {
  amount: {
    backgroundColor: '#f1f3f5',
    borderRadius: '4px',
    fontSize: '21px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#007fff',
    marginBottom: '8px',
    padding: '10px 0px',
  },
  button: {
    backgroundColor: 'white',
    color: '#212529',
    borderRadius: '24px',
    padding: '15px 0px',
    border: '1px solid #e9ecef',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
};

const statusType = () => ({
  PAID: { icon: 'check', color: '#37b24d', text: __('Payment confirmed') },
  PENDING: { icon: 'clock', color: '#f59f00', text: __('Pending payment') },
  CANCELED: { icon: 'hand holding usd', color: '#212529', text: __('Payment refunded') },
  FAILED: { icon: 'times', color: '#f03e3e', text: __('Payment failed') },
});

@inject('store')
@graphql(gql`
mutation updatePendingCharge($updateChargeInput: UpdateChargeInput!) {
  updateCharge(input: $updateChargeInput) {
    charge {
      id
      name
    }
  }
}`, {
  name: 'updatePendingCharge',
  options: {
  refetchQueries: ['MessageNodeEntityQuery', 'MessageEntityHistoryQuery']
  }
  })
@observer
export default class ChargeItem extends Responsive {
  static fragments = {
    charge: gql`
      fragment ChargeItem on Charge {
        id: dbId
        name
        amount
        hasFine
        hasInterest
        hasAnticipationDiscount
        anticipationDiscountFinalDate
        fineAmount
        interestAmount
        discountAmount
        cardFee
        boletoFee
        pixFee
        cardAmount
        paymentCardAmount
        organizationCardAmount
        boletoAmount
        paymentBoletoAmount
        organizationBoletoAmount
        pixAmount
        paymentPixAmount
        organizationPixAmount
        minimumAmount
        diferencePercentage
        differenceAmount
        minimumMethod
        paymentSituation
        dateLimit
        currency
        canRemind
        expired
        deleted
        paymentMethods
        absorbedFee
        allowedInstallments
        anticipationDiscountType
        anticipationDiscountValue
        anticipationDiscountUntil
        interestType
        interestValue
        interestStart
        fineType
        fineValue
        fineStart
        payableExpired
        account {
          id: dbId
          methods
          rates
        }
        payments(entityId: $entityId) {
          nodes {
            id: dbId
            entityId
            lastStatusUpdate
            status
            paidAmount
            installments
            created
            deleted
            method
            link
            installments
            reference
            expirationDate
            user {
              id: dbId
              fullname
            }
          }
        }
        message {
          id: dbId
          recipients {
            totalCount
          }
        }
        ...ChargeStatus
      }
      ${ChargeStatus.fragments.charge}
      `,

    chargeHistory: gql`
      fragment MessageChargeHistory on Message {
        charges {
          nodes {
            id: key
            name
            amount
            hasFine
            hasInterest
            hasAnticipationDiscount
            anticipationDiscountFinalDate
            fineAmount
            interestAmount
            discountAmount
            cardFee
            boletoFee
            pixFee
            cardAmount
            paymentCardAmount
            organizationCardAmount
            boletoAmount
            paymentBoletoAmount
            organizationBoletoAmount
            pixAmount
            paymentPixAmount
            organizationPixAmount
            minimumAmount
            diferencePercentage
            differenceAmount
            minimumMethod
            paymentSituation
            dateLimit
            currency
            canRemind
            expired
            deleted
            paymentMethods
            absorbedFee
            allowedInstallments
            anticipationDiscountType
            anticipationDiscountValue
            anticipationDiscountUntil
            interestType
            interestValue
            interestStart
            fineType
            fineValue
            fineStart
            payableExpired
            account {
              id: dbId
              methods
              rates
            }
            payments {
              nodes {
                id: dbId
                entityId
                lastStatusUpdate
                status
                paidAmount
                installments
                expirationDate
                reference
                created
                deleted
              }
            }
            message {
              id: dbId
              recipients {
                totalCount
              }
            }
            allPayments: payments {
              totalCount
            }
            ...PaymentDetails
          }
        }
      }
      ${PaymentDetails.fragments.payment}
      `,
  }

  constructor(props) {
    super(props);
    this.state = {
      online: window.navigator.onLine
    };

    this.currency = {
      BRL: 'R$',
      USD: 'US$'
    };
  }

  componentDidMount() {
    const { reload: reloadMessage } = this.props;

    window.ononline = () => {
      this.setState({ online: true });
      if (reloadMessage) reloadMessage();
    };

    window.onoffline = () => {
      this.setState({ online: false });
    };
  }

  renderLastUpdate = (dateString) => {
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);

    return utils.simpleDate(dateString, true, 'LL, HH:mm', lang);
  }

  remindPending = (numEntitiesToNotify) => {
    const { updatePendingCharge, store, charge } = this.props;
    const { canRemind } = charge;

    if (!canRemind) {
      store.snackbar = { active: true, message: __('A reminder has already been sent for this charge today'), success: false };
      return;
    }

    store.appends.push(
      <Modal
        id="RemindPendingConfirm"
        size="small"
        onClose={() => store.appends.pop()}
        header={__('Remind pending (%s) confirm', numEntitiesToNotify)}
        content={<Message warning>{__('Once sent, it can’t be undone.')}</Message>}
        actions={[
          <Button
            data-action="cancel"
            key={0}
            basic
            floated="left"
            content={__('Cancel')}
            onClick={() => store.appends.pop()}
          />,
          <Button
            data-action="confirm"
            key={1}
            primary
            content={__('Confirm')}
            onClick={() => {
              store.appends.pop();

              updatePendingCharge({
                variables: {
                  updateChargeInput: {
                    id: charge?.id,
                    remindPending: {
                      remindAll: true
                    }
                  }
                }
              }).then(() => {
                store.snackbar = { active: true, message: __('Reminders sent'), success: true };
              }).catch((err) => {
                store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
              });
            }}
          />
        ]}
      />
    );
  }

  renderLimitDate = (date) => {
    const { messageToApprove } = this.props;
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);

    const dateString = new Date(date);

    if(messageToApprove) {
      return utils.simpleDate(dateString, true, 'LL, HH:mm', lang);
    }

    const dateAdjusted = utils.adjustTZDate(dateString, -dateString.getTimezoneOffset());

    return utils.simpleDate(dateAdjusted, true, 'LL, HH:mm', lang);
  }

  render() {
    const { online } = this.state;
    const { charge, params, messageType, entity, store, fromHistory, messageToApprove } = this.props;
    const { name, amount, expired, dateLimit, deleted, id, payments,
      anticipationDiscountType, anticipationDiscountValue, anticipationDiscountFinalDate,
      minimumAmount, diferencePercentage, differenceAmount, minimumMethod, paymentSituation,
      payableExpired, hasFine, hasInterest } = charge;

    const entityId = store && store?.entity && store?.entity?.id;

    const paymentInfo = filterPayment(payments?.nodes.filter(node => node?.entityId === entityId), charge, false, store.currentOrganization.timezone);
    const ignoringDiscount = paymentInfo && paymentInfo?.ignoringDiscount;
    const ignoringExpiredBoleto = paymentInfo && paymentInfo?.ignoringExpiredBoleto;
    const payment = ignoringDiscount || ignoringExpiredBoleto ? null : paymentInfo;


    const lang = store.app.locale ? (store.app.locale === 'pt-BR' ? 'pt' : store.app.locale) : 'en';
    const exportLink = `${store.app.url}/csv/charge/payments?` +
      `limit=1000&charge_id=${id}&access_token=${encodeURIComponent(store.access_token)}&tz_offset=${-(new Date().getTimezoneOffset())}&locale=${lang}`;

    let allowCardPayment = isCardMethodAllowed(charge);
    let allowBoletoPayment = isBoletoMethodAllowed(charge);
    let allowPixPayment = isPixMethodIncluded(charge?.paymentMethods); // pix - use isPixMethodAllowed(charge) if we decide to use account methods
    const cheaperMethodExists = diferencePercentage > 0 && ((allowCardPayment && allowBoletoPayment) || (allowCardPayment && allowPixPayment) || (allowBoletoPayment && allowPixPayment)); // checks if some method is cheaper than other

    // backwards compatibility to the messages sent before the release of the "payment methods" feature
    if (!allowCardPayment && !allowBoletoPayment && !allowPixPayment) {
      allowBoletoPayment = true;
      allowCardPayment = true;
      allowPixPayment = true;
    }

    const paymentStatus = payment && statusType()[payment?.status];
    const numEntitiesToNotify = (charge?.message?.recipients?.totalCount - charge?.payments?.nodes.length) || 0;
    return (
      <div>
        <Card fluid key={id} style={{ marginBottom: '8px' }}>
          <Card.Content>
            <Container textAlign="center">
              <div className="horizontallySpacedItems">
                <div className="bold" style={{ marginBottom: '8px' }}>
                  <Icon name="donate" style={{ color: '#0080ff' }} />
                  {name}
                </div>
                {
                  (messageType === 'SENT' || params?.organization_id) && !fromHistory && !messageToApprove &&
                  <Dropdown icon={null} trigger={<Icon name="ellipsis v" />}>
                    <Dropdown.Menu className="bold" style={this.isMobile() ? { left: 'unset', right: 0 } : {}}>
                      {(!expired || payableExpired) &&
                        <Dropdown.Item data-action="remind-pending" onClick={() => this.remindPending(numEntitiesToNotify)}>
                          {__('Remind pending (%s)', numEntitiesToNotify)}
                        </Dropdown.Item>
                      }
                      <Dropdown.Item
                        as="a" href={exportLink}
                      >
                        {__('Export')}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                }
              </div>
              {
                (messageType === 'SENT' || params?.organization_id) ?
                  <div>
                    <div style={styles.amount}>
                      <p>
                        {charge?.amount && `${amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                      </p>
                    </div>
                    {
                      messageToApprove ?
                        <Button
                          fluid
                          disabled
                          style={styles.button}
                        >
                          {__('Your charge still has no payments')}
                        </Button>
                      :
                      !fromHistory &&
                      <Button
                        fluid
                        style={styles.button}
                        onClick={() => this.props.store.appends.push(<ChargeStatus
                          charge={filter(ChargeStatus.fragments.charge, charge)}
                        />)}
                      >
                        {__('See payment status')}
                      </Button>
                    }
                  </div>
                  :
                  !entity?.disabled && (
                    payment ?
                      <div>
                        <div style={{ backgroundColor: '#f1f3f5', paddingTop: '5px', paddingBottom: '5px' }}>
                          <p style={{ color: '#868e96', marginBottom: '0px', fontSize: '12px' }}>
                            {__('Amount paid')}
                          </p>
                          <p style={{ color: '#007fff', fontSize: '21px', fontWeight: 'bold' }}>
                            {payment?.paidAmount && `${payment?.paidAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                          </p>
                        </div>
                        {!messageToApprove && <div
                          style={{ color: paymentStatus?.color, border: 'solid 1px #e9ecef', padding: '15px', marginTop: '8px', borderRadius: '24px' }}
                          onClick={() => (this.isMobile() ? this.props.store.appends.push(<PaymentDetails payment={payment} charge={charge} />) : {})}
                        >
                          <Icon name={paymentStatus?.icon} />
                          {paymentStatus?.text}
                          {!this.isMobile() &&
                          <Icon
                            name="info circle"
                            style={{ float: 'right', color: '#212529', cursor: 'pointer' }}
                            onClick={() => this.props.store.appends.push(<PaymentDetails payment={payment} charge={charge} />)}
                          />}
                        </div>}
                      </div>
                      :
                      <div>
                        <div
                          style={{
                            height: 92,
                            width: '100%',
                            borderRadius: 4,
                            marginBottom: 24,
                            marginTop: 24,
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: '#f1f3f5'
                          }}
                        >
                          <div style={{ fontSize: 22, color: '#007fff', fontWeight: 'bold' }}>
                            {(minimumAmount || amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                          </div>
                          {
                            paymentSituation === paymentSituations?.ANTICIPATION_DISCOUNT && !deleted ?
                              <div style={{ color: '#6c6c6c' }}>
                                {__('Pay with a %s discount until %s',
                                  isPercentageType(anticipationDiscountType) ? `${anticipationDiscountValue}%` :
                                    anticipationDiscountValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                                  anticipationDiscountFinalDate
                                )}
                              </div> : cheaperMethodExists && !deleted ?
                                <div style={{ color: '#6c6c6c' }}>
                                  {__('There is a discount of %s%% (%s) on ', diferencePercentage, differenceAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))}
                                  <span style={{ fontWeight: 'bold' }}>{paymentMethods[minimumMethod]}</span>
                                </div>
                                : ''
                          }
                          {
                            ignoringDiscount && <div style={{ color: '#6c6c6c', marginTop: 16 }}>
                              <span style={{ fontWeight: 'bold' }}>{__('*If you already paid the charge with discount ignore this one')}</span>
                            </div>
                          }
                          {
                            ignoringExpiredBoleto && <div style={{ color: '#6c6c6c', marginTop: 16 }}>
                              <span style={{ fontWeight: 'bold' }}>{__('*If you already paid this charge just wait for the status to change')}</span>
                            </div>
                          }
                        </div>
                        {
                          allowPixPayment && !deleted &&
                          <PaymentMethodItem
                            method="PIX"
                            charge={charge}
                            highlight={cheaperMethodExists && minimumMethod === 'PIX' ? 'cheapest' : 'recommended'}
                            hideValues={!cheaperMethodExists}
                            disabled={!online}
                            messageToApprove={messageToApprove}
                          />
                        }
                        {
                          (allowCardPayment && !deleted) &&
                          <PaymentMethodItem
                            method="CARD"
                            charge={charge}
                            highlight={!allowPixPayment && differenceAmount > 0 && minimumMethod === 'CARD' && 'cheapest'}
                            hideValues={!cheaperMethodExists}
                            disabled={!online}
                            messageToApprove={messageToApprove}
                          />
                        }
                        {
                          (allowBoletoPayment && !deleted) &&
                          <PaymentMethodItem
                            method="BOLETO"
                            charge={charge}
                            highlight={!allowPixPayment && differenceAmount > 0 && minimumMethod === 'BOLETO' && 'cheapest'}
                            hideValues={!cheaperMethodExists}
                            disabled={!online}
                            messageToApprove={messageToApprove}

                          />
                        }
                        {deleted &&
                          <div style={{ color: '#6c6c6c', paddingBottom: 16 }}>
                            {__('This charge was deleted')}
                          </div>
                        }
                      </div>
                  )
              }

              {!online && !payment && (
                <div style={{ color: '#9B9E9F', margin: '5px' }}>
                  <Icon name="wifi slash" style={{ marginRight: '5px' }} />
                  {__('No internet connection')}
                </div>
              )}

              {
                deleted ?
                  <p style={{ color: '#f04040', paddingTop: '8px' }}>
                    <Icon name="times" />
                    {__('Charge was deleted')}
                  </p>
                  :
                  (
                    dateLimit &&
                    <p style={{ color: payment ? '#a0a0a0' : (expired ? (payableExpired ? '#ed335f' : '#a0a0a0') : '#ffb43c'), paddingTop: '8px' }}>
                      <Icon name="exclamation circle" />
                      {
                        payment ?
                          __('Last update %s', this.renderLastUpdate(payment.lastStatusUpdate))
                          :
                          expired ?
                            payableExpired ?
                              __('Overdue on %s', this.renderLimitDate(dateLimit))
                              :
                              __('Expired on %s', this.renderLimitDate(dateLimit))
                            :
                            payableExpired ?
                              __('Due on %s', this.renderLimitDate(dateLimit))
                              :
                              __('Expires on %s', this.renderLimitDate(dateLimit))
                      }
                    </p>
                  )
              }

            </Container>
          </Card.Content>
        </Card >
        {(hasFine || hasInterest) &&
          <p style={{ textAlign: 'left', color: 'rgba(108, 108, 108, 0.65)' }}>
            {__('*After the expiration date, the amount to be paid may include fine and interest')}
          </p>
        }
      </div>
    );
  }
}
