import React from 'react';
import { inject, observer } from 'mobx-react';
import { Table, Button, Icon, Popup } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { __ } from '../../i18n';

import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import Page from '../../components/Page';

const PAGE_SIZE = 1000;

@inject('store')
@graphql(gql`query SurveyResultsQuery($id: ID!, $limit: Int, $offset: Int) {
    node(id: $id) @connection(key: "Survey", filter: ["id"]) {
      ... on Survey {
        id: dbId
        options (limit: 40, orderBy: { column: ID, direction: ASC }){
          nodes {
            id: dbId
            value
          }
        }
        results (limit: $limit, offset: $offset, orderBy: {column: CREATED, direction: DESC}) {
          totalCount
          nodes {
            surveyOptionId
            entity {
              id: dbId
              fullname
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    fetchPolicy: 'network-only',
    variables: {
      id: ownProps.survey.dbId,
      limit: PAGE_SIZE,
      offset: 0
    }
  })
})
@observer
export default class SurveyResults extends Page {
  render() {
    const { data, store } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { id, options, results } = data.node;
    const exportLink = `${store.app.url}/csv/survey/results?` +
      `survey_id=${id}&access_token=${encodeURIComponent(store.access_token)}&limit=${results.totalCount}&tz_offset=${-(new Date().getTimezoneOffset())}`;
    const { nodes, pageInfo } = results;
    const entities = [];

    nodes.forEach((result) => {
      const index = entities.findIndex(e => e.id === result.entity.id);

      if (index > -1) {
        entities[index].choices.push(result.surveyOptionId);
      } else {
        entities.push(Object.assign({}, result.entity, { choices: [result.surveyOptionId] }));
      }
    });

    return (
      <Modal id="SurveyResults" open size="small" closeIcon="times close" onClose={() => this.props.store.appends.pop()}>
        <Modal.Header>{__('Survey')}</Modal.Header>
        <Modal.Content style={{ paddingRight: '16px' }}>
          <div style={{ overflowX: 'auto' }}>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  {options.nodes.map(option => <Table.HeaderCell key={option.id} textAlign="center">{option.value}</Table.HeaderCell>)}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  entities.map(entity =>
                    <Table.Row key={entity.id}>
                      <Table.Cell textAlign="left">{entity.fullname}</Table.Cell>
                      {
                        options.nodes.map((option) => {
                          if (entity.choices.indexOf(option.id) > -1) {
                            return (<Table.Cell textAlign="center">
                              <Popup
                                trigger={<Icon name="check" color="teal" />}
                                content={<span>{option.value}</span>}
                                hideOnScroll
                              />
                            </Table.Cell>);
                          }
                          return <Table.Cell />;
                        })
                      }
                    </Table.Row>
                  )
                }
              </Table.Body>
            </Table>
            <Pagination
              pagination={{
                graphql: true,
                hasNextPage: pageInfo && pageInfo.hasNextPage,
                loading: data.loading
              }}
              onLoadMore={() => this.loadMore('results')}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            data-action="export-survey-results"
            icon="cloud download"
            content={__('Export Results')}
            as="a"
            href={exportLink}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
