import { validateContent } from '../validateContent';

describe('validateContent', () => {
  it('should throw an error if title is empty', () => {
    expect(() => validateContent('title', '')).toThrow();
  });

  it('should check if title is valid', () => {
    expect(() => validateContent('title', 'test')).not.toThrow();
  });
});
