import React from 'react';
import { inject, observer } from 'mobx-react';
import { Divider, Icon } from 'semantic-ui-react';

import Form from '../../../components/ui/Form';
import PhoneInput from '../../../components/ui/PhoneInput';
import ColoredCheckbox from '../../../components/ColoredCheckbox';
import { __ } from '../../../i18n';

@inject('store') @observer
export default class AddressForm extends Form {
  defaultValues = {
    address: '',
    type: 'EMAIL',
    tags: [],
    invite: false
  }

  rules = {
    address: 'required',
    tags: [values => this.validateContent(values)]
  }

  maybes = {
    address: [
      [
        'email',
        values => values.type === 'EMAIL'
      ], [
        'mobile',
        values => values.type === 'PHONE'
      ]
    ]
  }

  componentDidMount() {
    this.setValue('tags', this.props.tags ?
      this.props.tags.filter(tag => tag.checked).map(tag => tag.id)
      :
      []);
  }

  validateContent = (value) => {
    const { entity_type, tags } = this.props;

    if (entity_type === 'STUDENT' && tags && tags.length && !value.length) {
      throw new Error(
        __('You must select at least one tag')
      );
    }
  }

  renderAddress = () => {
    const { values, errors } = this.state;
    const { address, type } = values;

    return (
      type === 'PHONE' ?
        <Form.Field
          className="phone-field"
          label={__('Phone')}
          name="address"
          red={errors && !!errors.address}
          control={PhoneInput}
          onChange={value =>
            this.onInputChange(null, { name: 'address', value: value && value.replace(/[^0-9]/g, '') })
          }
          autoFocus
        />
        :
        <Form.Input
          label={__('Email')}
          icon={{ before: { name: 'at' } }}
          name="address"
          value={address}
          red={errors && !!errors.address}
          style={{ marginBottom: '12px' }}
          onChange={e =>
            this.onTextInputChange(e, { name: 'address', value: e.target.value })
          }
          autoFocus
        />
    );
  }

  renderTag = () => {
    const { tags } = this.props;

    return (
      !!tags &&
      !!tags.length &&
        <Form.Field>
          <label>{__('Tags')}</label>
          <div style={{ padding: '0 16px' }}>
            {
              tags.map((tag, i) =>
                <ColoredCheckbox
                  key={i}
                  radio
                  bold={false}
                  radioChecked
                  name="tags"
                  data-name="tags"
                  label={tag.name}
                  value={tag.name}
                  data-value={tag.name}
                  checked={!!this.state.values.tags.find(id => id == tag.id)}
                  onClick={() => this.toggleValue('tags', tag.id)}
                  style={{ margin: '0 15px 8px 0', display: 'inline-block' }}
                  color="#084FFF"
                />
              )
            }
          </div>
        </Form.Field>
    );
  }

  renderInvite = () => {
    const { values } = this.state;
    const { store } = this.props;
    const { currentOrganization, entity, currentUser } = store;
    const organization = currentOrganization || (entity && entity.node && entity.node.organization);

    const isOrganizationLive = organization && organization.live !== null;
    const isEntityStudent = entity && entity.type === 'STUDENT';

    return (
      isOrganizationLive || !isEntityStudent || currentUser.isMaster ?
        [
          <Divider />,
          <div style={{ padding: '0 16px' }}>
            <Form.Field
              className="last-item"
            >
              <ColoredCheckbox
                radio
                radioChecked
                bold={false}
                label={__('Send invitation after adding')}
                name="invite"
                checked={values.invite}
                onClick={() => this.onInputChange(null, { name: 'invite', checked: !values.invite })}
              />
            </Form.Field>
          </div>
        ]
        :
        null
    );
  };

  renderError = errors => (
    errors &&
      <div className="error-warning">
        <Icon
          data-value="error"
          name="exclamation circle"
        />
        <span>{errors}</span>
      </div>
  );

  beforeSubmit = (values) => {
    if (values.tags.length > 0) {
      values.tags = values.tags.map(id => (
        { id, name: this.props.tags.find(tag => tag.id === id).name }
      ));
    }

    return values;
  }

  render() {
    const { errors } = this.state;

    return (
      <Form id="AddressForm" {...this.props} onSubmit={this.handleSubmit}>
        {this.renderAddress()}
        {this.renderError(errors && errors.address)}
        {this.renderTag()}
        {this.renderError(errors && errors.tags)}
        {this.renderInvite()}
      </Form>
    );
  }
}
