import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import AddressForm from '../Address/Form';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`query EntityAccountsQuery ($id: ID!) {
    node(id: $id) @connection(key: "Entity", filter: ["id"]) {
      ... on Entity {
        id: dbId
        organization {
          id: dbId
          tags {
            nodes {
              name
              id: dbId
            }
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.entity_id
    }
  })
})
@graphql(gql`mutation createAddress($createAddressMutation: CreateAddressInput!) {
  createAddress(input: $createAddressMutation) {
    clientMutationId
  }
}`)
@observer
export default class AddressAdd extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Add address');
  }

  request = (values) => {
    const { store, params, router, mutate, data } = this.props;

    return mutate({
      variables: {
        createAddressMutation: {
          entityId: params.entity_id,
          ...values
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: __('%s was added', values.address), success: true };
      router.push(`/organizations/${data.node.organization.id}/entities/`);
    });
  }

  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    return (
      <Controller
        id="AddressAdd"
        modal
        add
        title={__('Add address')}
        form={AddressForm}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        tags={this.props.data.node.organization.tags.nodes}
        {...this.props}
      />
    );
  }
}
