import React from 'react';
import { Checkbox } from 'semantic-ui-react';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Page from '../../components/Page';


import { __ } from '../../i18n';

@graphql(gql`mutation updateIntegration($updateIntegrationMutation: UpdateIntegrationInput!) {
  updateIntegration(input: $updateIntegrationMutation) {
    clientMutationId
  }
}`, {
  name: 'updateIntegration',
})

export default class IntegrationActivateToggle extends Page {
  handleUpdateIntegration(id, isActive) {
    const request = {
      mutate: this.props.updateIntegration,
      input: 'updateIntegrationMutation',
      message: __('Integration was updated'),
    };
    request.mutate({
      variables: {
        updateIntegrationMutation: { id, isActive }
      }
    }).then(() => {
      const data = this.props.integration;
      data.isActive = isActive;
      this.setState(data);
    });
  }

  render() {
    const { integration } = this.props;
    const { id } = this.props;
    return (
      <label>
        <Checkbox
          name={`hasDate-${id}`}
          checked={integration.isActive}
          onClick={(e, { checked }) => {
            this.handleUpdateIntegration(integration.dbId, checked);
          }}
          toggle
        />
      </label>
    );
  }
}
