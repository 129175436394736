import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Card, Icon, Grid, Popup } from 'semantic-ui-react';
import { Link } from 'react-router';
import gql from 'graphql-tag';
import { __ } from '../../i18n';
import * as utils from '../../utils';
import Responsive from '../../components/Responsive';

import Button from '../../components/ui/Button'

const ATTACHMENT_VIEW_SIZE_MB = 15;
const BYTES_IN_ONE_MB = 1e+6;
@inject('store') @observer
export default class FileItem extends Responsive {
  buttonStyle = () => this.isMobile() ? 
    {
      padding: '11px',
      width: '3rem',
      height: '3rem',
      minWidth: '3rem',
      minHeight: '3rem',
    }
    :
    {
      padding: '16px 24px',
      height: '3.5rem',
      minHeight: '3.5rem',
      whiteSpace: 'nowrap'
    };

  static fragments = {
    file: gql`
      fragment FileItem on Media {
        id: dbId
        origName
        uri
        size
        mimetype
      }`,
  }

  render() {
    const { file } = this.props;
    
    const isPDFOrText = (
      file.mimetype.includes('pdf') ||
      file.mimetype.endsWith('.pdf') ||
      file.origName.endsWith('.txt')
      )
      
    const isSupportedDocument = [
      '.doc', '.docx', '.odt',
      '.ppt', '.pptx', '.odp',
      '.xls', '.xlsx', '.ods'
    ].some(type => file.origName.endsWith(type))

    return (
      <Card fluid key={file.id}>
        <Card.Content>
          <Container>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column width={this.isMobile() && 13} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between'}}>
                  <div className="bold">{file.origName}</div>
                  <div style={{ color: '#a8a8a8' }}>
                    <Icon name={utils.getAttachmentIcon(file.mimetype, file.origName)} />
                    {' '}
                    <span style={{ fontSize: '1.15rem' }}>{file.size && utils.formatBytes(file.size, 1)}</span>
                  </div>
                </Grid.Column>
                <Grid.Column
                  width={this.isMobile() && 3}
                  style={this.isMobile() ?
                    { display: 'flex', alignItems: 'flex-end', justifyContent: 'center', flexDirection: 'column' }
                    : { display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}
                >
                  <Button
                    href={`/download_file?name=${encodeURIComponent(file.origName)}&path=${encodeURIComponent(file.uri)}`}
                    transparent
                    round
                    icon={{
                        name: 'download',
                        style: this.isMobile() ? { margin: 0, fontWeight: 300 } : { fontWeight: 300 },
                    }}
                    style={this.isMobile() ?
                      { order: 1, ...this.buttonStyle() }
                      : { marginLeft: '10px', order: 2, ...this.buttonStyle() }
                    }
                    text={this.isMobile() ? null : __('Download')}
                  />

                  {isPDFOrText &&
                    <Button
                      href={file.uri}
                      transparent
                      round
                      icon={{
                          name: 'eye',
                          style: this.isMobile() ? { margin: 0, fontWeight: 300 } : { fontWeight: 300 },
                      }}
                      style={this.isMobile() ?
                        { marginTop: '4px', order: 2, ...this.buttonStyle() }
                        : { order: 1, ...this.buttonStyle() }
                      }
                      text={this.isMobile() ? null : __('View')}
                    />}

                  {isSupportedDocument &&
                    (
                      file.size > ATTACHMENT_VIEW_SIZE_MB * BYTES_IN_ONE_MB ?
                        <Popup
                          content={__(
                            'Files with more than %s MB can\'t be previewed.',
                            ATTACHMENT_VIEW_SIZE_MB
                          )}
                          trigger={

                            <Button
                              href={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(file.uri)}`}
                              transparent
                              round
                              icon={{
                                name: 'eye',
                                style: this.isMobile() ? { margin: 0, fontWeight: 300 } : { fontWeight: 300 },
                              }}
                              style={this.isMobile() ?
                                { marginTop: '4px', order: 2, ...this.buttonStyle() }
                                : { order: 1, ...this.buttonStyle() }
                              }
                              disabled={file.size > ATTACHMENT_VIEW_SIZE_MB * BYTES_IN_ONE_MB}
                              text={this.isMobile() ? null : __('View')}
                            />

                          }
                        />
                        :
                        <Button
                          href={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(file.uri)}`}
                          transparent
                          round
                          icon={{
                            name: 'eye',
                            style: this.isMobile() ? { margin: 0, fontWeight: 300 } : { fontWeight: 300 },
                          }}
                          style={this.isMobile() ?
                            { marginTop: '4px', order: 2, ...this.buttonStyle() }
                            : { order: 1, ...this.buttonStyle() }
                          }
                          disabled={file.size > ATTACHMENT_VIEW_SIZE_MB * BYTES_IN_ONE_MB}
                          text={this.isMobile() ? null : __('View')}
                        />

                    )
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Card.Content>
      </Card>
    );
  }
}
