import React from 'react';
import { Icon } from 'semantic-ui-react';
import { inject } from 'mobx-react';
import { omit } from 'lodash';
import Form from '../../components/ui/Form';
import SelectGroup from '../../components/ui/SelectGroup';
import Button from '../../components/ui/Button';

import { __ } from '../../i18n';

const styles = {
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  }
};
@inject('store')
export default class ConciergeForm extends Form {
  defaultValues = {
    name: (this.props.concierge && this.props.concierge.name) || '',
    address: (this.props.concierge && this.props.concierge.address) || '',
    groups: (this.props.concierge && this.props.concierge.groups) || []
  }

  handleClick = () => {
    const { store } = this.props;
    const { values } = this.state;
    const formattedValues = {
      name: values.name.trim(),
      address: values.address.trim(),
      groups: values.groups
    };
    if (this.props.concierge && this.props.concierge.id) this.props.onSubmit({ ...formattedValues, id: this.props.concierge.id });
    else this.props.onSubmit(formattedValues);
    store.appends.pop();
  }

  isDisabled = () => !this.state.values.name.trim() || !this.state.values.groups.length;

  render() {
    const { edit } = this.props;
    const { values, errors } = this.state;
    const { concierge, usedGroups } = this.props;

    const actionStyle = this.isMobile() ? {} : {
      paddingLeft: '14px'
    };

    const newProps = omit(this.props, 'onSubmit', 'concierge', 'usedGroups');

    let updatedUsedGroups;
    if (usedGroups && concierge) {
      const thisConciergeGroupsIds = concierge.groups.map(item => item.id);
      updatedUsedGroups = usedGroups.filter(groupId => !thisConciergeGroupsIds.includes(groupId));
    } else if (usedGroups) {
      updatedUsedGroups = usedGroups;
    }

    return (
      <Form
        id="ConciergeForm"
        actionButtonsRight={[
          <div key={1} style={{ ...styles.footer, marginTop: '3rem', ...actionStyle }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <Button
                transparent
                round
                style={{ marginRight: '12px' }}
                text={__('Cancel')}
                onClick={() => { this.props.store.appends.pop(); }}
              />
              <Button
                round
                disabled={this.isDisabled()}
                text={edit ? __('Save') : __('Add')}
                style={styles.button}
                icon={{ name: 'check' }}
                onClick={() => { this.handleClick(); }}
              />
            </div>
          </div>
        ]}
        {...newProps}
      >
        <div style={{ marginBottom: '16px' }}>
          <h3 style={{ marginBottom: '16px' }}>{__('Details')}</h3>
          <Form.Input
            label={__('Name')}
            placeholder={__('Identify the gate')}
            name="name"
            value={values.name}
            onChange={e => this.onInputChange(null, { name: 'name', value: e.target.value })}
            autoFocus
            maxLength={50}
          />
          {
            errors && errors.name && errors.name !== 'Error' ?
              <div style={{ marginBottom: 14 }}>
                <Icon data-value="error" name="exclamation circle" size="large" color="red" />
                {errors.name}
              </div>
              :
              null
          }
        </div>
        <div style={{ marginBottom: '16px' }}>
          <Form.Input
            label={__('Address')}
            placeholder={__('Enter the address here (Optional)')}
            name="address"
            value={values.address}
            onChange={e => this.onInputChange(null, { name: 'address', value: e.target.value })}
            maxLength={100}
          />
          {
            errors && errors.address && errors.address !== 'Error' ?
              <div style={{ marginBottom: 14 }}>
                <Icon data-value="error" name="exclamation circle" size="large" color="red" />
                {errors.address}
              </div>
              :
              null
          }
        </div>
        <h3 style={{ marginBottom: '22px' }}>{__('Groups')}</h3>
        <span>{__('Grant access to')}</span>
        <SelectGroup
          selectedItems={values.groups}
          withSelectAll
          error={((errors && errors.groups && errors.groups !== 'Error')) ? errors.groups : null}
          visibility={'custom'}
          onSubmit={(groups => this.setValue('groups', groups))}
          onDelete={newGroups => this.setValue('groups', newGroups)}
          usedGroups={updatedUsedGroups}
          alternativeTags
          showSelectedTotalCount
        />
      </Form>
    );
  }
}
