import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`mutation deleteApplication($deleteApplicationMutation: DeleteApplicationInput!) {
  deleteApplication(input: $deleteApplicationMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['UserApplicationsQuery']
  }
})
@observer
export default class ApplicationDelete extends Controller {
  request = () => this.props.mutate({
    variables: {
      deleteApplicationMutation: {
        id: this.props.params.application_id
      }
    }
  }).then(() => {
    this.props.store.snackbar = { active: true, message: __('Application was deleted'), success: true };
    this.props.router.push('/applications');
  })

  render() {
    return (
      <Controller
        id="ApplicationDelete"
        delete
        object={__('application')}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
