import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import { __ } from '../../i18n';

@graphql(gql`mutation deleteAddress($deleteAddressMutation: DeleteAddressInput!) {
  deleteAddress(input: $deleteAddressMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['EntityAccountsQuery', 'UserInvitationsQuery', 'EntityEditQuery']
  }
})
@inject('store') @observer
export default class AddressDelete extends Controller {
  request = () => {
    this.setState({ loading: true });

    const deleteAddressMutation = this.props.params.code !== null ? {
      code: this.props.params.code,
      id: this.props.params.address_id
    } : {
      id: this.props.params.address_id
    };

    this.props.mutate({
      variables: {
        deleteAddressMutation
      }
    }).then(() => {
      const { store, router } = this.props;

      store.snackbar = { active: true, message: __('Address deleted successfully'), success: true };
      router.goBack();
      this.setState({ loading: false });
    }).catch((err) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }


  render() {
    return (
      <Controller
        id="AddressDelete"
        delete
        object={__('address')}
        onSubmit={this.onSubmit}
        loading={this.state.loading}
        {...this.props}
      />
    );
  }
}
