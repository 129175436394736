import React from 'react';
import Form from '../../components/Form';
import { __ } from '../../i18n';

export default class IntegrationForm extends Form {

  rules = {
    time: 'required',
    configuration: 'required'
  }

  render() {
    const { values, errors } = this.state;

    return (
      <Form id="IntegrationForm" {...this.props} onSubmit={this.handleSubmit}>
        <Form.Input 
          label={__('Running time')}
          name="time"
          value={values.time}
          onChange={this.onInputChange}
        />
        <Form.TextArea
          label={__('Configuration JSON')}
          name="configuration"
          rows="25"
          value={values.configuration}
          onChange={this.onInputChange}
        />
      </Form>
    );
  }
}
