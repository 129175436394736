/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Card,
  Button,
  Container,
  Icon,
  Divider,
  List,
  Message,
  Dropdown
} from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { uniqBy } from 'lodash';

import Modal from '../../components/Modal';
import Responsive from '../../components/Responsive';

import SurveyResults from '../Survey/Results';
import SurveyEdit from '../Survey/Edit';
import SurveyDelete from '../Survey/Delete';


import { __ } from '../../i18n';
import * as utils from '../../utils';

const styles = {
  proggressBar: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    minHeight: '48px',
    width: '100%',
    margin: '8px 0px',
    padding: '24px',
    borderRadius: '100px',
    background: '#FAFAFA'
  },
  absoluteBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    borderRadius: '100px',
    background: '#eee'
  }
};

@inject('store')
@graphql(gql`mutation createSurveyResult($createSurveyResultMutation: CreateSurveyResultInput!) {
  createSurveyResult(input: $createSurveyResultMutation) {
    clientMutationId
  }
}`, {
  name: 'createSurveyResult',
  options: {
    refetchQueries: ['MessageSurveyResultsEntityQuery']
  }
})
@graphql(gql`
mutation updatePendingSurvey($updateSurveyInput: UpdateSurveyInput!) {
  updateSurvey(input: $updateSurveyInput) {
    clientMutationId
  }
}
`, {
  name: 'updatePendingSurvey'
})
@observer
export default class SurveyResultForm extends Responsive {
  static fragments = {
    // if you need the id add to the component using the fragment
    // querying here can cause conflicts cause we need dbId in some components and key in others
    survey: gql`
      fragment SurveyResultForm on Survey {
        dbId
        name
        expired
        dateLimit
        optionNumber
        deleted
        canRemind
        summary {
          nodes {
            id: key
            dbId
            value
            count
          }
        }
        results (limit: 1000) {
          nodes {
            entityId
            surveyOptionId
          }
        }
        entities {
          totalCount
        }
      }
      `,
    surveyHistory: gql`
      fragment SurveyResultFormHistory on Survey {
        dbId
        name
        expired
        dateLimit
        optionNumber
        deleted
        canRemind
        summary {
          nodes {
            id: key
            dbId
            value
            count
          }
        }
        results (limit: 1000) {
          nodes {
            entityId
            surveyOptionId
          }
        }
      }
      `,
  }

  submitSurveyResult = async (option, survey) => {
    await this.props.createSurveyResult({
      variables: {
        createSurveyResultMutation: {
          entityId: this.props.params.entity_id,
          surveyId: survey.dbId,
          surveyOptionId: option.dbId
        }
      }
    });

    if (this.props.onSubmit) this.props.onSubmit();
    this.props.store.appends.pop();
  }

  disabledEntityMessage = () => {
    this.props.store.snackbar = { active: true, message: __('Feature disabled. Contact the institution for further details'), success: false };
  }

  remindPending = () => {
    const { updatePendingSurvey, survey, store } = this.props;
    const { canRemind, dbId, entities, results } = survey;

    if (!canRemind) {
      store.snackbar = { active: true, message: __('A reminder has already been sent for this survey today'), success: false };
      return;
    }

    store.appends.push(
      <Modal
        id="RemindPendingConfirm"
        size="small"
        onClose={() => store.appends.pop()}
        header={__('Remind pending (%s) confirm', entities.totalCount - uniqBy(results.nodes, 'entityId').length)}
        content={<Message warning>{__('Once sent, it can’t be undone.')}</Message>}
        actions={[
          <Button
            data-action="cancel"
            key={0}
            basic
            floated="left"
            content={__('Cancel')}
            onClick={() => store.appends.pop()}
          />,
          <Button
            data-action="confirm"
            key={1}
            primary
            content={__('Confirm')}
            onClick={() => {
              store.appends.pop();

              updatePendingSurvey({
                variables: {
                  updateSurveyInput: {
                    id: dbId,
                    remindPending: {
                      remindAll: true
                    }
                  }
                }
              }).then(() => {
                store.snackbar = { active: true, message: __('Reminders sent'), success: true };
              }).catch((err) => {
                store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
              });
            }}
          />
        ]}
      />
    );
  }

  surveyResultModal = (option, survey) => {
    this.props.store.appends.push(
      <Modal
        id="SurveyResultConfirm"
        size="small"
        onClose={() => this.props.store.appends.pop()}
        header={__('Survey selection confirm')}
        content={<Message warning>{__('Once selected, it can’t be undone.')}</Message>}
        actions={[
          <Button
            data-action="cancel"
            key={0}
            basic
            floated="left"
            content={__('Cancel')}
            onClick={() => this.props.store.appends.pop()}
          />,
          <Button
            data-action="confirm"
            key={1}
            primary
            content={__('Confirm')}
            onClick={() => this.submitSurveyResult(option, survey)}
          />
        ]}
      />
    );
  }

  renderProggress = (value, count, percentage, id) => <div data-id={`${id}`} style={styles.proggressBar} key={id}>
    <div
      style={{
        ...styles.absoluteBar,
        width: `${percentage === 0 ? 0 : Math.max(percentage, 11)}%`,
        clipPath: percentage < 11 ? `circle(50% at ${(percentage / 0.11) - 50}% 50%)` : null
      }}
    />
    <span data-name="value" className="bold" style={{ maxWidth: '500px', marginRight: '8px', zIndex: 1 }}>{value}</span>
    <span data-name="count" style={{ color: '#313131', margin: '0 8px 0 auto', whiteSpace: 'nowrap', zIndex: 1 }}>{count}</span>
    <span data-name="percentage" style={{ color: '#a0a0a0', whiteSpace: 'nowrap', zIndex: 1 }}>{Math.floor(percentage)}%</span>
  </div>

  renderSurveyOptions(survey, owner, fromHistory, cannotAnswer) {
    let { summary, results } = survey;
    let surveyAnswered = results.nodes.length >= survey.optionNumber;

    if (owner) {
      let totalCount = 0;

      summary.nodes.forEach((option) => {
        totalCount += option.count;
      });

      return (<div style={{ padding: '8px 0px' }}>
        {
          summary.nodes.map(option => (
            this.renderProggress(option.value, option.count, totalCount ? (option.count / totalCount) * 100 : 0, option.dbId)
          ))
        }
      </div>);
    }

    return (
      <List>
        {
          summary.nodes.map((option) => {
            let itemAnswered = results.nodes.filter(result => result.surveyOptionId === option.dbId).length > 0;
            return (
              <List.Item key={option.dbId}>
                <Button
                  data-action="add"
                  data-params={option.dbId}
                  fluid
                  disabled={surveyAnswered || itemAnswered || survey.expired || survey.deleted || fromHistory || cannotAnswer}
                  color={itemAnswered ? 'black' : undefined}
                  onClick={!this.props.entity.disabled ?
                    () => this.surveyResultModal(option, survey) :
                    this.disabledEntityMessage}
                >
                  {option.value}
                </Button>
              </List.Item>
            );
          })}

      </List>
    );
  }

  renderLimitDate = (survey) => {
    const dateString = new Date(survey.dateLimit);
    const dateAdjusted = utils.adjustTZDate(dateString, -dateString.getTimezoneOffset());

    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);

    return utils.simpleDate(dateAdjusted, true, 'LL, HH:mm', lang);
  }

  render() {
    let { survey, params, messageType, store, fromHistory } = this.props;
    const { results, optionNumber, expired, name, dbId, deleted, entities } = survey;
    const owner = messageType === 'SENT' || params.organization_id;
    let optionsRemaining = optionNumber - results.nodes.length;
    const exportLink = `${store.app.url}/csv/survey/results?` +
      `survey_id=${dbId}&access_token=${encodeURIComponent(store.access_token)}&limit=1000&tz_offset=${-(new Date().getTimezoneOffset())}`;
    const cannotAnswer = params.entity_id != store.currentEntity.id;

    let items = [];

    if (owner && !fromHistory) {
      items = items.concat([<Dropdown.Item data-action="see-details" onClick={() => this.props.store.appends.push(<SurveyResults survey={survey} />)}>
        {__('See details')}
      </Dropdown.Item>,
      (
        <Dropdown.Item as="a" href={exportLink}>
          {__('Export')}
        </Dropdown.Item>
      )
      ]);
    }

    if (owner && !deleted && (!params.organization_id || store.currentEntity.type === 'ADMIN') && !fromHistory) {
      if (!expired && results && results.nodes && entities && (uniqBy(results.nodes, 'entityId').length < entities.totalCount)) {
        items.push(<Dropdown.Item data-action="remind-pending" onClick={() => this.remindPending()}>
          {__('Remind pending (%s)', entities.totalCount - uniqBy(results.nodes, 'entityId').length)}
        </Dropdown.Item>);
      }

      items = items.concat([<Dropdown.Item
        onClick={() => this.props.store.appends.push(<SurveyEdit survey={survey} />)}
      >
        {__('Edit')}
      </Dropdown.Item>,
      (
        <Dropdown.Item
          onClick={() => this.props.store.appends.push(<SurveyDelete survey={survey} />)}
        >
          {__('Delete')}
        </Dropdown.Item>
      )
      ]);
    }

    return (
      <Card key={dbId} id="SurveyResultForm" data-id={dbId} fluid style={{ marginBottom: '1em' }}>
        <Card.Content>
          <Container textAlign="center">
            <div className="horizontallySpacedItems">
              <div className="bold"><Icon name="bar chart" style={{ color: '#0080ff' }} />{name}</div>
              {
                items.length ?
                  <Dropdown icon={null} trigger={<Icon data-action="open-survey-options" name="ellipsis v" />}>
                    <Dropdown.Menu className="bold" style={this.isMobile() ? { left: 'unset', right: 0 } : {}}>
                      {items}
                    </Dropdown.Menu>
                  </Dropdown>
                  :
                  null
              }
            </div>
            <Divider hidden fitted />
            {
              optionNumber > 1 && optionsRemaining > 0 && !owner && !expired && !deleted &&
              [
                <Divider hidden />,
                __('Options remaining') + ': ' + optionsRemaining,
              ]
            }
            {this.renderSurveyOptions(survey, owner, fromHistory, cannotAnswer)}
            {
              deleted ?
                <p style={{ color: '#f04040' }}>
                  <Icon name="times" />
                  {__('Survey Deleted')}
                </p>
                :
                survey.dateLimit &&
                <p style={{ color: expired ? '#a0a0a0' : '#ffb43c' }}>
                  <Icon name="exclamation circle" />
                  {
                    expired ?
                      __('Expired %s', this.renderLimitDate(survey))
                      :
                      __('Expires %s', this.renderLimitDate(survey))
                  }
                </p>
            }
          </Container>
        </Card.Content>
        {this.props.children}
      </Card>
    );
  }
}
