import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import { Message } from 'semantic-ui-react';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`mutation deleteRole($deleteRoleMutation: DeleteRoleInput!) {
  deleteRole(input: $deleteRoleMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['GroupNodeQuery', 'OrganizationGroupsQuery']
  }
})
@observer
export default class RoleDelete extends Controller {

  request = () => {
    const { store, params, router, mutate } = this.props;

    return mutate({
      variables: {
        deleteRoleMutation: {
          entityId: params.entity_id,
          groupId: params.group_id
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: __('Person was removed from group'), success: true };
      router.push(`/organizations/${params.organization_id}/groups/${params.group_id}${location.search}`);
    });
  }

  render() {
    return (
      <Controller
        id="RoleDelete"
        delete
        title={this.props.plural ? __('Are you sure you want to remove these %s?', __('role')) : __('Are you sure you want to remove this %s?', __('role'))}
        content={<Message warning>{__('Once removed, it can’t be undone.')}</Message>}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
