import React from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Loader, Header } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

@inject('store')
@graphql(gql`query ApplicationNodeQuery ($id: ID!) {
  node(id: $id) @connection(key: "Application", filter: ["id"]) {
    ... on Application {
      id: dbId
      name
    }
  }
}`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.application_id
    }
  })
})
@observer
export default class ApplicationNode extends React.Component {
  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active />;

    const { name } = data.node;

    return (
      <Grid id="ApplicationNode" stackable>
        <Grid.Column width={3}>
          <Header>{name}</Header>
        </Grid.Column>
        <Grid.Column stretched width={10}>
          {this.props.children}
        </Grid.Column>
      </Grid>
    );
  }
}
