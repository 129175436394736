import React from 'react';
import { Icon, Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { __ } from '../../i18n';
import { isEntityType } from '../../utils';
import Modal from '../../components/Modal';
import BetaModal from '../../components/ui/Modal';
import Responsive from '../../components/Responsive';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '30px'
  },
  grid: {
    display: 'flex',
  },
  column: {
    padding: '15px',
    borderRadius: '20px',
    margin: '7px',
    display: 'flex',
    flex: 1,
    border: '1px solid #E8E8E8',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.02)',
    flexDirection: 'column',
  },
  text: {
    textAlign: 'center',
    padding: 5
  },
  ul: {
    padding: 0,
    listStyleType: 'none'
  },
  li: {
    padding: '2px 4px 4px 4px'
  },
  title: {
    fontSize: '20px',
    margin: '0px 20px'
  },
  header: {
    fontSize: '25px',
    fontWeight: 'bold',
    marginBottom: '15px'
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '10px',
    marginTop: '10px'
  },
  button: {
    backgroundColor: '#084FFF',
    marginTop: '10px',
    borderRadius: '20px',
    minWidth: '200px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  row: {
    padding: '8px 24px',
    borderBottom: '1px solid #f1f3f5',
    width: '100%'
  }
};

const colors = {
  free: '#0BAE6D',
  light: '#084FFF',
  standard: '#0069FF',
  premium: '#CCB300'
};

const plans = {
  PREMIUM: __('Premium'),
  STANDARD: __('Standard'),
  LIGHT: __('Light'),
  FREE: __('Free'),
  STANDARD_NEW: __('Standard'),
  LIGHT_NEW: __('Light')
};

const openWhatsApp = (organization, feature) => {
  const message = `Olá, sou ${organization.fullname} e estou no plano ${plans[organization.plan]} e gostaria de saber mais sobre os outros planos.`;
  const phone = organization.plan === 'FREE' ? '5519993122796' : '5519993120878';
  const link = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;

  return link;
};

@inject('store')
@observer
export default class PaywallModal extends Responsive {
  constructor(props) {
    super(props);
    this.styles = {
      column: {
        padding: '15px',
        borderRadius: '20px',
        margin: '7px',
        display: 'flex',
        minWidth: this.isMobile() ? window.innerWidth * 0.66 : '200px',
        flex: 1,
        border: '1px solid #E8E8E8',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.02)',
        flexDirection: 'column',
      },
    };
  }
  renderGrid = () => (
    <div style={{ ...styles.grid, flexWrap: this.isMobile() ? 'unset' : 'wrap', marginBottom: this.isMobile ? '60px' : '0px' }}>
      <div style={{ ...this.styles.column }}>
        <h1 style={{ ...styles.title, color: colors.free }}>{__('Free')}</h1>
        <ul style={styles.ul}>
          <li style={styles.li}>
            <Icon name="check circle" className="duotone" style={{ color: colors.free, fontSize: '18px' }} />
            <span>{__('Send and receive messages')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.free, fontSize: '18px' }} />
            <span>{__('%s months of message history', 6)}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.free, fontSize: '18px' }} />
            <span>{__('1000 messages')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.free, fontSize: '18px' }} />
            <span> + {__('%s messages per student / month', 5)}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.free, fontSize: '18px' }} />
            <span>{__('Reading control')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.free, fontSize: '18px' }} />
            <span>{__('Send images and PDF`s')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.free, fontSize: '18px' }} />
            <span>{__('Polls, surveys and authorizations')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.free, fontSize: '18px' }} />
            <span>{__('Commitments and school calendar')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.free, fontSize: '18px' }} />
            <span>{__('Payments')}</span>
          </li>
        </ul>
      </div>
      <div style={{ ...this.styles.column }}>
        <h1 style={{ ...styles.title, color: colors.standard }}>Standard</h1>
        <ul style={styles.ul}>
          <li style={styles.li}>
            <Icon name="hand point left" className="duotone" style={{ color: colors.standard, fontSize: '18px' }} />
            <span>{__('All functions of the Free plan')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.standard, fontSize: '18px' }} />
            <span>{__('%s years of message history', 5)}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.standard, fontSize: '18px' }} />
            <span>{__('1000 messages')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.standard, fontSize: '18px' }} />
            <span>+ {__('%s messages per student / month', 30)}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.standard, fontSize: '18px' }} />
            <span>{__('Send audios')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.standard, fontSize: '18px' }} />
            <span>{__('Send pptx, docx and others')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.standard, fontSize: '18px' }} />
            <span>{__('Integrations')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.standard, fontSize: '18px' }} />
            <span>{__('Reports')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.standard, fontSize: '18px' }} />
            <span>{__('Labels')}</span>
          </li>
        </ul>
      </div>
      <div style={{ ...this.styles.column }}>
        <h1 style={{ ...styles.title, color: colors.premium }}>Premium</h1>
        <ul style={styles.ul}>
          <li style={styles.li}>
            <Icon name="hand point left" className="duotone" style={{ color: colors.premium, fontSize: '18px' }} />
            <span>{__('All functions of the Standard plan')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.premium, fontSize: '18px' }} />
            <span>{__('%s years of message history', 5)}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.premium, fontSize: '18px' }} />
            <span>{__('Sending unlimited messages')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.premium, fontSize: '18px' }} />
            <span>{__('Communications manager')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.premium, fontSize: '18px' }} />
            <span>{__('Telephone service')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.premium, fontSize: '18px' }} />
            <span>{__('Send videos')}</span>
          </li>
          <li style={styles.li}>
            <Icon name="check circle outline" className="duotone" style={{ color: colors.premium, fontSize: '18px' }} />
            <span>{__('Forms')}</span>
          </li>
        </ul>
      </div>
    </div>
  )

  render() {
    const { store, feature } = this.props;
    const { currentOrganization } = store;

    return (
      this.isMobile() ?
        <BetaModal
          id={'recipientAttachmentForm'}
          size="tiny"
          toast
          header={__('Update now')}
          actions
          scrolling
          invertCloseButton
          onClose={() => this.props.store.appends.pop()}
          content={
            <div style={{ ...styles.container, padding: '0px' }}>
              <span style={{ ...styles.text, color: '#6C6C6C' }}>{__('Choose the right plan for you:').toUpperCase()}</span>
              <div className={'message-tags mobile'} style={{ ...styles.row, borderBottom: 'none', padding: '8px 12px 0', display: '-webkit-inline-box', }}>
                {this.renderGrid()}
              </div>
              <div style={{ ...styles.footer, marginTop: '0px', width: '100%', position: 'absolute', bottom: '0px', backgroundColor: '#fff' }}>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.classapp.com.br/calculadora-agenda-digital#precos" style={{ color: '#084FFF', fontSize: '16px' }}>{__('See all features')}</a>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  {isEntityType(store, 'ADMIN') ? (
                    <Button
                      primary
                      as="a"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={openWhatsApp(currentOrganization, feature)}
                      style={{ ...styles.button, width: '80%' }}
                      content={__('I want to purchase')}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          }
        />
        :
        <Modal
          size="fullscreen"
          closeIcon={'times close inside'}
          onClose={() => this.props.store.appends.pop()}
          content={
            <div style={styles.container}>
              <span style={styles.header}>{__('Update now')}</span>
              {this.renderGrid()}
              <div style={styles.footer}>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.classapp.com.br/calculadora-agenda-digital#precos" style={{ color: '#084FFF', fontSize: '16px' }}>{__('See all features')}</a>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {isEntityType(store, 'ADMIN') ? (
                    <Button
                      primary
                      data-testid="paywall-modal-whatsapp-button"
                      as="a"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={openWhatsApp(currentOrganization, feature)}
                      style={styles.button}
                      content={__('I want to purchase')}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          }
        />
    );
  }
}
