import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Image } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

import * as utils from '../../utils';

import Modal from '../../components/Modal';
import AudioPlayer from '../../components/AudioPlayer';
import VideoPlayer from '../../components/VideoPlayer';

import { __ } from '../../i18n';


@inject('store') @observer
export default class MediaItem extends React.Component {

  static fragments = {
    image: gql`
      fragment MediaItemImage on Media {
        id: dbId
        mimetype
        origName
        original: uri(size:"w1280")
      }`,
    video: gql`
      fragment MediaItemVideo on Media {
        id: dbId
        mimetype
        origName
        original: uri(size:"w1280")
        thumbnail(size:"w320")
        uri
      }`,
    audio: gql`
      fragment MediaItemAudio on Media {
        id: dbId
        mimetype
        origName
        uri
      }`,
  }

  render() {
    const { media, container } = this.props;

    const removeExtensionFromAudio = utils.isAudioFile(media.mimetype, media.origName) && media.origName.replace(/\.\w*$/, '');

    const content = (<Container textAlign="center">
      {[
        media.mimetype.includes('image') &&
        <Image className="media-image" key="image" src={media?.original || media?.preview} wrapped />,
        utils.isAudioFile(media.mimetype, media.origName) &&
        <AudioPlayer key="audio" url={media.uri} alias={removeExtensionFromAudio} name={media.origName} />,
        utils.isVideoFile(media.mimetype, media.origName) &&
        <div key="video" className="clsp-video" style={{ height: 320 }}>
          <VideoPlayer src={media.uri} name={media.origName} thumbnail={media.thumbnail} />
        </div>
      ]}
    </Container>);

    if (container) return content;

    return (
      <Modal
        className="mediaItem"
        title={media.origName}
        style={{ maxHeight: '100vh' }}
        data-id={media.id}
        size="small"
        closeIcon="times close"
        onClose={() => this.props.store.appends.pop()}
      >
        <Modal.Content image>
          {content}
        </Modal.Content>
      </Modal>
    );
  }
}
