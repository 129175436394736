import React from 'react';
import { inject, observer } from 'mobx-react';
import { Header, Icon, Menu, Button, Loader, Image, Popup } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { omit } from 'lodash';
import CardAdd from '../Card/Add';
import { __ } from '../../i18n';
import Form from '../../components/Form';
import { brandToLogo, getSymbolFromCurrency } from '../../lib/payment';
import ColoredCheckbox from '../../components/ColoredCheckbox';
import InputMask from 'react-input-mask';
import PhoneInput from '../../components/PhoneInput';
import * as utils from '../../utils';

const actionStyle = {
  mobile: {
    display: 'flex',
    flexDirection: 'column'
  },
  desktop: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const amountStyle = {
  mobile: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
    marginBottom: '2rem'
  },
  desktop: {
    marginTop: 'auto',
    marginBottom: 'auto'
  }
};

const calculateInstallments = (amount, allowedInstallments) => [...Array(allowedInstallments).keys()].map(i => ({ text: `${i + 1} x ${parseFloat(amount / (i + 1)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`, value: (i + 1), key: i }));
@inject('store')
@graphql(gql`query PaymentFormQuery {
  viewer {
      id: dbId
      cards {
        nodes {
          id: dbId
          last4Digits
          brand
          cardToken
        }
      }
      email
      phone
    }
}
`)
@observer
export default class PaymentForm extends Form {
  constructor(props) {
    super(props);

    this.defaultValues = {
    };
    this.rules = {};

    if (this.props.creditCard) {
      this.defaultValues.card = '';
      this.defaultValues.installments = 1;
      this.rules.card = ['required'];
      this.rules.installments = ['required'];
    } else {
      this.defaultValues.cpf = (props.store.currentUser && props.store.currentUser.documentNumber) || '';
      this.defaultValues.phone = (props.store.currentUser && props.store.currentUser.phone) || '';
      this.defaultValues.email = (props.store.currentUser && props.store.currentUser.email) || '';

      this.rules.cpf = ['required', 'CPF'];
      this.rules.email = ['required', 'email'];
      this.rules.phone = ['required', 'mobile'];
    }
  }

  beforeOnSubmit = () => this.setState({ loading: true });

  afterOnSubmit = () => this.setState({ loading: false });

  renderRow = (card, key) => {
    const brand = brandToLogo(card.brand || '');

    return (
      <Menu.Item
        key={key}
        onClick={e => this.onSelectionChange(e, { name: 'card', value: card })}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Header as="h4" style={{ margin: 0 }}>
            <Image style={{ height: 26, width: 26 }} src={brand.noColor} />
            <Header.Content style={{ fontWeight: 'normal' }}>
              {card.brand}{' •••• '}{card.last4Digits.toString().padStart(4, '0')}
            </Header.Content>
          </Header>
          <ColoredCheckbox radio checked={this.state.values.card === card} />
        </div>
      </Menu.Item>
    );
  }

  renderNewCard = () => (
    <Menu.Item
      key={'new-card'}
      onClick={() => {
        const { email, phone } = this.props.data.viewer;
        const parameters = { email, phone };
        this.props.store.appends.push(<CardAdd {...parameters} />);
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Header as="h4" style={{ margin: 0 }}>
          <Icon name="credit card" />
          <Header.Content style={{ fontWeight: 'normal' }}>
            {__('New credit card')}
          </Header.Content>
        </Header>
      </div>
    </Menu.Item>
  )
  renderAmount = () => {
    const { charge } = this.props;

    return (<div style={this.isMobile() ? amountStyle.mobile : amountStyle.desktop}>
      <span style={{ fontSize: 16, fontWeight: 'bold' }}>
        {__('Total cost')}
        <span style={{ fontWeight: 'bolder', color: '#0080ff', marginLeft: '12px' }}>{
          parseFloat(charge.paymentCardAmount)
            .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }</span>
      </span>
    </div>);
  }

  handleProps = () => {
    const { loading } = this.props;
    const { creditCard, boleto } = this.props;
    let newProps = { modal: true };
    if (creditCard) {
      newProps = { ...omit(this.props, ['onSubmit']), ...newProps };
      newProps.actionButtons = (<div style={this.isMobile() ? actionStyle.mobile : actionStyle.desktop}>
        {this.renderAmount()}
        <Button
          data-action="submit"
          type="button"
          primary
          loading={loading}
          disabled={loading}
          content={__('Confirm payment')}
          onClick={utils.debounce(this.handleSubmit)}
        />
      </div>);
    } else {
      newProps = { ...this.props, ...newProps };
      newProps.submitButton = {
        content: boleto ? __('Generate boleto') : __('Generate pix code')
      };
    }


    return newProps;
  }

  creditCardForm = () => {
    const { data, charge } = this.props;
    const { cards } = data.viewer;
    const { errors } = this.state;
    const newProps = omit(this.props, ['onSubmit']);
    const fieldName = (message, error) => (
      <div style={{ display: 'flex', marginBottom: '5px' }}>
        <p className="bold" style={{ margin: '8px 8px 8px 0px', fontSize: '1.05rem', fontWeight: 'bold', color: 'black' }}>{message}</p>
        { error && <Popup
          trigger={<Button type="button" icon="exclamation triangle" basic color="red" />}
          content={error}
        />}
      </div>
    );

    return (
      <Form id="PaymentForm" {...newProps} {...this.handleProps()}>
        {fieldName(__('Select a credit card'), errors && errors.card)}
        <Menu
          vertical
          fluid
          style={{ justifyContent: 'center', marginTop: 0, background: 'white' }}
        >
          <Form.Group grouped>
            <Form.Field type="radio">
              {cards.nodes.map((card, i) => this.renderRow(card, i))}
              {this.renderNewCard()}
            </Form.Field>
          </Form.Group>
        </Menu>
        {fieldName(__('Number of installments'), errors && errors.installments)}
        <Form.Dropdown
          fluid
          selection
          name="installments"
          upward
          onChange={(e, { name, value }) => this.onSelectionChange(e, { name, value })}
          value={this.state.values.installments}
          options={calculateInstallments(charge.paymentCardAmount, charge.allowedInstallments)}
          style={{
            borderRadius: 4
          }}
        />
      </Form>
    );
  }

  generateConfirmationForm = () => {
    const { boleto } = this.props;

    return (
      <Form id={`Generate${boleto ? 'Boleto' : 'Pix'}Form`} {...this.props} {...this.handleProps()} onSubmit={utils.debounce(this.handleSubmit)}>
        <p style={{ margin: '0px 0px 5px 0px', color: 'black', fontSize: '1.05rem' }}>
          {boleto ? __('Do you wish to generate a boleto for this charge?') : __('Do you want to generate a pix code for this charge?')}
        </p>
      </Form>
    );
  }

  missingDataForm = () => {
    const { values, errors } = this.state;
    const { boleto } = this.props;

    return (
      <Form id={`${boleto ? 'Boleto' : 'Pix'}Form`} {...this.props} {...this.handleProps()} onSubmit={utils.debounce(this.handleSubmit)}>
        <p style={{ margin: '0px 0px 5px 0px', color: 'black', fontSize: '1.05rem' }}>
          {boleto ? __('Please, insert your document number, email and phone bellow to generate your boleto') : __('Please add your CPF, email and phone number below to generate your pix')}
        </p>
        <p style={{ margin: '0px 0px 5px 0px', color: 'black', fontWeight: 'bold' }}>
          {__('Document Number')}
        </p>
        <InputMask mask="999.999.999-99" value={values.cpf} onChange={e => this.onInputChange(null, e.target)}>
          {
            inputProps =>
              <Form.Input
                name="cpf"
                {...inputProps}
                placeholder="000.000.000-00"
                error={errors && errors.cpf}
              />
          }
        </InputMask>
        <Form.Input
          label={__('Email')}
          placeholder={__('Insert your email')}
          name="email"
          value={values.email}
          onChange={e => this.onTextInputChange(null, { name: 'email', value: e.target.value.replace(/\s/g, '') })}
          error={errors && errors.email}
        />
        <Form.Input
          label={__('Phone')}
          name="phone"
          type="text"
          defaultValue={values.phone}
          error={errors && errors.phone}
          placeholder={__('Area Code + Phone')}
          control={PhoneInput}
          onChange={value => this.onInputChange(null, { name: 'phone', value: value && value.replace(/[^0-9 ()+-]+$/, ''), checked: '' })}
        />
        <p style={{ textAlign: 'center', color: '#868e96', width: '65%', margin: 'auto' }}>
          {__('You will be able to see and edit this information in your user settings')}
        </p>
      </Form>);
  }

  render() {
    const { data, creditCard } = this.props;
    const { currentUser } = this.props.store;

    if ((data.loading && !data.viewer) || !data.viewer) return <Loader active inline="centered" />;

    if (creditCard) return this.creditCardForm();
    if (currentUser.documentNumber && currentUser.email && currentUser.phone) return this.generateConfirmationForm();
    return this.missingDataForm();
  }
}
