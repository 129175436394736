import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import { __ } from '../../i18n';
import * as utils from '../../utils';

@graphql(gql`mutation deleteUserCard($deleteUserCardMutation: DeleteUserCardInput!) {
  deleteUserCard(input: $deleteUserCardMutation) {
    userCard {
      dbId
      user {
        fullname
      }
    }
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['UserPaymentsQuery']
  }
})
@inject('store') @observer
export default class CardDelete extends Controller {
  state = {
    loading: false
  }

  request = () => {
    this.setState({ loading: true });

    this.props.mutate({
      variables: {
        deleteUserCardMutation: {
          id: this.props.params.card_id
        }
      }
    }).then(() => {
      this.setState({ loading: false });

      const { store, router } = this.props;
      store.snackbar = { active: true, message: __('Card deleted successfully'), success: true };

      router.push('/user/payments');
    }).catch((err) => {
      this.setState({ loading: false });
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 5000 };
    });
  }


  render() {
    return (
      <Controller
        id="UserCardDelete"
        delete
        object={__('card')}
        onSubmit={this.onSubmit}
        loading={this.state.loading}
        {...this.props}
      />
    );
  }
}
